export type HotelButtonProps = {
    classes: string
    onClick: (e: any) => void
    style: {}
    context: string | JSX.Element
    isButtonShown: boolean
}

export const HotelButton = ({
    classes,
    context,
    isButtonShown,
    onClick,
    style
}: HotelButtonProps) => {
    return (
        <div className="room-selection-wrapper">
            {isButtonShown && (
                <button
                    className={classes}
                    style={style}
                    onClick={onClick}
                >
                    {context}
                </button>
            )}
        </div>
    )
}

import { useEffect, useState } from 'react'
import { EventJSON } from '../../../events-service/src/models/event'
import { TourJSON } from '../../../events-service/src/models/tour'
import { eventsWithoutJwtClient } from 'apollo-client'
import { ContactUsFormType } from 'types/contactUs'
import { validateEmail } from 'util/validate'
import { CONTACT_US, GET_TOPICAL_QUERY } from 'graphQL/contact-us'
import { isValidPhoneNumber } from 'react-phone-number-input'
import {
    FILL_FIELD_REQUIRED,
    FORM_INTERNAL_SERVER_ERROR,
    INVALID_EMAIL_FORMAT,
    INVALID_PHONE_FORMAT,
    PLACE_HOLDER_OPTION,
    REFRESH_REMIND
} from 'consts'
type useContactUsProps = {
    publishedEvents: EventJSON[]
    tours: TourJSON[]
}

const blankFormData = () => ({
    content: '',
    email: '',
    name: '',
    orderNumber: '',
    phone: '',
    relatedTo: '',
    subject: '',
    topical: ''
})

export const useContactUs = ({ publishedEvents, tours }: useContactUsProps) => {
    const [errors, setErrors] = useState<ContactUsFormType>(blankFormData())
    const [formData, setFormData] = useState<ContactUsFormType>(blankFormData())
    const [formState, setFormState] = useState<string>('init')
    const [optionList, setOptionList] = useState<(TourJSON | EventJSON)[]>([])
    const [serverError, setServerError] = useState<string>('')
    const [topicalData, setTopicalData] = useState<any[]>([])
    useEffect(() => {
        const getTopicalData = async () => {
            try {
                const { data } = await eventsWithoutJwtClient.query({
                    query: GET_TOPICAL_QUERY,
                    fetchPolicy: 'no-cache'
                })
                setTopicalData(data.getSettings.relatedTos)
            } catch {
                setFormState('submitted')
                setServerError(REFRESH_REMIND)
                setTopicalData([])
            }
        }
        getTopicalData()
    }, [])
    useEffect(() => {
        let eventNotTour: EventJSON[] = []
        if (tours.length > 0) {
            publishedEvents.forEach((event) => {
                const inTour = tours
                    .flatMap((tour) => tour.events)
                    .some((_event) => _event.id === event.id)
                if (!inTour) eventNotTour.push(event)
            })
        } else {
            eventNotTour = publishedEvents
        }
        const _tours = tours.filter((tour) => tour.isContactUsTour)
        const optionList = [...eventNotTour, ..._tours].sort((a, b) => a.name.localeCompare(b.name))
        setOptionList(optionList)
    }, [])

    const validateFormData = () => {
        const { content, email, name, phone, relatedTo, subject, topical } = formData
        let formErrors = blankFormData()
        Object.assign(formErrors, errors)
        let isValid = true
        if (!name.trim()) {
            formErrors.name = FILL_FIELD_REQUIRED
            isValid = false
        }

        if (phone && !isValidPhoneNumber(phone)) {
            formErrors.phone = INVALID_PHONE_FORMAT
            isValid = false
        }

        if (!email.trim()) {
            formErrors.email = FILL_FIELD_REQUIRED
            isValid = false
        } else {
            const errorMsg = validateEmail(email.trim())
            if (errorMsg) {
                formErrors.email = INVALID_EMAIL_FORMAT
                isValid = false
            }
        }

        if (!subject.trim()) {
            formErrors.subject = FILL_FIELD_REQUIRED
            isValid = false
        }

        if (topical === PLACE_HOLDER_OPTION || topical === '') {
            formErrors.topical = FILL_FIELD_REQUIRED
            isValid = false
        }

        if (relatedTo === PLACE_HOLDER_OPTION || relatedTo === '') {
            formErrors.relatedTo = FILL_FIELD_REQUIRED
            isValid = false
        }

        if (!content.trim()) {
            formErrors.content = FILL_FIELD_REQUIRED
            isValid = false
        }
        return { formErrors, isValid }
    }

    const handleFormOnChange = (key: string, value: string) => {
        setFormData({
            ...formData,
            [key]: value
        })
        resetFormFieldErrors(key)
    }

    const handleSubmitForm = async () => {
        setFormState('submitting')
        const { formErrors, isValid } = validateFormData()
        setErrors(formErrors)
        if (isValid) {
            const { content, email, name, orderNumber, phone, relatedTo, subject, topical } =
                formData
            try {
                const { data } = await eventsWithoutJwtClient.query({
                    fetchPolicy: 'no-cache',
                    query: CONTACT_US,
                    variables: {
                        content,
                        email,
                        name,
                        orderNumber,
                        phone,
                        relatedTo,
                        subject,
                        topical
                    }
                })
                setFormState('submitted')
                if (data.contactUs.ok) {
                    setFormData(blankFormData())
                    setServerError('')
                } else {
                    setServerError(FORM_INTERNAL_SERVER_ERROR)
                }
            } catch {
                setFormState('submitted')
                setServerError(FORM_INTERNAL_SERVER_ERROR)
            }
        }
    }

    const resetFormFieldErrors = (field: string) => {
        if (errors[field as keyof ContactUsFormType]) {
            setErrors({
                ...errors,
                [field]: ''
            })
        }
    }
    return {
        errors,
        formData,
        formState,
        handleFormOnChange,
        handleSubmitForm,
        optionList,
        resetFormFieldErrors,
        serverError,
        topicalData
    }
}

import { OrderItem, OrderState } from "store"
import { EventJSON } from "../../../events-service/src/models/event"
import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { getEventNameOverride } from "util/event"
import { dateRange, getTimeZone } from "util/dates"
import { formatMoney } from "util/parse"
import { getOrderCheckInAndOut } from "util/order"
import { OrderListType } from "types/orders"


type useMyExperienceProps = {
    events: EventJSON[]
    orders: OrderState[]
}
type useMyExperienceReturnType = {
    orderList: OrderListType[]
}

export const useMyExperience = ({
    events,
    orders
}: useMyExperienceProps): useMyExperienceReturnType => {
    const [orderList, setOrderList] = useState<OrderListType[]>([])

    const getCheckInAndOut = (items: OrderItem[], event: EventJSON): (string | Date)[] => {
        const { checkInDate, checkOutDate, timezone } = event
        const hotels = event.hotels.filter((hotel) => {
            return hotel.rooms.find((room) => {
                return items.find((item) => 
                    item.itemID === room.id && item.type === 'room'
                )
            })   
        })
        const [_checkInDate, _checkOutDate] = getOrderCheckInAndOut(
            checkInDate,
            checkOutDate,
            hotels,
            items,
            timezone
        )
        return [_checkInDate, _checkOutDate]
    }


    useEffect(() => {
        if (orders.length === 0 || events.length === 0) return
        const getOrderList = () => {
            const newOrderList: OrderListType[] = []
            orders.sort((a, b) => dayjs(b.created).format().localeCompare(dayjs(a.created).format()))
                .forEach((order) => {
                    const { displayStatus, eventID, id, items, number, status, total } = order
                    const event = events.find((event) => event.id === eventID)
                    if (!event) return
                    const [checkInDate, checkOutDate] = getCheckInAndOut(order.items, event)
                    const checkDateRange = dateRange(
                        checkInDate,
                        checkOutDate,
                        event.timezone
                    )
                    const created = getTimeZone(order.created, event.timezone).format('MM-DD-YYYY')
                    const ticketId = items.find((item) => {
                        return item.type === 'ticket'
                    })?.itemID || ''
                    const eventNameOverride = getEventNameOverride(event, ticketId)
                    newOrderList.push({
                        checkDateRange: checkDateRange,
                        created: created,
                        eventName: eventNameOverride || event.name,
                        id: id,
                        orderNumber: number,
                        orderTotal: formatMoney(total / 100, event.currency),
                        status: displayStatus || status
                    })
                })
            setOrderList(newOrderList)
        }
        getOrderList()
    }, [events, orders])
    return {
        orderList
    }
}

import { faLock, faCircleChevronRight, faCircleChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { faSpinner, faStar as emptyStar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import Slider from 'react-slick'
import Image from './image'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'
import { isMobileOnly } from 'react-device-detect'
import { HotelButton } from './hotelButton'
import {
    COLOR_GRAY_200,
    COLOR_GRAY_500,
    COLOR_PRIMARY_600,
    COLOR_PRIMARY_700,
    FONT_SIZE,
    FONT_SIZE_12,
    FONT_SIZE_14,
    FONT_SIZE_18,
    FONT_SIZE_24,
    FONT_SIZE_8,
    FONT_WEIGHT_500,
    FONT_WEIGHT_600,
    FULL_WIDTH,
    SIZE_2,
    SIZE_8,
    SIZE_10,
    SIZE_20,
    SIZE_28,
    SIZE_120
} from 'styles/theme'
import { Box, Skeleton } from '@mui/material'

const slickSettings = {
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'ease',
    dots: true,
    nextArrow: (
        <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
        >
            <FontAwesomeIcon
                icon={faCircleChevronRight}
                style={{ fontSize: '36px', backgroundColor: '#667085', borderRadius: '50%' }}
            />
        </span>
    ),
    prevArrow: (
        <span
            className="carousel-control-next-icon"
            aria-hidden="true"
        >
            <FontAwesomeIcon
                icon={faCircleChevronLeft}
                style={{ fontSize: '36px', backgroundColor: '#667085', borderRadius: '50%' }}
            />
        </span>
    )
}

export type HotelCardProp = {
    codeIsCorrect: boolean
    dateDisplay?: string
    hasShoulderNights?: boolean
    hotel: HotelJSON
    hotelIndex?: number
    isF1: boolean
    isLoading?: boolean
    isOnlySellHotel: boolean
    isPreSale: boolean
    isSoldOut: boolean
    saleStatusOnHotelCard: string
    needShowPrice: boolean
    onHandleImageClick: (hotelImageUrl: any[]) => void
    onHotelCardClick: (
        hotelId: string,
        onButton: boolean,
        shoulderNights: DateRange<Dayjs> | undefined
    ) => void
    onUnlockButtonClick: () => void
    selectButtonClass: string
    selectButtonText: string
    submitting: boolean
    hotelShoulderNights: DateRange<Dayjs> | undefined
}

export default function HotelCard({
    codeIsCorrect,
    dateDisplay,
    hasShoulderNights,
    hotel,
    hotelIndex = 0,
    isF1,
    isLoading = false,
    isOnlySellHotel,
    isPreSale,
    isSoldOut,
    saleStatusOnHotelCard,
    needShowPrice,
    onHandleImageClick,
    onHotelCardClick,
    onUnlockButtonClick,
    selectButtonClass,
    selectButtonText,
    submitting,
    hotelShoulderNights
}: HotelCardProp) {
    const isFuture = saleStatusOnHotelCard.indexOf('On Sale') !== -1
    let unSelectDates = selectButtonText === 'Unavailable'
    const largerThanGroupSize = selectButtonText.indexOf('Larger Than') !== -1
    const onSale = !(isFuture || isSoldOut || unSelectDates)
    const { description, headerImage, id, name } = hotel

    const renderSelectHotelButton = () => {
        let buttonClass = ''
        let buttonOnClick = (e: any) => {}
        let style = {}
        let context: string | JSX.Element = ''
        let isButtonShown = true

        switch (true) {
            case onSale && isPreSale:
                buttonClass = 'btn-purple btn btn-sm btn-unlock w-100'
                context = (
                    <>
                        <span className="icon-unlock">
                            <FontAwesomeIcon icon={faLock} />
                        </span>
                        Unlock
                    </>
                )
                buttonOnClick = onUnlockButtonClick
                break

            case onSale && !isPreSale && unSelectDates:
                buttonClass = 'btn btn-block btn-gray w-100 disabled'
                style = { width: SIZE_120 }
                context = selectButtonText
                break

            case onSale && !isPreSale && !unSelectDates:
                buttonClass = 'btn ' + selectButtonClass
                style = { cursor: 'pointer', width: SIZE_120 }
                context = submitting ? (
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin"
                    />
                ) : (
                    selectButtonText
                )
                buttonOnClick = (e) => {
                    e.stopPropagation()
                    onHotelCardClick(id, true, hotelShoulderNights)
                }
                break

            case largerThanGroupSize:
                buttonClass = 'btn btn-block btn-gray w-100 disabled'
                context = selectButtonText
                break

            case isSoldOut:
                buttonClass = 'btn btn-block btn-gray w-100 disabled'
                context = 'Sold Out'
                break

            case isFuture:
                isButtonShown = false
                break

            default:
                break
        }
        return (
            <HotelButton
                classes={buttonClass}
                context={context}
                isButtonShown={isButtonShown}
                onClick={buttonOnClick}
                style={style}
            />
        )
    }
    const onImageClick = (e: any) => {
        e.stopPropagation()
        onHandleImageClick(headerImage)
    }

    const onCardClick = (e: any) => {
        if (!onSale) {
            return
        }
        e.stopPropagation()
        onHotelCardClick(id, false, hotelShoulderNights)
    }

    return (
        <div
            className={
                isOnlySellHotel
                    ? 'mb-3 col-xl-4 col-md-6 col-12'
                    : 'mb-3 col-lg-4 col-md-6 col-12 mb-md-4-2'
            }
        >
            <div className="card hotel-item-wrapper">
                <div className="td-n2 hotel-item-link">
                    {isLoading ? (
                        <Skeleton
                            animation="wave"
                            key={`hotelImageSkeleton_${hotelIndex}`}
                            sx={{
                                maskImage: 'none',
                                paddingBottom: '65%'
                            }}
                            variant="rectangular"
                        />
                    ) : (
                        <div
                            data-bs-ride="carousel"
                            style={{
                                position: 'relative'
                            }}
                        >
                            <Box
                                sx={{
                                    overflow: 'hidden',
                                    position: 'relative',
                                    width: FULL_WIDTH,
                                    '::after': {
                                        clear: 'both',
                                        content: '""',
                                        display: 'block'
                                    }
                                }}
                            >
                                <Slider {...slickSettings}>
                                    {headerImage.map((imageItem: any, index: number) => {
                                        return (
                                            <Box
                                                key={'hotel-image-' + index}
                                                sx={{
                                                    backfaceVisibility: 'hidden',
                                                    borderTopLeftRadius: SIZE_10,
                                                    borderTopRightRadius: SIZE_10,
                                                    cursor: 'pointer',
                                                    display: 'inline-block',
                                                    float: 'left',
                                                    height: 0,
                                                    marginRight: `-${FULL_WIDTH}`,
                                                    overflow: 'hidden',
                                                    paddingBottom: '65%',
                                                    position: 'relative',
                                                    transition: 'transform 0.6s ease-in-out',
                                                    width: FULL_WIDTH,
                                                    '@media (max-width: 767px)': {
                                                        borderTopLeftRadius: SIZE_8,
                                                        borderTopRightRadius: SIZE_8,
                                                        paddingBottom: '56%'
                                                    },
                                                    '& img': {
                                                        backgroundColor: COLOR_GRAY_200,
                                                        objectFit: 'cover'
                                                    }
                                                }}
                                            >
                                                <Image
                                                    alt=""
                                                    layout="fill"
                                                    onClick={onImageClick}
                                                    sizes="(max-width: 384px) 100vw, (max-width: 768) 50vw, 33vw"
                                                    src={imageItem.url}
                                                    style={{
                                                        width: FULL_WIDTH
                                                    }}
                                                />
                                            </Box>
                                        )
                                    })}
                                </Slider>
                            </Box>
                        </div>
                    )}
                    {isLoading ? (
                        <Skeleton
                            animation="wave"
                            key={`hotelDetailSkeleton_${hotelIndex}`}
                            sx={{
                                height: '400px',
                                maskImage: 'none'
                            }}
                            variant="rectangular"
                        />
                    ) : (
                        <Box
                            sx={{
                                cursor: onSale ? 'pointer' : 'not-allowed',
                                flex: '1 1 auto',
                                padding: `${FONT_SIZE} ${FONT_SIZE} ${FONT_SIZE_24}`,
                                '@media (min-width:768px)': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: FONT_SIZE_24
                                }
                            }}
                            onClick={onCardClick}
                        >
                            <h3
                                style={{
                                    fontSize: FONT_SIZE_18,
                                    fontWeight: FONT_WEIGHT_600,
                                    lineHeight: SIZE_28,
                                    marginBottom: FONT_SIZE_8
                                }}
                            >
                                {name}
                            </h3>
                            <div
                                style={{
                                    color: isMobileOnly ? COLOR_PRIMARY_600 : COLOR_PRIMARY_700,
                                    fontSize: FONT_SIZE_14,
                                    fontWeight: FONT_WEIGHT_500,
                                    lineHeight: SIZE_20,
                                    marginBottom: FONT_SIZE_8
                                }}
                            >
                                {dateDisplay}
                                {hasShoulderNights && ' (Additional Nights Available)'}
                            </div>
                            <Box
                                sx={{
                                    color: COLOR_GRAY_500,
                                    marginBottom: FONT_SIZE_12,
                                    '@media (min-width: 768px)': {
                                        display: 'flex',
                                        flex: '1 1 auto'
                                    }
                                }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: description }} />
                            </Box>
                            <Box
                                sx={{
                                    display: isPreSale ? 'flex' : undefined,
                                    flexWrap: 'wrap',
                                    justifyContent: isPreSale ? 'flex-end' : undefined,
                                    whiteSpace: 'nowrap',
                                    '@media (max-width: 767px)': {
                                        '& button': {
                                            fontSize: FONT_SIZE_14,
                                            lineHeight: SIZE_20
                                        }
                                    }
                                }}
                            >
                                {needShowPrice && (
                                    <div
                                        style={{
                                            paddingRight: SIZE_2
                                        }}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: saleStatusOnHotelCard
                                            }}
                                        />
                                    </div>
                                )}
                                {renderSelectHotelButton()}
                            </Box>
                        </Box>
                    )}
                </div>
            </div>
        </div>
    )
}

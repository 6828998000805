export const FILL_FIELD_REQUIRED = 'This field is required.'
export const FORM_INTERNAL_SERVER_ERROR = 'Internal server error!'
export const INCORRECT_EMAIL = 'Incorrect email'
export const INVALID_EMAIL_FORMAT = 'Invalid email format.'
export const INVALID_PHONE_FORMAT = 'Please enter a valid phone number.'
export const PHONE_WARNING_TEXT =
    'By providing your phone number, you agree to be contacted by Vibee by phone or text message.'
export const PLACE_HOLDER_OPTION = '----'
export const REFRESH_REMIND = 'An error occurred, please refresh and try again.'
export const CONFIRM_NEW_BILLING_INFO = 'Please confirm your new billing information.'
export const EMAIL_ALREADY_USED_IN_SPLIT_PAY =
    'This email is already being used in split pay. Please enter a different email address'
export const ENTER_VALID_EMAIL_ADDRESS = 'Please enter a valid email address.'
export const ENTER_VALID_POSTAL_CODE = 'Please enter a valid postal code.'
export const ENTER_VALID_PHONE = 'Please enter a valid phone.'

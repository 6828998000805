import { gql } from "@apollo/client";
import { usersClient } from "../apollo-client";
import { useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavbarState } from "../store";


const FORGOT_PASSWORD = gql`
mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    success
  }
}
`;


export default function ForgetPwd() {
  const [email, setEmail] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const navState: NavbarState = useSelector((state: RootStateOrAny) => state.navbarReducer);
  const dispatch = useDispatch();

  const validateEmail = (v: string) => {
    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]+)+$/;
    const isValid = emailReg.test(v);
    setEmailError(isValid ? '' : 'Please enter a valid email address.');
    return isValid;
  }

  return (
    <>
      {
        navState.expandedNav ?
          <div className="login-header p-3 pb-2">
            <h4 className="headline-3 fw-bold mb-0">Forgot Password</h4>
          </div>
          : ''
      }
      <div className="login-wrapper p-3">
        {
          <form className="LoginForm" id="registrationForm" noValidate autoComplete="off" autoCapitalize="off">
            <div className="form-floating mb-3">
              <input type="email" className="form-control is-invalid" id="floatingInput" placeholder="name@example.com" value={email} onChange={e => {
                setEmail(e.target.value.trim());
                validateEmail(e.target.value.trim());
              }} onBlur={(e) => {
                validateEmail(e.target.value.trim());
              }} />
              <label htmlFor="floatingInput">Email address</label>
              <div id="" className="invalid-feedback mt-0">
                {emailError}
              </div>
            </div>
            <span className="btn btn-block btn-purple mb-4" onClick={(e) => {
              e.stopPropagation();
              const isValid = validateEmail(email || '');
              if (!isValid) return;
              usersClient.mutate({
                mutation: FORGOT_PASSWORD,
                variables: {
                  email: email
                },
                fetchPolicy: "no-cache",
              }).then(({ data: { forgotPassword: { success } } }) => {
                  dispatch({ type: 'navbar/setState', payload: { resetPassword: true } });
              })
            }}>Reset Password</span>
          </form>
        }
      </div>
    </>
  )
}
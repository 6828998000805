import dayjs from 'dayjs'
import { DATE_FORMAT, InsureTarget, TravelGuardSelection } from 'enums'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { CheckoutInsuranceDetailsParams } from 'store'
import { getTravelGuardQuote } from 'store/actions/fetchCart'
import { debounce } from 'util/cart'
import { TravelGuardInfoType, TravelGuardPriceType } from 'types/travelGuard'
import { validateBirthDate, validateEmail } from 'util/validate'
import { TRAVEL_GUARD_MAX_AGE } from 'consts'

const blankTravelGuardInfo = {
    birthDate: '',
    insureTarget: '',
    selected: ''
}

const blankTravelGuardPrice = {
    benefitCode: '',
    benefitDetailsAmountsBasePremiumValue: '',
    productDetailsAmountsTotalBasePremium: '',
    productDetailsAmountsTotalPremiumValue: '',
    productDetailsAmountsTotalStandardPremium: ''
}

type UseTravelGuardReturnType = {
    checkTravelGuardRequestParameters: (
        birthDate: string | Date,
        country: string,
        email: string,
        firstName: string,
        insureTarget: string,
        isSplitPay: boolean,
        lastName: string,
        state: string
    ) => boolean
    debouncedSendTravelGuardQuoteRequest: (...args: any) => void
    getInsuranceDetails: (
        braintreePaymentNonce: string | null,
        country: string,
        coveredAmount: number
    ) => CheckoutInsuranceDetailsParams | null
    hasFilledBasicInfo: boolean
    isLoadingRequest: boolean
    resetTravelGuardForm: () => void
    setTravelGuardInfoErrors: Dispatch<SetStateAction<TravelGuardInfoType>>
    travelGuardFormOnChange: (key: keyof TravelGuardInfoType, value: string) => void
    travelGuardInfo: TravelGuardInfoType
    travelGuardInfoErrors: TravelGuardInfoType
    travelGuardPrice: TravelGuardPriceType
}

type UseTravelGuardProps = {
    cartIdOrOrderId: string
    isTravelGuardEnabled: boolean
    ordersUrl: string
}

export const useTravelGuard = ({
    cartIdOrOrderId,
    isTravelGuardEnabled,
    ordersUrl
}: UseTravelGuardProps): UseTravelGuardReturnType => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [shouldLoad, setShouldLoad] = useState<boolean>(true)
    const [travelGuardInfo, setTravelGuardInfo] =
        useState<TravelGuardInfoType>(blankTravelGuardInfo)
    const [travelGuardInfoErrors, setTravelGuardInfoErrors] =
        useState<TravelGuardInfoType>(blankTravelGuardInfo)
    const [travelGuardPrice, setTravelGuardPrice] =
        useState<TravelGuardPriceType>(blankTravelGuardPrice)
    const [hasFilledBasicInfo, setHasFilledBasicInfo] = useState<boolean>(false)
    const controllerRef = useRef<AbortController | null>(null)

    const birthDateOnChange = (value: string) => {
        setTravelGuardInfo({
            ...travelGuardInfo,
            birthDate: value
        })
        if (!validateBirthDate(value, TRAVEL_GUARD_MAX_AGE)) {
            setTravelGuardPrice(blankTravelGuardPrice)
            return
        }
    }

    const checkTravelGuardRequestParameters = (
        birthDate: string | Date,
        country: string,
        email: string,
        firstName: string,
        insureTarget: string,
        isSplitPay: boolean,
        lastName: string,
        state: string
    ) => {
        const emailErrorMsg = validateEmail(email)
        if (emailErrorMsg) {
            setHasFilledBasicInfo(false)
            return false
        }
        if (country !== 'US') {
            setHasFilledBasicInfo(false)
            return false
        }
        if (!state || state === 'State') {
            setHasFilledBasicInfo(false)
            return false
        }
        if (!firstName.trim() || !lastName.trim() || !country) {
            setHasFilledBasicInfo(false)
            return false
        }
        setHasFilledBasicInfo(true)
        if (!validateBirthDate(birthDate, TRAVEL_GUARD_MAX_AGE)) {
            return false
        }
        if (isSplitPay && !insureTarget) {
            return false
        }
        return true
    }

    const getInsuranceDetails = (
        braintreePaymentNonce: string | null,
        country: string,
        coveredAmount: number
    ): CheckoutInsuranceDetailsParams | null => {
        if (!isTravelGuardEnabled || country !== 'US') return null
        const { birthDate, insureTarget, selected } = travelGuardInfo
        if (selected === TravelGuardSelection.NO) return null
        const {
            benefitCode,
            benefitDetailsAmountsBasePremiumValue,
            productDetailsAmountsTotalBasePremium,
            productDetailsAmountsTotalStandardPremium,
            productDetailsAmountsTotalPremiumValue
        } = travelGuardPrice

        const cancelCoverage = selected === TravelGuardSelection.YES_WITH_CANCEL
        const newBenefitCode = cancelCoverage ? 'CFAR' : benefitCode
        const insuranceDetailsParams: CheckoutInsuranceDetailsParams = {
            braintreePaymentNonce,
            cancelCoverage,
            benefitCode: newBenefitCode,
            insuredAll: insureTarget === InsureTarget.EVERYONE,
            travelerDateOfBirth: dayjs(birthDate).format('YYYY-MM-DD'),
            benefitDetailsAmountsBasePremiumValue,
            coveredAmount,
            productDetailsAmountsTotalBasePremium,
            productDetailsAmountsTotalPremiumValue,
            productDetailsAmountsTotalStandardPremium
        }
        return insuranceDetailsParams
    }

    const resetTravelGuardForm = () => {
        const { birthDate, insureTarget, selected } = travelGuardInfo
        if (birthDate || insureTarget || selected) {
            setTravelGuardInfo(blankTravelGuardInfo)
        }
        const {
            birthDate: birthDateError,
            insureTarget: insureTargetError,
            selected: selectedError
        } = travelGuardInfoErrors
        if (birthDateError || insureTargetError || selectedError) {
            setTravelGuardInfoErrors(blankTravelGuardInfo)
        }
        const { productDetailsAmountsTotalBasePremium, productDetailsAmountsTotalPremiumValue } =
            travelGuardPrice
        if (productDetailsAmountsTotalBasePremium || productDetailsAmountsTotalPremiumValue) {
            setTravelGuardPrice(blankTravelGuardPrice)
        }
    }

    const resetTravelGuardFormFieldErrors = (key: keyof TravelGuardInfoType) => {
        if (travelGuardInfoErrors[key]) {
            setTravelGuardInfoErrors({
                ...travelGuardInfoErrors,
                [key]: ''
            })
        }
    }

    const sendTravelGuardQuoteRequest = useCallback(
        async (
            birthDate: string | Date,
            cancelCoverage: boolean,
            cartOrOrder: 'cart' | 'order',
            country: string,
            email: string,
            firstName: string,
            coveredAmount: number,
            insuredAll: boolean,
            isLayaway: boolean,
            lastName: string,
            phone: string,
            state: string,
            memberId?: string
        ): Promise<void> => {
            if (controllerRef.current) {
                controllerRef.current.abort()
            }
            controllerRef.current = new AbortController()
            let memberIdParams = {}
            if (cartOrOrder === 'order' && memberId) {
                memberIdParams = {
                    memberID: memberId
                }
            }
            setIsLoading(true)
            try {
                const response = (await getTravelGuardQuote(
                    cartOrOrder,
                    cartIdOrOrderId,
                    ordersUrl,
                    {
                        cancelCoverage,
                        coveredAmount,
                        insuredAll,
                        isLayaway,
                        travelerFirstName: firstName,
                        travelerLastName: lastName,
                        travelerDateOfBirth: dayjs(birthDate).format(DATE_FORMAT),
                        travelerEmail: email,
                        travelerPhone: phone,
                        travelerResidencyAddressState: state,
                        travelerResidencyAddressCountry: country,
                        ...memberIdParams
                    },
                    controllerRef.current.signal
                )) as any
                let travelGuardPrice = blankTravelGuardPrice
                const amountValuesList =
                    response.quoteResponse.quoteResponses[0].purchaseRequest.productDetails[0]
                        .amounts[0].amountValues
                const totalPremium =
                    amountValuesList.find((item: { type: string }) => item.type === 'TotalPremium')
                        ?.value || ''
                const standardPremium =
                    amountValuesList.find(
                        (item: { type: string }) => item.type === 'StandardPremium'
                    )?.value || ''
                const basePremium =
                    amountValuesList.find((item: { type: string }) => item.type === 'BasePremium')
                        ?.value || ''
                travelGuardPrice.productDetailsAmountsTotalPremiumValue = totalPremium
                travelGuardPrice.productDetailsAmountsTotalStandardPremium = standardPremium
                travelGuardPrice.productDetailsAmountsTotalBasePremium = basePremium

                const benefitDetailList =
                    response.quoteResponse.quoteResponses[0].purchaseRequest.productDetails[0]
                        .benefitDetails
                const benefitDetailAmountValuesList =
                    benefitDetailList.find(
                        (item: { benefitCode: string }) => item.benefitCode === 'CFAR'
                    )?.amounts[0].amountValues || []
                const benefitDetailBasePremiumValue =
                    benefitDetailAmountValuesList.find(
                        (item: { type: string }) => item.type === 'BasePremium'
                    )?.value || ''
                const benefitCode =
                    benefitDetailList.find((item: { isStandard: any }) => item.isStandard)
                        ?.benefitCode || ''
                travelGuardPrice.benefitDetailsAmountsBasePremiumValue =
                    benefitDetailBasePremiumValue
                travelGuardPrice.benefitCode = benefitCode
                setTravelGuardPrice(
                    normalizeDecimalPlaces(travelGuardPrice) as TravelGuardPriceType
                )
                setIsLoading(false)
            } catch (error: any) {
                setIsLoading(false)
                if (error.name === 'AbortError') {
                    return
                } else {
                    setTravelGuardPrice(blankTravelGuardPrice)
                }
            }
            controllerRef.current = null
        },
        [cartIdOrOrderId, ordersUrl]
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSendTravelGuardQuoteRequest = debounce(async (...args: any) => {
        if (!shouldLoad) {
            setShouldLoad(true)
            return
        }
        // @ts-ignore
        await sendTravelGuardQuoteRequest(...args)
    }, 250)

    const travelGuardFormOnChange = (key: keyof TravelGuardInfoType, value: string) => {
        switch (key) {
            case 'birthDate':
                birthDateOnChange(value)
                setShouldLoad(true)
                break
            case 'insureTarget':
                setTravelGuardInfo({
                    ...travelGuardInfo,
                    insureTarget: value
                })
                setShouldLoad(true)
                break
            case 'selected':
                setShouldLoad(false)
                setTravelGuardInfo({
                    ...travelGuardInfo,
                    selected: value
                })
                break
            default:
                const exhaustiveCheck: never = key
                throw new Error(`Unhandled case ${exhaustiveCheck}`)
        }
        resetTravelGuardFormFieldErrors(key)
    }

    // a function that loops travelguard price, checks for presence of a non-zero number with a decimal / period, and if that is available check if the number has 2 digits in the decimal place or just one. if it only has one decimal digit then set the 2nd digit to 0
    const normalizeDecimalPlaces = (obj: Record<string, string>): Record<string, string> => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                // Check if the value is a non-zero decimal number
                const value = obj[key]
                if (value.match(/^\d*\.\d+$/)) {
                    // Check the number of digits after the decimal
                    const parts = value.split('.')
                    const decimalPart = parts[1]
                    if (decimalPart.length === 1) {
                        // If only one digit, append '0'
                        obj[key] = parts[0] + '.' + decimalPart + '0'
                    }
                }
            }
        }
        return obj
    }

    useEffect(() => {
        // This is to prevent the request from being sent
        // when the component is first mounted
        return () => {
            if (controllerRef.current) {
                controllerRef.current.abort()
            }
        }
    }, [])

    return {
        checkTravelGuardRequestParameters,
        debouncedSendTravelGuardQuoteRequest,
        getInsuranceDetails,
        hasFilledBasicInfo,
        isLoadingRequest: isLoading,
        resetTravelGuardForm,
        setTravelGuardInfoErrors,
        travelGuardFormOnChange,
        travelGuardInfo,
        travelGuardInfoErrors,
        travelGuardPrice
    }
}

import { DocumentNode, gql } from '@apollo/client'

export const GET_ORDER_ID_QUERY: DocumentNode = gql`
    query ListEvents($limit: Int!, $offset: Int!, $filters: EventFilters) {
        listEvents(limit: $limit, offset: $offset, filters: $filters) {
            data {
                version
                showTicketPrice
                hotels {
                    id
                    name
                    location
                    checkInTime
                    checkOutTime
                    locationDisplay
                    coordinates {
                        lat
                        lng
                    }
                    roomAmenities {
                        id
                        title
                        icon
                        items
                    }
                    description
                    headerTitle
                    headerImage {
                        url
                        size
                        format
                        position
                        id
                        height
                        width
                    }
                    rating
                    resortFee
                    overBaseOccupancyFee
                    rooms {
                        id
                        name
                        description
                        headerImage {
                            url
                            size
                            format
                            position
                            id
                            height
                            width
                        }
                        tiers {
                            id
                            name
                            onSale
                            onSaleDate
                            quantityAvailable
                            price
                            prices {
                                id
                                hotelRoomTierID
                                size
                                price
                                quantityAvailable
                                taxesAndFees
                                taxBreakdown {
                                    name
                                    amount
                                }
                            }
                        }
                        quantityAvailable
                        sleeps
                        numberOfBeds
                        baseOccupancy
                        shoulderNights {
                            id
                            eventID
                            hotelRoomID
                            bookDate
                            quantityAvailable
                            taxesAndFees
                        }
                    }
                    amenities {
                        id
                        title
                        icon
                        items
                    }
                }
                addOnItems {
                    id
                    categoryID
                    name
                    description
                    price
                    pricePerPerson
                    pricePerRoom
                    quantityAvailable
                    lowStockLevel
                    onSale
                    onPostPurchase
                    required
                    hidden
                    taxesAndFees
                    headerImage {
                        id
                        url
                    }
                }
                addOnCategories {
                    description
                    groupLimit
                    id
                    title
                    headerImage {
                        id
                        url
                    }
                }
                id
                slug
                headerTitle
                headerImage {
                    url
                    size
                    format
                    position
                    id
                    height
                    width
                }
                name
                location
                locationDisplay
                description
                timezone
                currency
                dateDisplay
                startDate
                endDate
                checkInDate
                checkOutDate
                itinerary {
                    id
                    date
                    label
                }
                items {
                    name
                }
                tags {
                    label
                }
                tickets {
                    id
                    name
                    eventNameOverride
                    description
                    minAge
                    status
                    tiers {
                        id
                        name
                        description
                        onSale
                        onSaleDate
                        price
                        taxesAndFees
                    }
                }
                faqs {
                    question
                    answer
                    id
                    categoryID
                }
                faqCategories {
                    categoryName
                    id
                    position
                }
                layawayDates {
                    date
                    depositAmount
                    depositIsPercentage
                }
                depositIsPerPerson
            }
        }
    }
`

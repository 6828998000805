import { HotelRoomJSON } from '../../../events-service/src/models/hotelRoom'
import { HotelRoomShoulderNightJSON } from '../../../events-service/src/models/hotelRoomShoulderNight'
import { HotelRoomTierJSON } from '../../../events-service/src/models/hotelRoomTier'
import { HotelRoomTierPriceJSON } from '../../../events-service/src/models/hotelRoomTierPrice'
import { TicketJSON } from '../../../events-service/src/models/ticket'
import { TierJSON } from '../../../events-service/src/models/tier'

export const formatMoney = (event: { currency: any }) => {
    const formattedCurrency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: (event.currency || 'USD').toUpperCase()
    }).format
    return formattedCurrency
}

export const handleAssignRoomCarryBit = (assignRoomArray: any, rooms: any[], i: any) => {
    let tryRoom = assignRoomArray[i]
    if (tryRoom > 4) {
        assignRoomArray[i] = 0
        if (i !== rooms.length - 1) {
            assignRoomArray[i + 1] += 1
            assignRoomArray = handleAssignRoomCarryBit(assignRoomArray, rooms, i + 1)
        } else {
            assignRoomArray.push(-1)
        }
    }
    return assignRoomArray
}

export const handleArrangeSleepsCarryBit = (roomSleepsList: any[], rooms: any[], i: any) => {
    let trySleep = roomSleepsList[i][1]
    let sleep = rooms[roomSleepsList[i][0]].sleeps
    if (trySleep > sleep) {
        roomSleepsList[i][1] = rooms[roomSleepsList[i][0]].minimumOccupancy
        if (i !== roomSleepsList.length - 1) {
            roomSleepsList[i + 1][1] += 1
            handleArrangeSleepsCarryBit(roomSleepsList, rooms, i + 1)
        } else {
            roomSleepsList.push(-1)
        }
    }
    return roomSleepsList
}

// This function filters out invalid room assignments
export const filterAssignments = (assignments: number[][], groupSize: number, rooms: any[]) => {
    return assignments.filter((assignRoomArray) => {
        if (assignRoomArray.filter((r) => r !== 0).length > 4) {
            return false
        }

        if (
            assignRoomArray
                .filter((r) => r !== 0)
                .reduce((previous, current) => previous + current, 0) > groupSize
        ) {
            return false
        }

        let anyNotAvailable = false
        for (let i = 0; i < assignRoomArray.length; i++) {
            let assignRoom = assignRoomArray[i]
            if (assignRoom !== 0) {
                anyNotAvailable = anyNotAvailable || rooms[i].quantityAvailable < assignRoomArray[i]
            }
        }
        if (anyNotAvailable) {
            return false
        }

        let availableSleep = 0
        for (let i = 0; i < assignRoomArray.length; i++) {
            let assignRoom = assignRoomArray[i]
            if (assignRoom !== 0) {
                availableSleep = availableSleep + rooms[i].sleeps * assignRoom
            }
        }

        if (availableSleep < groupSize) {
            return false
        }

        return true
    })
}

// This function generates all possible room assignments
export const generateAssignments = (rooms: any[]) => {
    let roomCount = rooms.length
    let assignRoomArray: number[] = []
    for (let i = 0; i < roomCount; i++) {
        assignRoomArray.push(0)
    }
    let assignments: number[][] = []
    while (true) {
        assignRoomArray[0] += 1
        assignRoomArray = handleAssignRoomCarryBit(assignRoomArray, rooms, 0)
        if (assignRoomArray.length > rooms.length) {
            break
        } else {
            assignments.push([...assignRoomArray])
        }
    }
    return assignments
}

export const isSoldOut = (
    prices: HotelRoomTierPriceJSON[],
    groupSize: number,
    inventory: Record<string, number>
) => {
    return (
        prices.filter((t) => inventory[t.id] === 0 && t.size <= groupSize).length ===
        prices.filter((r) => r.size <= groupSize).length
    )
}

export const getEarliestOnSaleDate = (v: {
    tiers: { id: string; onSale: boolean; onSaleDate?: Date | string }[]
}) => {
    let earliestOnSaleDate: Date | undefined
    for (let t = 0; t < v.tiers.length; t++) {
        const tier = v.tiers[t]
        if (!tier.onSale || !tier.onSaleDate) continue
        const onSaleDate = new Date(tier.onSaleDate!)
        if (!earliestOnSaleDate || earliestOnSaleDate.getTime() > onSaleDate.getTime()) {
            earliestOnSaleDate = onSaleDate
        }
    }
    return earliestOnSaleDate
}

export const getRoomTierTimeSaleAble = (tier: HotelRoomTierJSON, codeIsRight?: boolean) => {
    let nowTime: Date = new Date()
    if (!tier.onSaleDate) {
        return true
    }
    const onSaleDate = new Date(tier.onSaleDate!)
    if (nowTime.getTime() > onSaleDate.getTime() || codeIsRight) {
        return true
    }
    return false
}

export const isRoomTierSaleAble = (tier: HotelRoomTierJSON, codeIsCorrect = false): boolean => {
    const nowTime = Date.now()
    const onSaleDate = tier.onSaleDate ? new Date(tier.onSaleDate) : null
    return !onSaleDate || nowTime > onSaleDate.getTime() || codeIsCorrect
}

export const getRoomTier = (
    codeIsCorrect = false,
    groupSize: number,
    inventory: Record<string, number>,
    room: HotelRoomJSON
): HotelRoomTierJSON | undefined => {
    const availableTiers = room.tiers.filter((tier) => {
        const availablePrices = tier.prices.filter(
            (price) =>
                inventory[price.id] > 0 &&
                price.size <= groupSize &&
                isRoomTierSaleAble(tier, codeIsCorrect)
        )
        return availablePrices.length > 0
    })
    return availableTiers[0]
}

export const updateInventory = (
    tier: HotelRoomTierJSON,
    inventory: Record<string, number>,
    pricingId: string
): Record<string, number> => {
    const pricing = tier.prices.find((price) => price.id === pricingId)
    if (pricing?.quantityAvailable === -1) {
        const otherPrices = tier.prices.filter(
            (price) =>
                price.id !== pricingId && price.quantityAvailable === -1 && inventory[price.id] > 0
        )
        otherPrices.forEach((price) => {
            inventory[price.id] -= 1
        })
    }
    return inventory
}

export const getTicketTier = (
    ticket: TicketJSON,
    inventory: Record<string, number>,
    inCartTickets: Record<string, number>
): TierJSON | undefined => {
    const ticketTiers = ticket.tiers
        .filter((t) => inventory[t.id] > 0 || !!inCartTickets[t.id])
        .sort((a, b) => a.price - b.price)
    if (ticketTiers.length) return ticketTiers[0]
}

export const getLowerTicketTier = (ticket: TicketJSON): TierJSON | undefined => {
    // Get the cheapest price whether it is sold out or not
    const ticketTiers = ticket.tiers.filter((t) => t).sort((a, b) => a.price - b.price)
    if (ticketTiers.length) return ticketTiers[0]
}

export type ArrangePriceType = {
    allNights: any
    codeIsCorrect: boolean
    groupSize: number
    inventory: Record<string, number>
    isShoulderNights: boolean
    overBaseOccupancyFee: number
    hotelRooms: any[]
    roomSleepsList: any[]
    selectedGroupSize: number
    shoulderNightsArray: Record<string, any[]>
}

export const getArrangePrice = (
    roomSleepsList: [number, number][],
    rooms: HotelRoomJSON[],
    overBaseOccupancyFee: number,
    inventory: Record<string, number>,
    shoulderNightsArray: Record<string, HotelRoomShoulderNightJSON[]>,
    isShoulderNights: boolean,
    selectedGroupSize: number,
    codeIsRight?: boolean
): number => {
    let price = 0
    let calculationTimes = 0
    let temporaryInventory = { ...inventory }
    let baseOccupancyTotal = 0
    let nightInventoryEnough = true

    for (let i = 0; i < roomSleepsList.length; i++) {
        let [room_index, sleep] = roomSleepsList[i]
        let room = rooms[room_index]
        baseOccupancyTotal += room.baseOccupancy
        let roomTier = getRoomTier(codeIsRight, sleep, inventory, room)

        if (!roomTier) {
            continue
        }

        let sizePrice = roomTier.prices.filter((p) => inventory[p.id] > 0 && p.size <= sleep)
        if (sizePrice.length === 0) {
            continue
        }

        calculationTimes++
        let selectedPrice = sizePrice[0]

        if (isShoulderNights) {
            let nightsList: HotelRoomShoulderNightJSON[] = []
            Object.entries(shoulderNightsArray).map(([id, night]) => {
                if (id === room.id) {
                    nightsList = night
                }
            })

            if (nightsList.length === 0) {
                nightInventoryEnough = false
            }

            nightsList.map((n) => {
                if (temporaryInventory[n.id] > 0) {
                    temporaryInventory[n.id] -= 1
                    price += n.price
                } else {
                    nightInventoryEnough = false
                }
            })
        }

        temporaryInventory[selectedPrice.id] -= 1
        temporaryInventory = updateInventory(roomTier, temporaryInventory, selectedPrice.id)

        price += selectedPrice.price
    }

    let overBaseFee = 0
    if (selectedGroupSize - baseOccupancyTotal > 0) {
        overBaseFee = (selectedGroupSize - baseOccupancyTotal) * overBaseOccupancyFee
    }

    price += overBaseFee

    if (calculationTimes !== roomSleepsList.length) {
        price = 99999999 // mean room sold out
    } else if (!nightInventoryEnough) {
        price = 99999998 // mean room Unavailable on Selected Dates
    }

    return price
}

export const lowestPrice = (
    v: { tiers: { id: string; price: number }[] },
    inventory: Record<string, number>,
    inCartTickets: Record<string, number>
) => {
    return v.tiers
        .filter((t) => inventory[t.id] > 0 || !!inCartTickets[t.id])
        .reduce<number>(
            (carry, current) => (current.price < carry ? current.price : carry),
            v.tiers[0].price
        )
}

import { EventJSON } from '../../../events-service/src/models/event'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import { ShoppingCartItem } from '../store'

export const getDepositAmount = (e: EventJSON): number => {
    if (!e || !e.layawayDates || e.layawayDates.length === 0) {
        return 0
    }
    const now = new Date()
    const dates = [...e.layawayDates]
        .sort((a, b) => {
            return new Date(a.date).valueOf() - new Date(b.date).valueOf()
        })
        .map((d) => {
            return {
                ...d,
                date: new Date(d.date)
            }
        })
    for (let i = 0; i < e.layawayDates.length; i++) {
        if (dates[i].date.valueOf() > now.valueOf() && i !== 0) {
            return dates[i - 1].depositAmount
        }
    }
    return dates[dates.length - 1].depositAmount
}

export const getDepositIsPercentage = (e: EventJSON): boolean => {
    if (!e || !e.layawayDates || e.layawayDates.length === 0) {
        return false
    }
    const now = new Date()
    const dates = [...e.layawayDates]
        .sort((a, b) => {
            return new Date(a.date).valueOf() - new Date(b.date).valueOf()
        })
        .map((d) => {
            return {
                ...d,
                date: new Date(d.date)
            }
        })
    for (let i = 0; i < e.layawayDates.length; i++) {
        if (dates[i].date.valueOf() > now.valueOf() && i !== 0) {
            return dates[i - 1].depositIsPercentage
        }
    }
    return dates[dates.length - 1].depositIsPercentage
}

export const calculateInventoryIncludeCard = (
    inventory: Record<string, number>,
    cartItem: ShoppingCartItem[],
    hotel: HotelJSON[] | undefined
) => {
    if (cartItem?.length === 0 || !cartItem) return inventory
    let newInventory = JSON.parse(JSON.stringify(inventory))
    cartItem.map((item) => {
        if (newInventory[item.itemID]) {
            newInventory[item.itemID] += item.quantity
        } else {
            newInventory[item.itemID] = item.quantity
        }
        if (item.type === 'room.tier.price' && hotel) {
            hotel
                .flatMap((h) => h.rooms)
                .flatMap((r) => r.tiers)
                .map((t) => {
                    t.prices?.map((p) => {
                        if (p.id === item.itemID && p.quantityAvailable === -1) {
                            t.prices.map((n) => {
                                if (n.quantityAvailable === -1 && n.id !== item.itemID) {
                                    if (newInventory[n.id]) {
                                        newInventory[n.id] += item.quantity
                                    } else {
                                        newInventory[n.id] = item.quantity
                                    }
                                }
                            })
                        }
                    })
                })
        }
    })
    return newInventory
}

export const getAdditionalShoulderNightsFromItem = (item: any[]) => {
    if (item.length === 0) return 0
    let shouldNightsCount = 0
    const roomNumber = item.reduce((pre, cur) => {
        if (cur.type === 'room') return pre + 1
        if (cur.type === 'room.shouldernight') shouldNightsCount += 1
        return pre
    }, 0)
    return shouldNightsCount / roomNumber
}

export const getEventNameOverride = (
    event: EventJSON | undefined,
    selectedTicketNumber: string
) => {
    if (!event) {
        return ''
    }
    const eventNameOverride =
        event.tickets.find((ticket) => {
            return ticket.id === selectedTicketNumber
        })?.eventNameOverride || ''
    return eventNameOverride
}

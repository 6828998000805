import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { isMobile, isTablet } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { EventJSON } from '../../../../events-service/src/models/event'
import { HotelJSON } from '../../../../events-service/src/models/hotel'
import { HotelRoomJSON } from '../../../../events-service/src/models/hotelRoom'
import { HotelRoomTierJSON } from '../../../../events-service/src/models/hotelRoomTier'
import { TicketJSON } from '../../../../events-service/src/models/ticket'
import { TierJSON } from '../../../../events-service/src/models/tier'
import { ZoneJSON } from '../../../../events-service/src/models/zone'
import { eventsWithoutJwtClient } from '../../apollo-client'
import BodyClass from '../../components/bodyClass'
import EventBottomHero from '../../components/eventBottomHero'
import { EventFAQ } from 'components/eventFAQ'
import EventFeaturesAndBenefitsSection from '../../components/eventFeaturesAndBenefitsSection'
import EventMap from '../../components/eventMap'
import { EventTimeLine } from '../../components/Slug/EventTimeLine'
import HotelCard from '../../components/hotelCard'
import SelectTicketNotification from '../../components/selectTicketNotification'
import { TicketTypeSelection } from '../../components/Slug/TicketTypeSelection'
import { AddToCartInfo, ShoppingCartState } from '../../store'
import { checkCode, fetchCart } from '../../store/actions/fetchCart'
import { getInventory } from '../../store/actions/fetchOrders'
import {
    allRoomIsFuture,
    dateRange,
    getShoulderNightsByHotelId,
    getTimeZone,
    now,
    timeZoneString
} from '../../util/dates'
import useHive from '../../util/hiveHooks'
import Scroll from 'react-scroll'
import { MobileNextArrow, MobilePrevArrow, NextArrow, PrevArrow } from '../../components/slickArrow'
import { calculateInventoryIncludeCard, getEventNameOverride } from '../../util/event'
import { getImage } from '../../util/getEventHeaderImageByDevice'
import GuestsModal from '../../components/guestsModal'
import getConfig from 'next/config'
import PreSaleModal from '../../components/preSaleModal'
import { HotelRoomTierPriceJSON } from '../../../../events-service/src/models/hotelRoomTierPrice'
import BreadCrumbsNavbar from '../../components/breadCrumbsNavbar'
import { useAppSelector } from '../../hooks'
import { formatMoney, handleAmountsPoint, newAssignRoom } from '../../util/parse'
import { GET_EVENTS_PAGE_QUERY } from '../../graphQL/events/getEventsPageQuery.graphql'
import Image from 'components/image'
import { getEarliestOnSaleDate } from 'util/pricing'
import HotelCardImages from 'components/hotelCardImages'
import { TicketExperienceJSON } from '../../../../events-service/src/models/ticketExperience'
import { TicketExperienceTierJSON } from '../../../../events-service/src/models/ticketExperienceTier'
import { useCalculate } from 'hooks/useCalculate'
import { useWindowWidth } from 'hooks/useWidth'
import {
    APP_NAME,
    GAProductType,
    googleAnalyticsEvent,
    GoogleAnalyticsEventActionType
} from 'components/Google/GoogleAnalytics'
import { EventNotIncludeTicketBanner } from 'components/EventNotIncludeTicketBanner'
import { DateRangePickerModalComponent } from 'components/DatePickerModal'
import { useHotelOnly } from 'hooks/useHotelOnly'
import { EventNotIncludeTicketModal } from 'components/eventNotIncludeTicketModal'
import Skeleton from '@mui/material/Skeleton'
import { FULL_WIDTH, SIZE_22, SIZE_30 } from 'styles/theme'
import { EventTop } from 'components/Slug/EventTop'
import { EventIntroduction } from 'components/Slug/EventIntroduction'
import {
    FaqsCategoriesType,
    FaqsType,
    HotelPriceType,
    NavbarUrlType,
    OptionType,
    TicketTierItemType
} from 'types/event'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useStyles } from 'components/Slug/styles/slug.styles'
import { INIT_UUID } from 'consts'

const { publicRuntimeConfig } = getConfig()

let ScrollLink = Scroll.Link

function getSettings(arrowParent: string) {
    return {
        infinite: false,
        slidesToShow: 5,
        cssEase: 'ease',
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        speed: '600',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2,
                    prevArrow: <MobilePrevArrow parent={arrowParent} />,
                    nextArrow: <MobileNextArrow parent={arrowParent} />
                }
            },
            {
                breakpoint: 767,
                settings: {
                    infinite: false,
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    prevArrow: <MobilePrevArrow parent={arrowParent} />,
                    nextArrow: <MobileNextArrow parent={arrowParent} />
                }
            }
        ]
    }
}

export async function getServerSideProps(context: {
    res: { setHeader: (k: string, v: string) => void; req: any }
    params: { slug: string }
    query: any
}) {
    try {
        context.res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59')
        const { data } = await eventsWithoutJwtClient.query({
            query: GET_EVENTS_PAGE_QUERY,
            variables: {
                slug: context?.params?.slug,
                password: context?.query?.password || ''
            },
            fetchPolicy: 'no-cache'
        })

        const subDomain = publicRuntimeConfig.NEXT_PUBLIC_SUBDOMAIN
        let currentDomainForCors = 'https://vibee.com'
        if (subDomain) {
            currentDomainForCors = `https://${subDomain}.vibee.com`
        }

        return {
            props: {
                event: data.getEvent,
                password: context?.query?.password || '',
                meta: {
                    ...data.getEvent.meta,
                    url: currentDomainForCors + '/events/' + context?.params?.slug
                }
            }
        }
    } catch (e) {
        console.error('Error in server side props in events', e)
        return {
            redirect: {
                destination: '/',
                permanent: true
            }
        }
    }
}

export const isSoldOut = (room: { tiers: { id: string }[] }, inventory: Record<string, number>) => {
    if (Object.entries(inventory).length === 0) {
        return true
    }
    return room.tiers.filter((t) => inventory[t.id] === 0).length === room.tiers.length
}

export const roomIsSoldOut = (
    room: { tiers: { id: string; prices: HotelRoomTierPriceJSON[] }[] },
    inventory: Record<string, number>
) => {
    return (
        room.tiers.filter(
            (t) => t.prices.filter((p) => inventory[p.id] === 0).length === t.prices.length
        ).length === room.tiers.length
    )
}

const pxToNumber = (px: string) => {
    const num = Number(px.replace('px', ''))
    return num
}

export const getTierTimeSaleAble = (codeIsRight: boolean, tier: { onSaleDate?: Date | string }) => {
    const nowTime: Date = new Date()
    if (!tier.onSaleDate) {
        return true
    }
    const onSaleDate = new Date(tier.onSaleDate!)
    if (nowTime.getTime() > onSaleDate.getTime() || codeIsRight) {
        return true
    }
    return false
}

export const getRoomTier = (
    codeIsRight: boolean,
    groupSize: number,
    inventory: Record<string, number>,
    room: HotelRoomJSON,
    tierIndex?: number
): HotelRoomTierJSON | undefined => {
    const tiers = room.tiers.filter(
        (tier) =>
            inventory[tier.id] > 0 &&
            tier.onSale &&
            getTierTimeSaleAble(codeIsRight, tier) &&
            tier.prices.filter((price) => inventory[price.id] > 0 && price.size <= groupSize)
                .length > 0
    )
    if (tiers.length > 1 && tierIndex) {
        return tiers[tierIndex]
    } else {
        return tiers[0]
    }
}

export const getTicketTier = (
    ticket: TicketJSON,
    inventory: Record<string, number>
): TierJSON | undefined => {
    const ticketTiers = ticket.tiers
        .filter((t) => inventory[t.id] > 0)
        .sort((a, b) => a.price - b.price)
    if (ticketTiers.length) return ticketTiers[0]
}

export const getLowerTicketExperienceTier = (
    ticketTierId: string,
    ticketExperience: TicketExperienceJSON
): TicketExperienceTierJSON | undefined => {
    const ticketExperienceTiers = ticketExperience.tiers
        .filter((t) => t.ticketTierID === ticketTierId)
        .sort((a, b) => a.price - b.price)
    if (ticketExperienceTiers.length) return ticketExperienceTiers[0]
}

export const getLowerTicketTier = (ticket: TicketJSON): TierJSON | undefined => {
    const ticketTiers = ticket.tiers.filter((t) => t).sort((a, b) => a.price - b.price)
    if (ticketTiers.length) return ticketTiers[0]
}

export const getTicketExperiencePrice = (
    experience: TicketExperienceJSON | undefined,
    tiersTotal: TicketTierItemType[]
) => {
    let ticketExperiencePrice = 0
    if (experience) {
        tiersTotal.forEach((ticketTier) => {
            const experienceTier = experience.tiers.find(
                (tier) => tier.ticketTierID === ticketTier.id
            )
            if (!experienceTier) return
            ticketExperiencePrice +=
                (experienceTier.price + experienceTier.taxesAndFees) * ticketTier.quantity
        })
    }
    return ticketExperiencePrice
}

export const getTicketAndExperiencePrice = (
    event: EventJSON,
    groupSize: number,
    inventory: Record<string, number>,
    ticket: TicketJSON
) => {
    let temporaryInventory = { ...inventory }
    let remainingNeedQuantity = groupSize
    const ticketTiersTotal: TicketTierItemType[] = []
    let ticketTierPrice = 0
    while (remainingNeedQuantity > 0) {
        const tier = getTicketTier(ticket, temporaryInventory)
        if (!tier) break
        const quantity =
            temporaryInventory[tier.id] < remainingNeedQuantity
                ? temporaryInventory[tier.id]
                : remainingNeedQuantity
        ticketTiersTotal.push({
            id: tier.id,
            quantity: quantity,
            price: tier.price + tier.taxesAndFees
        })
        ticketTierPrice += (tier.price + tier.taxesAndFees) * quantity
        temporaryInventory[tier.id] -= quantity
        remainingNeedQuantity -= quantity
    }
    if (remainingNeedQuantity > 0) {
        return { ticketAndExperiencePrice: 0, error: 'Sold Out' }
    }
    const experience = event.ticketExperiences.find((t) => t.ticketID === ticket.id)
    const ticketExperiencePrices = getTicketExperiencePrice(experience, ticketTiersTotal)
    return { ticketAndExperiencePrice: ticketTierPrice + ticketExperiencePrices, error: '' }
}

const GUEST_OPTIONS = [
    { value: 1, label: '1 Guest', isDisabled: false },
    { value: 2, label: '2 Guests', isDisabled: false },
    { value: 3, label: '3 Guests', isDisabled: false },
    { value: 4, label: '4 Guests', isDisabled: false },
    { value: 5, label: '5 Guests', isDisabled: false },
    { value: 6, label: '6 Guests', isDisabled: false },
    { value: 7, label: '7 Guests', isDisabled: false },
    { value: 8, label: '8 Guests', isDisabled: false }
]

const DEFAULT_GUEST_OPTION = GUEST_OPTIONS[1]

const Event: NextPage<any> = ({ event, password }: { event: EventJSON; password: string }) => {
    const router = useRouter()
    const dispatch = useDispatch()
    const eventSlug = router.query.slug as string
    const isF1 = eventSlug.indexOf('-f1') !== -1 || eventSlug.indexOf('-F1') !== -1

    const [email, setEmail] = useState<string>('')
    const [hiveState, hiveSubscribe] = useHive(
        () => {
            setEmail('')
            console.log('Success: Added to segment: ' + event.name)
        },
        (data: any) => {
            setEmail('')
            console.log('Error: Add to segment ' + event.name + ' failed:', data)
        }
    )

    const [inCartRooms, setInCartRooms] = useState<Record<string, number>>({})
    const availableZones = event?.zones?.filter((zone) => {
        return event.tickets.find((ticket) => ticket.zones[0] && ticket.zones[0].id === zone.id)
    })
    const hasZones = availableZones.length > 0
    const initSelectedZone = hasZones ? availableZones[0] : undefined
    const [selectedZone, setSelectedZone] = useState<ZoneJSON | undefined>(initSelectedZone)
    const [selectedTicketZone, setSelectedTicketZone] = useState<ZoneJSON | undefined>(
        initSelectedZone
    )
    const [selectedHotelId, setSelectedHotelId] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [inventory, setInventory] = useState<Record<string, number>>({})
    const cart: ShoppingCartState = useAppSelector((state) => state.shoppingCartReducer)
    const eventTickets = event?.tickets?.sort((a, b) => {
        if (a.position !== b.position) {
            return a.position - b.position
        }
        return a.tiers[0].price - b.tiers[0].price
    })
    const queryParams = password ? `?password=${password}` : ''
    const isOnlySellHotel = event.hotels.length > 0 && event.tickets.length === 0
    const isOnlySellTickets =
        event.tickets.length > 0 && !event.hotels.some((hotel) => hotel.rooms.length > 0)
    const hasAddonsPage =
        event.addOnItems.length > 0 &&
        event.addOnItems.some(
            (addOnItem) => !addOnItem.required && !addOnItem.hidden && addOnItem.onSale
        )
    const scrollLinkNode = useRef<any>(null)
    const peopleNumber = 2
    const isShoulderNights = false
    const [submitTicketId, setSubmitTicketId] = useState<string>('')
    const [hotelPriceArray, setHotelPriceArray] = useState<Record<string, HotelPriceType[]>>({})

    const initTicketSelect = () => {
        const g: Record<string, number> = {}
        event.tickets.forEach((t) => {
            g[t.id] = 0
        })
        return g
    }
    const [selectedTicketGroupSize, setSelectedTicketGroupSize] = useState(initTicketSelect())
    const [submitting, setSubmitting] = useState<boolean>(false)
    const futureTicketsRef = useRef(new Set<string>())
    const [showGuestsModal, setShowGuestsModal] = useState<boolean>(false)
    const [groupSizeOptions, setGroupSizeOptions] = useState<typeof GUEST_OPTIONS>(GUEST_OPTIONS)
    const [selectedGuestsOption, setSelectedGuestsOption] = useState<OptionType>(
        groupSizeOptions[1]
    )
    const [guestsSubmitting, setGuestsSubmitting] = useState<boolean>(false)
    const [selectedOptionIsPlaceholder, setSelectedOptionIsPlaceholder] = useState<boolean>(true)
    const [isPreSale, setIsPreSale] = useState<boolean>(false)
    const [inPreSaleInterval, setInPreSaleInterval] = useState<boolean>(false)
    const [showPreSalePopUp, setShowPreSalePopUp] = useState<boolean>(false)
    const [code, setCode] = useState('')
    const [codeError, setCodeError] = useState<string>('')
    const [codeIsRight, setCodeIsRight] = useState<boolean>(false)
    const [allImageCardGallery, setAllImageCardGallery] = useState<boolean>(false)
    const [showSingleImage, setShowSingleImage] = useState<boolean>(false)
    const [hotelImageUrl, setHotelImageUrl] = useState<any[]>([])
    const { isMobileWidth } = useWindowWidth()
    const [showEventNotIncludeTicketModal, setShowEventNotIncludeTicketModal] =
        useState<boolean>(false)
    const [isReserving, setIsReserving] = useState<boolean>(false)
    const [eventNameOverrideTicketNumber, setEventNameOverrideTicketNumber] = useState<string[]>([])
    const [showEventNotIncludeTicketBanner, setShowEventNotIncludeTicketBanner] =
        useState<boolean>(false)
    const [isDateRangePickerModalOpen, setIsDateRangePickerModalOpen] = useState<boolean>(false)
    const [faqs, setFaqs] = useState<FaqsType[]>([])
    const [faqsCategories, setFaqsCategories] = useState<FaqsCategoriesType[]>([])
    const [selectedGroupSize, setSelectedGroupSize] = useState<number>(2)
    const [hasAddToCart, setHasAddToCart] = useState<boolean>(false)
    const [hasBeenToPreview, setHasBeenToPreview] = useState<boolean>(false)
    const [navbarUrl, setNavbarUrl] = useState<NavbarUrlType>({
        accommodation: '',
        addOn: '',
        groupSize: '',
        preview: '',
        room: ''
    })
    const { getLocalStorage, setLocalStorage } = useLocalStorage()
    const localCartInfo = getLocalStorage('addToCartInfo')
    const { totalTiersData, calculateCheapestPrice, getCheapestPrice } = useCalculate({
        event: event,
        inventory: inventory,
        cartInfo: localCartInfo
    })
    const shippingFee = useMemo(() => {
        return event.addOnItems.reduce((carry, addOn) => {
            if (addOn.required) {
                return (carry += addOn.price + addOn.taxesAndFees)
            }
            return carry
        }, 0)
    }, [event])
    const shouldShowCountdown: boolean = localCartInfo?.shouldShowCountdown ?? false
    const { classes } = useStyles()
    const {
        ageRequirement,
        currency,
        dateDisplay,
        description,
        endDate,
        headerImage,
        headerVideo,
        itinerary,
        location,
        locationDisplay,
        realEventEndDate,
        requiresPresaleUntil,
        requiresPresaleUntilEnd,
        startDate,
        ticketTips,
        timezone
    } = event

    const handleTicketNameResize = () => {
        let maxHeight = 0
        const ticketElementArray: HTMLElement[] = []
        event.tickets
            .filter((ticket) => !ticket.hidden)
            .map((t, index) => {
                const ticketNameElement: HTMLElement | null = document.getElementById(
                    `ticket_${index}`
                )
                if (ticketNameElement) {
                    ticketNameElement.style.height = 'auto'
                    ticketElementArray.push(ticketNameElement)
                    if (ticketNameElement.offsetHeight > maxHeight) {
                        maxHeight = ticketNameElement.offsetHeight
                    }
                }
            })
        if (maxHeight !== 0) {
            ticketElementArray.map((t) => (t.style.height = `${maxHeight}px`))
        }
    }

    const {
        addToCartDefaultValueRange,
        addToCartValue,
        cartFirstCheckDateOfLocationTimeRange,
        defaultValueRange,
        getCartFirstCheckDateRange,
        hasShoulderNights,
        maxSelectDate,
        minSelectDate,
        resetDatesToInitialEventDates,
        selectedDates,
        setMaxSelectDate,
        setMinSelectDate,
        updateDates,
        updateEventProp,
        textContent
    } = useHotelOnly({
        event,
        groupSize: selectedGroupSize,
        hasAddToCart,
        inventory,
        selectedHotelId
    })
    const hotel = event.hotels.find((hotel) => hotel.id === selectedHotelId)

    useEffect(() => {
        window.addEventListener('resize', handleTicketNameResize)
        return () => {
            window.removeEventListener('resize', handleTicketNameResize)
        }
    }, [])

    useEffect(() => {
        if (requiresPresaleUntil && requiresPresaleUntilEnd) {
            const activeStartDate = getTimeZone(requiresPresaleUntil, timezone)
            const activeEndDate = getTimeZone(requiresPresaleUntilEnd, timezone)
            if (activeStartDate < now(timezone) && now(timezone) < activeEndDate) {
                setInPreSaleInterval(true)
                if (!hasAddToCart) {
                    setIsPreSale(true)
                    setShowPreSalePopUp(true)
                }
                if (hasAddToCart && code !== cart.presaleCode) {
                    setCode(cart.presaleCode)
                    setCodeIsRight(true)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (scrollLinkNode.current) {
            const ticket = eventTickets.find((item) => item.zones[0].id === selectedZone?.id)
            if (!ticket) return
            scrollLinkNode.current.scrollTo('ticket_' + ticket.id, {
                spy: true,
                smooth: true,
                duration: 800,
                delay: 0
            })
        }
    }, [eventTickets, selectedZone])

    useEffect(() => {
        if (getTimeZone(realEventEndDate, timezone).isBefore(now(timezone))) {
            console.log('Event End!')
            router.push('/')
        }
    }, [realEventEndDate, router, timezone])

    useEffect(() => {
        if (hasAddToCart) {
            const selectedTicketNumberInCart =
                cart.items.find((item) => item.type === 'ticket')?.itemID || ''
            const eventNameOverride = getEventNameOverride(event, selectedTicketNumberInCart)
            if (eventNameOverride !== '') {
                setShowEventNotIncludeTicketBanner(true)
            }
        }
    }, [cart, event, hasAddToCart])
    useEffect(() => {
        let ticketsInCart: Record<string, number> = {}
        const load = async () => {
            const shoppingCartID =
                window.localStorage.getItem('shoppingCartID') || cart.id || uuid()
            const cartData: ShoppingCartState = await fetchCart(
                publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
                shoppingCartID
            )(dispatch)

            const notAdditionalOrder = cartData.purchaseAddOnsMemberId === INIT_UUID
            const hasAddToCart =
                cartData.items.length !== 0 && event.id === cartData.eventID && notAdditionalOrder
            const inventoryData = await getInventory(event.id, 8)
            const ticketsTierInCart = cartData.items.filter((i) => i.type === 'ticket.tier')
            const ticketsExperienceInCart = cartData.items.filter(
                (i) => i.type === 'ticket.experience.tier'
            )
            const roomsTierInCart = cartData.items.filter((i) => i.type === 'room.tier')
            event.tickets.forEach((t) => {
                const ticketTierInCart = ticketsTierInCart.find((tierInCart) => {
                    return t.tiers.find((tier) => {
                        return tier.id === tierInCart.itemID
                    })
                })

                ticketsInCart[t.id] = ticketTierInCart ? ticketTierInCart.quantity : 0
            })
            setInCartRooms(() => {
                roomsTierInCart.forEach((r) => {
                    inCartRooms[r.itemID] = r.quantity
                })
                return inCartRooms
            })
            if (hasZones && ticketsTierInCart.length > 0) {
                const inCartSelectedTicketZone = event.tickets.find(
                    (t) => t.id === ticketsTierInCart[0].parent
                )?.zones[0]
                setSelectedTicketZone(inCartSelectedTicketZone)
            }
            let option: OptionType | undefined
            if (event.id === localCartInfo.eventID) {
                option = GUEST_OPTIONS.find(
                    (guestOption) => guestOption.value === localCartInfo.groupSize
                )
                if (option) {
                    setSelectedOptionIsPlaceholder(false)
                }
            }
            setHasAddToCart(hasAddToCart)
            setSelectedGroupSize(option?.value || 2)
            setSelectedGuestsOption(option || DEFAULT_GUEST_OPTION)
            setInventory(calculateInventoryIncludeCard(inventoryData, cartData.items, event.hotels))
            setIsLoading(false)
        }
        load().catch(() => {
            setIsLoading(false)
        })
        setSelectedTicketGroupSize(ticketsInCart)
    }, [
        cart.id,
        dispatch,
        event,
        hasZones,
        inCartRooms,
        isOnlySellHotel,
        localCartInfo.eventID,
        localCartInfo.groupSize
    ])

    useEffect(() => {
        let hasBeenToPreview: boolean = false
        if (
            localCartInfo?.isShowShoppingBag !== undefined &&
            localCartInfo.comparedEventsIDInPreview !== undefined &&
            event.id === localCartInfo.comparedEventsIDInPreview
        ) {
            hasBeenToPreview = localCartInfo?.isShowShoppingBag
        }
        setHasBeenToPreview(hasBeenToPreview)
    }, [event, localCartInfo.comparedEventsIDInPreview, localCartInfo.isShowShoppingBag])

    useEffect(() => {
        window.localStorage.setItem('password', password)
    }, [password])

    useEffect(() => {
        const showFaqsCategories = event.faqCategories
            .filter((category) => {
                return event.faqs.some((faq) => {
                    return faq.categoryID === category.id
                })
            })
            .sort((a, b) => a.position - b.position)
        setFaqs(event.faqs.sort((a, b) => a.position - b.position))
        setFaqsCategories(showFaqsCategories)
    }, [event])

    useEffect(() => {
        if (isLoading) return
        const cardImg = document.querySelector('.js-card-img')
        const cardBody = document.querySelector('.js-card-body')

        if (cardImg && cardBody) {
            const cardBodyHeight = pxToNumber(getComputedStyle(cardBody, null).height)
            const cardImgHeight = pxToNumber(getComputedStyle(cardImg, null).height)
            if (cardBodyHeight < cardImgHeight) {
                const card = document.querySelector('.js-card')
                if (card) {
                    card.classList.add('align-items-center')
                }
            }
        }
    }, [isLoading])

    useEffect(() => {
        let navbarUrl = {
            accommodation: '#',
            addOn: '#',
            groupSize: `/events/${eventSlug}/group-size${queryParams}`,
            preview: '#',
            room: `#`
        }
        if (hasAddToCart) {
            let addOnUrl = `/events/${eventSlug}/add-ons${queryParams}`
            let roomUrl = `/events/${eventSlug}/${localCartInfo.previousHotelID}/${cart.groupSize}/${localCartInfo.previousSelectedTicketNumber}${queryParams}`
            if (!shouldShowCountdown) {
                addOnUrl = `#`
                roomUrl = `#`
            }
            navbarUrl = {
                ...navbarUrl,
                accommodation: `/events/${eventSlug}/choose-your-accommodation${queryParams}`,
                addOn: addOnUrl,
                room: roomUrl
            }
        }
        if (hasBeenToPreview) {
            navbarUrl = {
                ...navbarUrl,
                preview: `/events/${eventSlug}/checkout/preview${queryParams}`
            }
        }
        setNavbarUrl(navbarUrl)
    }, [
        eventSlug,
        hasAddToCart,
        localCartInfo.previousHotelID,
        localCartInfo.previousSelectedTicketNumber,
        shouldShowCountdown,
        queryParams
    ])

    useEffect(() => {
        let hotelOnlyData = {}
        switch (true) {
            case isOnlySellHotel:
                hotelOnlyData = {
                    checkInDate: event.checkInDate,
                    checkOutDate: event.checkOutDate,
                    firstCheckInDate: event.checkInDate,
                    firstCheckOutDate: event.checkOutDate
                }
                break
            default:
                hotelOnlyData = {
                    checkInDate: localCartInfo.firstCheckInDate,
                    checkOutDate: localCartInfo.firstCheckOutDate
                }
                break
        }
        if (localCartInfo?.hasClickNavbar && hasAddToCart) {
            const previousTicketItem =
                localCartInfo.previousTicketItems !== undefined
                    ? localCartInfo.previousTicketItems
                    : []
            const selectedTicketNumber =
                localCartInfo.previousSelectedTicketNumber !== undefined
                    ? localCartInfo.previousSelectedTicketNumber
                    : ''
            setLocalStorage('addToCartInfo', {
                ...localCartInfo,
                ...hotelOnlyData,
                eventID: cart?.eventID,
                groupSize: cart.groupSize,
                ticketItems: previousTicketItem,
                hotelID: localCartInfo.previousHotelID,
                selectedTicketNumber
            })
        }
    }, [localCartInfo?.hasClickNavbar, hasAddToCart])

    useEffect(() => {
        let anchorElement = document.getElementsByClassName('event-detail-wrapper')
        if (anchorElement && showGuestsModal) {
            anchorElement[0].classList.remove('modal-open')
        }
    }, [showGuestsModal])

    const maxGroupSizeQty = 8

    let ticketNumInfos = {
        AllTickets: 0,
        WillBeSold: 0,
        SoldOut: 0,
        OnSale: 0,
        LatestDateToBeSold: new Date()
    }
    eventTickets.forEach((ticket) => {
        const earliestOnSaleDate = getEarliestOnSaleDate(ticket)
        ticketNumInfos['AllTickets'] += 1
        if (earliestOnSaleDate && earliestOnSaleDate.getTime() > new Date().getTime()) {
            if (ticketNumInfos['WillBeSold'] === 0) {
                ticketNumInfos['LatestDateToBeSold'] = earliestOnSaleDate
            } else if (ticketNumInfos['LatestDateToBeSold'] > earliestOnSaleDate) {
                ticketNumInfos['LatestDateToBeSold'] = earliestOnSaleDate
            }
            ticketNumInfos['WillBeSold'] += 1
        } else if (ticket.hidden || isSoldOut(ticket, inventory)) {
            ticketNumInfos['SoldOut'] += 1
        } else {
            ticketNumInfos['OnSale'] += 1
        }
    })
    let hotelNumInfos = {
        AllHotel: 0,
        WillBeSold: 0,
        SoldOut: 0,
        OnSale: 0,
        LatestDateToBeSold: new Date()
    }
    event.hotels.forEach((h, index) => {
        hotelNumInfos['AllHotel'] += 1
        let roomNumInfos = {
            AllRooms: 0,
            WillBeSold: 0,
            SoldOut: 0,
            OnSale: 0,
            LatestDateToBeSold: new Date()
        }
        h.rooms.forEach((r, i) => {
            const earliestOnSaleDate = getEarliestOnSaleDate(r)
            roomNumInfos['AllRooms'] += 1
            if (earliestOnSaleDate && earliestOnSaleDate.getTime() > new Date().getTime()) {
                roomNumInfos['WillBeSold'] += 1
                if (i === 0) {
                    roomNumInfos['LatestDateToBeSold'] = earliestOnSaleDate
                } else if (roomNumInfos['LatestDateToBeSold'] > earliestOnSaleDate) {
                    roomNumInfos['LatestDateToBeSold'] = earliestOnSaleDate
                }
            } else if (roomIsSoldOut(r, inventory)) {
                roomNumInfos['SoldOut'] += 1
            } else {
                roomNumInfos['OnSale'] += 1
            }
        })

        if (roomNumInfos['WillBeSold'] === roomNumInfos['AllRooms']) {
            hotelNumInfos['WillBeSold'] += 1
            if (index === 0) {
                hotelNumInfos['LatestDateToBeSold'] = roomNumInfos['LatestDateToBeSold']
            } else if (hotelNumInfos['LatestDateToBeSold'] > roomNumInfos['LatestDateToBeSold']) {
                hotelNumInfos['LatestDateToBeSold'] = roomNumInfos['LatestDateToBeSold']
            }
        } else if (roomNumInfos['SoldOut'] === roomNumInfos['AllRooms']) {
            hotelNumInfos['SoldOut'] += 1
        } else {
            hotelNumInfos['OnSale'] += 1
        }
    })

    let ticketsInCart: Record<string, number> = {}
    cart?.items
        ?.filter((item) => item.type === 'ticket')
        .forEach((item) => {
            ticketsInCart[item.itemID] = item.quantity
        })

    const getLowestPriceOfHotel = useCallback(
        (selectedGroupSize: number, shoulderNightsArray: Record<string, any[]>) => {
            let temHotelPriceTest: Record<string, HotelPriceType[]> = {}
            let priceData: Record<string, number> = {}
            let priceDataExcludingInventory: Record<string, number> = {}
            if (!isOnlySellHotel) {
                event.tickets.forEach((ticket: TicketJSON) => {
                    temHotelPriceTest[ticket.id] = []
                    event.hotels.forEach((hotel: HotelJSON) => {
                        let availableRoom = hotel.rooms.filter((room) => {
                            return (
                                room.tickets.length === 0 ||
                                room.tickets.find((t) => {
                                    return t.id === ticket.id
                                }, [])
                            )
                        })
                        if (isShoulderNights) {
                            availableRoom = availableRoom.filter((r) => {
                                return r.shoulderNights.length !== 0
                            })
                        }

                        const totalTiersPriceDataExcludingInventory = totalTiersData(true).find(
                            (tiersData) => tiersData.hotelID === hotel.id
                        )
                        if (totalTiersPriceDataExcludingInventory) {
                            const cheapestPriceData = calculateCheapestPrice(
                                newAssignRoom(selectedGroupSize),
                                availableRoom,
                                selectedGroupSize,
                                hotel.id,
                                false,
                                totalTiersPriceDataExcludingInventory.hotelTable,
                                hotel.overBaseOccupancyFee
                            )
                            priceDataExcludingInventory[ticket.id] = cheapestPriceData.price
                        }

                        const totalTiersPriceData = totalTiersData(false).find(
                            (tiersData) => tiersData.hotelID === hotel.id
                        )
                        if (totalTiersPriceData) {
                            const cheapestPriceData = calculateCheapestPrice(
                                newAssignRoom(selectedGroupSize),
                                availableRoom,
                                selectedGroupSize,
                                hotel.id,
                                false,
                                totalTiersPriceData.hotelTable,
                                hotel.overBaseOccupancyFee
                            )
                            priceData[ticket.id] = cheapestPriceData.price
                        }
                        const cheapestPrice = Object.entries(priceDataExcludingInventory).filter(
                            (k) => k[0] == ticket.id
                        )?.[0]?.[1]
                        let error = ''
                        if (availableRoom.length === 0) error = 'Sold Out'
                        temHotelPriceTest[ticket.id].push({
                            id: hotel.id,
                            price: Number(cheapestPrice),
                            associate: hotel.tickets,
                            error: error
                        })
                    })
                })
            } else {
                event.hotels.forEach((hotel: HotelJSON) => {
                    temHotelPriceTest[hotel.id] = []
                    const availableRoom = hotel.rooms
                    let cheapestPrice = 0
                    const totalTiersPriceDataExcludingInventory = totalTiersData(true).find(
                        (tiersData) => tiersData.hotelID === hotel.id
                    )
                    if (totalTiersPriceDataExcludingInventory) {
                        const cheapestPriceData = calculateCheapestPrice(
                            newAssignRoom(selectedGroupSize),
                            availableRoom,
                            selectedGroupSize,
                            hotel.id,
                            true,
                            totalTiersPriceDataExcludingInventory.hotelTable,
                            hotel.overBaseOccupancyFee
                        )
                        cheapestPrice = cheapestPriceData.price
                    }
                    if (availableRoom?.every((r) => r.minimumOccupancy > selectedGroupSize))
                        cheapestPrice = 1000000000
                    if (availableRoom.length === 0) cheapestPrice = 1000000000
                    temHotelPriceTest[hotel.id].push({
                        id: hotel.id,
                        price: Number(cheapestPrice),
                        associate: hotel.tickets
                    })
                })
            }
            return temHotelPriceTest
        },
        [event.hotels, event.tickets, calculateCheapestPrice, isShoulderNights]
    )

    const getTicketStatus = (
        ticket: TicketJSON,
        inventory: Record<string, number>,
        groupSize: number
    ) => {
        let ticketStatus: string = ''
        const earliestOnSaleDate = getEarliestOnSaleDate(ticket)
        let ticketAndExperience = 0
        if (!isOnlySellHotel) {
            const ticketTier = ticket.tiers
                .filter((tier) => getTierTimeSaleAble(codeIsRight, tier))
                .sort((a, b) => a.price - b.price)
            let ticketTierPrice = 0
            const experience = event.ticketExperiences.filter((t) => t.ticketID === ticket.id)
            let experiencePrice = 0
            if (ticketTier.length > 0) {
                ticketTierPrice = (ticketTier[0].price + ticketTier[0].taxesAndFees) * groupSize
                experiencePrice = experience?.reduce((carry, experience) => {
                    const experienceTier = experience.tiers.find(
                        (tier) => tier.ticketTierID === ticketTier[0].id
                    )
                    let price = 0
                    if (experienceTier) {
                        price = (experienceTier.price + experienceTier.taxesAndFees) * groupSize
                    }
                    return carry + price
                }, 0)
            }
            ticketAndExperience = ticketTierPrice + experiencePrice
        }
        if (
            futureTicketsRef.current.has(ticket.id) ||
            (earliestOnSaleDate && earliestOnSaleDate.getTime() > new Date().getTime())
        ) {
            futureTicketsRef.current.add(ticket.id)
            ticketStatus =
                'On Sale ' +
                getTimeZone(earliestOnSaleDate, timezone).format('MMM D, YYYY @ hh:mm A') +
                ' ' +
                timeZoneString(timezone)
            if (!(codeIsRight || (hasAddToCart && inPreSaleInterval))) {
                ticketAndExperience = 0
            }
        } else if (isSoldOut(ticket, inventory)) {
            ticketStatus = 'Sold Out'
        }
        return { ticketStatus: ticketStatus, ticketAndExperience: ticketAndExperience }
    }

    const getTicketHotelPrice = (
        ticket: TicketJSON,
        hotelPriceArray: HotelPriceType[] | undefined
    ) => {
        let cheapestHotelPrice = 99999999
        if (!hotelPriceArray) {
            return cheapestHotelPrice
        }
        hotelPriceArray.forEach((value) => {
            const hotelPriceAssociateTicketsArray = value.associate.map((ticket) => ticket.id)
            if (
                !value.error &&
                (value.associate.length === 0 ||
                    hotelPriceAssociateTicketsArray.includes(ticket.id))
            ) {
                cheapestHotelPrice = Math.min(cheapestHotelPrice, value.price)
            }
        })
        return cheapestHotelPrice
    }

    const getOnlyHotelStatus = (
        hotel: HotelJSON,
        hotelLowerPrice: HotelPriceType[] | undefined
    ) => {
        if (hotelLowerPrice === undefined) return 'Unavailable'
        let earliestOnSaleDateList: Date[] = []
        let status = ''
        const rooms = hotel.rooms
        rooms.forEach((r) => {
            const saleDate = getEarliestOnSaleDate(r)
            if (saleDate !== undefined) earliestOnSaleDateList.push(saleDate)
        })
        if (earliestOnSaleDateList.length > 1) {
            earliestOnSaleDateList.sort((a, b) => a.getTime() - b.getTime())
        }

        const availableRoom = hotel.rooms.filter((room) => {
            const roomIsOnSale = room.tiers.length !== 0 && room.tiers.some((tier) => tier.onSale)
            if (roomIsOnSale && !roomIsSoldOut(room, inventory)) {
                return room
            }
        })
        switch (true) {
            case earliestOnSaleDateList.length > 0 &&
                earliestOnSaleDateList[0].getTime() > new Date().getTime() &&
                availableRoom.length !== 0 &&
                allRoomIsFuture(availableRoom):
                status =
                    'On Sale ' +
                    getTimeZone(earliestOnSaleDateList[0], timezone).format('MM/DD/YY @ hh:mm A') +
                    ' ' +
                    timeZoneString(timezone)
                break
            case hotelLowerPrice[0].price === 99999999:
                status = 'Sold Out'
                break
            case hotelLowerPrice[0].price === 1000000000:
                status = 'Unavailable'
                break
            default:
                const perPackagePrice = Number(hotelLowerPrice[0].price) + shippingFee
                status = `<div class="mb-1 fw-500 paragrah text-dark"> <span class="sub-headline-0 fw-semibold"> From ${handleAmountsPoint(
                    formatMoney(perPackagePrice / peopleNumber / 100, event.currency)
                )}</span> / Person </div>
                <p class="mb-1 paragrah text-normal" /> ${handleAmountsPoint(
                    formatMoney(perPackagePrice / 100, event.currency)
                )} Total (Taxes and fees included)
          <p class="mb-4 mb-md-20 paragrah text-normal">*Price shown based on 2 people</p>`
        }
        return status
    }

    const zonesSlider: any = getSettings('#zone-slickbox #zone-slick')
    /* event handler functions */

    useEffect(() => {
        if (Object.entries(inventory).length > 0) {
            setHotelPriceArray(getLowestPriceOfHotel(peopleNumber, {}))
        }
    }, [inventory])

    const updateGroupSizeOptions = (hotelId: string) => {
        const hotel = event.hotels.find((h) => h.id === hotelId)!
        let newGroupSizeOptions = [...groupSizeOptions]
        let unlimitedInventoryRoomTiers: Record<string, boolean> = {}
        hotel.rooms.forEach((room) => {
            room.tiers.forEach((tiers) => {
                unlimitedInventoryRoomTiers[tiers.id] = tiers.prices.every(
                    (price) => price.quantityAvailable === -1
                )
            })
        })
        let excludingInventory = false
        const totalTiersPriceData = totalTiersData(excludingInventory).find(
            (hotelTotalTable) => hotelTotalTable.hotelID === hotel.id
        )
        newGroupSizeOptions = newGroupSizeOptions.map((groupSizeOption) => {
            const groupSize = groupSizeOption.value
            const price = getCheapestPrice(
                hotel.rooms,
                groupSize,
                hotel.id,
                false,
                hotel.overBaseOccupancyFee,
                totalTiersPriceData,
                unlimitedInventoryRoomTiers
            )
            if (price === 99999999 || price === 1000000000) {
                return {
                    ...groupSizeOption,
                    isDisabled: true
                }
            }
            return groupSizeOption
        })
        const oneGuestIsDisabled = newGroupSizeOptions[0].isDisabled
        const twoGuestIsDisabled = newGroupSizeOptions[1].isDisabled
        if (!oneGuestIsDisabled && twoGuestIsDisabled) {
            setSelectedGroupSize(newGroupSizeOptions[0].value)
            setSelectedGuestsOption(newGroupSizeOptions[0])
        }
        setGroupSizeOptions(newGroupSizeOptions)
    }

    const onHotelCardClick = (hotelId: string, onButton: boolean) => {
        setSelectedHotelId(hotelId)
        updateGroupSizeOptions(hotelId)
        openGuestsModal()
    }

    const onHandleImageClick = (hotelImageUrl: any[]) => {
        setHotelImageUrl(hotelImageUrl)
        setAllImageCardGallery(true)
    }

    const handleSelectedGuestsOptionChange = (newValue: any) => {
        setSelectedOptionIsPlaceholder(false)
        setSelectedGroupSize(newValue.value)
        setSelectedGuestsOption(newValue)
    }

    const openGuestsModal = () => {
        setShowGuestsModal(true)
    }

    const hideGuestsModal = () => {
        setShowGuestsModal(false)
    }

    const resetLocalStorgeCheckInAndOutDate = (
        cartInfo: AddToCartInfo,
        hasAddToCart: boolean,
        selectedHotelId: string
    ) => {
        let localStorageDate = {}
        let previousHotelId: string | undefined = ''
        let previousCheckInDate
        let previousCheckOutDate
        const hasAddToCartButInOtherEvent = cart.items.length !== 0 && event.id !== cart.eventID
        switch (true) {
            case hasAddToCart:
                previousHotelId = cartInfo.previousHotelID
                previousCheckInDate = cartInfo.firstCheckInDate
                previousCheckOutDate = cartInfo.firstCheckOutDate
                break
            case hasAddToCartButInOtherEvent:
                previousCheckInDate = event.checkInDate
                previousCheckOutDate = event.checkOutDate
                if (event.id === localCartInfo.comparedEventsID) {
                    previousCheckInDate = cartInfo.firstSelectedCheckInDate[0]
                    previousCheckOutDate = cartInfo.firstSelectedCheckInDate[1]
                }
                break
            case cartInfo.firstSelectedCheckInDate !== undefined:
                previousHotelId = cartInfo.hasSelectedHotelID
                previousCheckInDate = cartInfo.firstSelectedCheckInDate[0]
                previousCheckOutDate = cartInfo.firstSelectedCheckInDate[1]
                break
            default:
                previousCheckInDate = event.checkInDate
                previousCheckOutDate = event.checkOutDate
                break
        }

        switch (true) {
            case selectedHotelId !== previousHotelId:
                localStorageDate = {
                    checkInDate: event.checkInDate,
                    checkOutDate: event.checkOutDate
                }
                break
            case cart.items.length === 0 && event.id !== localCartInfo.comparedEventsID:
                localStorageDate = {
                    checkInDate: event.checkInDate,
                    checkOutDate: event.checkOutDate,
                    firstSelectedCheckInDate: [event.checkInDate, event.checkOutDate]
                }
                break
            default:
                localStorageDate = {
                    checkInDate: previousCheckInDate,
                    checkOutDate: previousCheckOutDate
                }
                break
        }

        return localStorageDate
    }

    const handleConfirmSelectedGuestsOption = () => {
        if (guestsSubmitting) return
        setGuestsSubmitting(true)
        const numberOfGuests = selectedGuestsOption.value
        const localStorageInfo = {
            code: code,
            codeIsRight: codeIsRight,
            eventID: event.id,
            eventSlug,
            groupSize: selectedGroupSize,
            hasClickNavbar: false,
            hotelID: selectedHotelId,
            isPreSale: isPreSale,
            selectedTicketNumber: ''
        }
        if (hasShoulderNights) {
            setShowGuestsModal(false)
            const hotelShoulderNights = getShoulderNightsByHotelId(
                event,
                hotel,
                inventory,
                '',
                numberOfGuests
            )

            const addComparedEventsIDData = {
                comparedEventsID: '',
                hasSelectedHotelID: ''
            }
            const checkInAndOutData = resetLocalStorgeCheckInAndOutDate(
                localCartInfo,
                hasAddToCart,
                hotel!.id
            )

            updateEventProp({
                ...localStorageInfo,
                ...checkInAndOutData,
                ...addComparedEventsIDData
            })
            if (hotelShoulderNights) {
                setMinSelectDate(hotelShoulderNights[0]!)
                setMaxSelectDate(hotelShoulderNights[1]!)
            }
            setIsDateRangePickerModalOpen(true)
            setGuestsSubmitting(false)
        } else {
            const selectedItem: GAProductType = {
                affiliation: APP_NAME,
                coupon: undefined,
                discount: undefined,
                index: 0,
                item_brand: hotel?.name ?? '',
                item_category: `${hotel?.name} item`,
                item_category2: undefined,
                item_category3: undefined,
                item_category4: undefined,
                item_category5: undefined,
                item_id: selectedHotelId,
                item_list_id: selectedHotelId,
                item_list_name: hotel?.name + ' item list',
                item_name: `${hotel?.name}`,
                item_variant: undefined,
                location_id: undefined,
                price: undefined,
                quantity: numberOfGuests
            }
            googleAnalyticsEvent({
                action: GoogleAnalyticsEventActionType.SELECT_ITEM,
                itemListId: selectedHotelId,
                itemListName: hotel?.name + ' item list',
                items: [selectedItem]
            })
            updateEventProp({
                ...localStorageInfo,
                checkInDate: event.checkInDate,
                checkOutDate: event.checkOutDate,
                hasSelectedHotelID: ''
            })
            router
                .push(`/events/${eventSlug}/${selectedHotelId}/${numberOfGuests}/${queryParams}`)
                .catch(() => {
                    setGuestsSubmitting(false)
                })
        }
    }

    const handleClickDateRangePickerModalContinue = () => {
        setSubmitting(true)
        const selectedItem: GAProductType = {
            affiliation: APP_NAME,
            coupon: undefined,
            discount: undefined,
            index: 0,
            item_brand: hotel?.name ?? '',
            item_category: `${hotel?.name} item`,
            item_category2: undefined,
            item_category3: undefined,
            item_category4: undefined,
            item_category5: undefined,
            item_id: selectedHotelId,
            item_list_id: selectedHotelId,
            item_list_name: hotel?.name + ' item list',
            item_name: `${hotel?.name}`,
            item_variant: undefined,
            location_id: undefined,
            price: undefined,
            quantity: 1
        }
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.SELECT_ITEM,
            itemListId: selectedHotelId,
            itemListName: hotel?.name + ' item list',
            items: [selectedItem]
        })
        router
            .push(`/events/${eventSlug}/${selectedHotelId}/${selectedGroupSize}/${queryParams}`)
            .catch(() => {
                setSubmitting(false)
                setIsDateRangePickerModalOpen(false)
            })
    }
    const addToLocalCartInfo = (ticketId: string) => {
        let newCheckTimeInfo = {}
        if (
            localCartInfo.selectedTicketNumber === undefined ||
            localCartInfo.selectedTicketNumber !== ticketId
        ) {
            newCheckTimeInfo = {
                checkInDate: event.checkInDate,
                checkOutDate: event.checkOutDate,
                firstCheckInDate: event.checkInDate,
                firstCheckOutDate: event.checkOutDate,
                firstSelectedCheckInDate: [event.checkInDate, event.checkOutDate]
            }
        }
        setLocalStorage('addToCartInfo', {
            ...localCartInfo,
            ...newCheckTimeInfo,
            code: code,
            codeIsRight: codeIsRight,
            eventID: event.id,
            eventSlug,
            groupSize: hasAddToCart ? localCartInfo.groupSize : null,
            hasBeenToHotelPageEventID: hasAddToCart ? localCartInfo.hasBeenToHotelPageEventID : '',
            hasClickNavbar: false,
            isPreSale: isPreSale,
            selectedTicketNumber: ticketId
        })
        const selectedTicket = eventTickets.find((ticket) => ticket.id === ticketId)
        const selectedItem: GAProductType = {
            affiliation: APP_NAME,
            coupon: undefined,
            discount: undefined,
            index: 0,
            item_brand: event?.name ?? '',
            item_category: `${event?.name} item`,
            item_category2: undefined,
            item_category3: undefined,
            item_category4: undefined,
            item_category5: undefined,
            item_id: selectedTicket?.tiers?.[0]?.id ?? '',
            item_list_id: event.id,
            item_list_name: event?.name + ' item list',
            item_name: `${selectedTicket?.name}`,
            item_variant: undefined,
            location_id: undefined,
            price: selectedTicket?.tiers?.[0]?.price ?? 0,
            quantity: selectedTicket?.tiers?.[0]?.quantityAvailable ?? 0
        }
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.SELECT_ITEM,
            itemListId: event.id,
            itemListName: event?.name + ' item list',
            items: [selectedItem]
        })
        router.push(`/events/${eventSlug}/group-size${queryParams}`).catch(() => {
            setSubmitting(false)
        })
    }
    const handlePackageSelection = (ticketId: string) => {
        if (submitting) return
        setSubmitting(true)
        setSubmitTicketId(ticketId)
        if (hasAddToCart) {
            const selectedTicketNumber =
                cart.items.find((item) => item.type === 'ticket')?.itemID || ''
            if (ticketId === selectedTicketNumber) {
                addToLocalCartInfo(ticketId)
                return
            }
        } else if (localCartInfo.selectedTicketNumber === ticketId) {
            addToLocalCartInfo(ticketId)
            return
        }
        if (!eventNameOverrideTicketNumber.includes(ticketId)) {
            const eventNameOverride = getEventNameOverride(event, ticketId)
            if (eventNameOverride !== '') {
                setEventNameOverrideTicketNumber([...eventNameOverrideTicketNumber, ticketId])
                setShowEventNotIncludeTicketModal(true)
                return
            }
        }
        addToLocalCartInfo(ticketId)
    }

    const renderSelectTickets = () => {
        if (event.tickets.length <= 0) return null
        return (
            <>
                {!isLoading && (
                    <SelectTicketNotification
                        codeIsRight={codeIsRight || hasAddToCart}
                        email={email}
                        event={event}
                        hiveState={hiveState}
                        hotelNumInfos={hotelNumInfos}
                        hotelOnly={isOnlySellHotel}
                        isF1={isF1}
                        isOnlySellTickets={isOnlySellTickets}
                        isPreSale={isPreSale}
                        onEmailChange={setEmail}
                        onNotifyClick={() => {
                            hiveSubscribe(email, event.name)
                        }}
                        ticketNumInfos={ticketNumInfos}
                    />
                )}
                <div className={classes.row}>{renderTickets()}</div>
            </>
        )
    }

    const renderTickets = () => {
        return eventTickets.map((ticket, index) => {
            if (ticket.hidden) return null
            const { ticketStatus, ticketAndExperience } = getTicketStatus(
                ticket,
                inventory,
                peopleNumber
            )
            let needShowHotelPrice = true
            if (
                Object.entries(hotelPriceArray).length === 0 &&
                Object.entries(inventory).length === 0
            ) {
                needShowHotelPrice = false
            }
            const hotelPrice = getTicketHotelPrice(ticket, hotelPriceArray[ticket.id])
            const ticketsAssociateHotel = event.hotels.filter((hotel) => {
                switch (true) {
                    case hotel.tickets.length <= 0:
                        return hotel
                    case hotel.tickets.find((hotelTickets) => hotelTickets.id === ticket.id) !==
                        undefined:
                        return hotel
                }
            })
            let allAvailableRoomSoldOut = true
            const availableRoom = ticketsAssociateHotel
                .flatMap((hotel) => hotel.rooms)
                .filter((room) => {
                    const roomIsOnSale = room.tiers.some((tier) => tier.onSale)
                    if (!roomIsOnSale) return
                    const haveAvailableRoom =
                        room.tickets.length === 0 ||
                        room.tickets.some((_ticket) => _ticket.id === ticket.id)
                    if (!haveAvailableRoom) return
                    if (!roomIsSoldOut(room, inventory)) {
                        allAvailableRoomSoldOut = false
                    }
                    return true
                })
            allAvailableRoomSoldOut = allAvailableRoomSoldOut && !isOnlySellTickets
            const isOnsaleTicket = ticket.tiers.some((tier) => tier.onSale)
            const isEmptyAvailableRoom = availableRoom.length === 0 && !isOnlySellTickets
            const ticketTip = ticketTips.find((tip) => tip.ticketID === ticket.id)
            const totalPrice =
                ticketAndExperience + shippingFee + (isOnlySellTickets ? 0 : hotelPrice)
            return (
                <TicketTypeSelection
                    allAvailableRoomSoldOut={allAvailableRoomSoldOut}
                    codeIsRight={codeIsRight}
                    currency={currency}
                    hotelNumInfos={hotelNumInfos}
                    inPreSaleInterval={inPreSaleInterval}
                    isEmptyAvailableRoom={isEmptyAvailableRoom}
                    isLoading={isLoading}
                    isOnlySellTickets={isOnlySellTickets}
                    isOnsaleTicket={isOnsaleTicket}
                    isPreSale={isPreSale}
                    key={'ticket_' + ticket.id}
                    needShowHotelPrice={needShowHotelPrice}
                    onPackageSelectionClick={handlePackageSelection}
                    onUnlockButtonClick={renderPreSalePopUp}
                    peopleNumber={peopleNumber}
                    submitting={submitting && submitTicketId === ticket.id}
                    totalPrice={totalPrice}
                    ticket={ticket}
                    ticketIndex={index}
                    ticketNameResize={handleTicketNameResize}
                    ticketNumInfos={ticketNumInfos}
                    ticketPrice={ticketAndExperience}
                    ticketStatus={ticketStatus}
                    ticketTip={ticketTip}
                    timezone={timezone}
                />
            )
        })
    }

    const renderHotelOnlySoldOutNotification = () => {
        if (event.tickets.length <= 0 && hotelNumInfos['OnSale'] <= 0) {
            return (
                <SelectTicketNotification
                    codeIsRight={codeIsRight || hasAddToCart}
                    email={email}
                    event={event}
                    hiveState={hiveState}
                    hotelNumInfos={hotelNumInfos}
                    hotelOnly={isOnlySellHotel}
                    isF1={isF1}
                    isOnlySellTickets={isOnlySellTickets}
                    isPreSale={isPreSale}
                    onEmailChange={setEmail}
                    onNotifyClick={() => {
                        hiveSubscribe(email, event.name)
                    }}
                    ticketNumInfos={ticketNumInfos}
                />
            )
        }
    }

    const renderHotelCards = () => {
        if (!isOnlySellHotel) return null
    
        return (
            <div className={classes.row}>
                {isLoading ? (
                    <Skeleton
                        animation="wave"
                        sx={{
                            height: SIZE_30,
                            marginBottom: SIZE_22,
                            width: FULL_WIDTH
                        }}
                    />
                ) : (
                    <div
                        className="col-sm-12 gx-md-0 gx-md-3"
                        id="selectHotel"
                    >
                        <h2
                            className={`headline-2 mobile-padding-left ${isF1 ? 'f1-font' : ''}`}
                            style={{ marginBottom: '22px' }}
                        >
                            Choose Your Accommodation
                        </h2>
                    </div>
                )}
                {Object.keys(inventory).length > 0 && event.hotels.map((hotel: HotelJSON, index) => {
                    const selectButtonClass = 'btn-block btn-purple w-100 '
                    const isSoldOut =
                        hotel.rooms.filter((room) => roomIsSoldOut(room, inventory)).length ===
                        hotel.rooms.length
                    let selectButtonText = 'Select Hotel'
                    if (isSoldOut) {
                        selectButtonText = 'Sold Out'
                    }
                    const onlyHotelStatus = getOnlyHotelStatus(
                        { ...hotel },
                        hotelPriceArray[hotel.id]
                    )
                    const hotelShoulderNights = getShoulderNightsByHotelId(
                        event,
                        hotel,
                        inventory,
                        '',
                        selectedGroupSize
                    )
                    const needShowPrice = onlyHotelStatus !== 'Sold Out'
                    return (
                        <HotelCard
                            codeIsCorrect={codeIsRight}
                            dateDisplay={dateRange(event.checkInDate, event.checkOutDate, timezone)}
                            hotel={hotel}
                            hotelIndex={index}
                            hasShoulderNights={hotelShoulderNights !== undefined}
                            isF1={isF1}
                            isLoading={isLoading}
                            isOnlySellHotel={isOnlySellHotel}
                            isPreSale={isPreSale}
                            isSoldOut={isSoldOut}
                            key={hotel.id}
                            saleStatusOnHotelCard={onlyHotelStatus}
                            needShowPrice={needShowPrice}
                            onHandleImageClick={onHandleImageClick}
                            onHotelCardClick={onHotelCardClick}
                            onUnlockButtonClick={renderPreSalePopUp}
                            selectButtonClass={selectButtonClass}
                            selectButtonText={selectButtonText}
                            submitting={submitting && selectedHotelId === hotel.id}
                            hotelShoulderNights={undefined}
                        />
                    )
                })}
            </div>
        )
    }

    const renderEventMap = () => {
        if (!isOnlySellTickets) {
            return (
                <EventMap
                    event={event}
                    isF1={isF1}
                />
            )
        }
    }

    const renderEventTimeLine = () => {
        if (itinerary.length <= 0 || isOnlySellTickets) return null
        return (
            <EventTimeLine
                itinerary={itinerary}
                timezone={timezone}
            />
        )
    }

    const renderPreSalePopUp = () => {
        setShowPreSalePopUp(true)
    }

    const hidePreSalePopUp = () => {
        setShowPreSalePopUp(false)
        setCode('')
        setCodeError('')
    }

    const handleValidCode = async () => {
        setCodeError('')
        setSubmitting(true)
        if (code != '') {
            checkCode(
                publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
                event.id,
                code.toLowerCase()
            ).then((data) => {
                if (data == undefined) {
                    setCodeError('')
                    setSubmitting(false)
                    setShowPreSalePopUp(false)
                    setIsPreSale(false)
                    setCodeIsRight(true)
                } else {
                    switch (data.message) {
                        case 'Not Found':
                            setCodeError('Please enter a correct code')
                            setSubmitting(false)
                            break
                        case 'invalid code':
                            setCodeError('Incorrect presale code. Please try again.')
                            setSubmitting(false)
                            break
                    }
                }
            })
        } else {
            setCodeError('Please enter a correct code')
            setSubmitting(false)
        }
    }

    const footerAddPBCSS = isMobile ? (isTablet ? 'pc-mb2' : 'mobile-mb2') : 'pc-mb2'

    useEffect(() => {
        const event_tickets_available = event.tickets?.filter(
            (t) =>
                t.quantityAvailable > 0 && t.tiers.some((t) => t.quantityAvailable > 0 && t.onSale)
        )
        const event_accommodations_available = event.hotels?.filter((h) =>
            h.rooms.some((r) => r.quantityAvailable > 0 && r.tiers.some((t) => t.onSale))
        )
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.PAGE_VIEW,
            category: event.name,
            label: window.location.toString(),
            pageTitle: event.name + ' - Event Page Detail'
        })
        const gaItems = isOnlySellHotel ? event_accommodations_available : event_tickets_available
        const eventItem = {
            affiliation: APP_NAME,
            coupon: undefined,
            discount: undefined,
            index: 0,
            item_brand: event.name,
            item_category: `${event.name} item`,
            item_category2: undefined,
            item_category3: undefined,
            item_category4: undefined,
            item_category5: undefined,
            item_id: event.id,
            item_list_id: event.id,
            item_list_name: event.name + ' item list',
            item_name: `${event.name}`,
            item_variant: undefined,
            location_id: undefined,
            price: undefined,
            quantity: 1
        }
        const items: GAProductType[] = gaItems.map(
            (item: TicketJSON | HotelJSON, index: number) => {
                return {
                    affiliation: APP_NAME,
                    coupon: undefined,
                    discount: undefined,
                    index: index,
                    item_brand: item.name,
                    item_category: `${event.name} item`,
                    item_category2: undefined,
                    item_category3: undefined,
                    item_category4: undefined,
                    item_category5: undefined,
                    item_id: item.id,
                    item_list_id: event.id,
                    item_list_name: event.name + ' item list',
                    item_name: `${event.name}`,
                    item_variant: undefined,
                    location_id: undefined,
                    price: isOnlySellHotel
                        ? undefined
                        : +((item as TicketJSON).tiers?.[0]?.price / 100 + 100.0).toFixed(2),
                    quantity: isOnlySellHotel
                        ? (item as HotelJSON).rooms.length
                        : (item as TicketJSON).tiers?.[0]?.quantityAvailable
                }
            }
        )
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.VIEW_ITEM,
            currency: 'USD',
            items: [eventItem],
            value: undefined
        })
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.VIEW_ITEM_LIST,
            itemListId: event.id,
            itemListName: event.name + ' item list',
            items: items
        })
    }, [])

    const handleNavBarClick = () => {
        if (!hasAddToCart) return
        setLocalStorage('addToCartInfo', {
            ...localCartInfo,
            eventSlug: localCartInfo.previewEventSlug,
            eventID: cart.eventID,
            comparedEventsID: cart.eventID,
            groupSize: cart.groupSize,
            checkInDate: localCartInfo.firstCheckInDate,
            checkOutDate: localCartInfo.firstCheckOutDate,
            selectedTicketNumber: localCartInfo.previousSelectedTicketNumber,
            ticketItems: localCartInfo.previousTicketItems,
            ticketExperienceItems: localCartInfo.ticketExperienceItems,
            code: localCartInfo.presaleCode !== undefined ? localCartInfo.presaleCode : '',
            codeIsRight: codeIsRight,
            hasClickNavbar: true,
            hasBeenToHotelPageEventID: event.id
        })
    }

    return (
        <div
            itemScope
            itemType="https://schema.org/Event"
        >
            <div style={{ display: 'none' }}>
                <div itemProp="url">{`http://vibee.com/events/${eventSlug}/${queryParams}`}</div>
                <div itemProp="name">{event.meta.title || event.name}</div>
                <div
                    itemProp="description"
                    dangerouslySetInnerHTML={{ __html: description }}
                ></div>
                <div
                    className="paragraph-multi fw-500"
                    itemProp="location"
                    itemScope
                    itemType="https://schema.org/Place"
                >
                    <p itemProp="name">
                        {event?.locationDisplay !== '' ? locationDisplay : location}
                    </p>
                </div>
                <Image
                    src={getImage(headerImage, 'desktop').url}
                    alt=""
                    itemProp="image"
                    layout="fill"
                />
                <Image
                    src={getImage(headerImage, 'square').url}
                    alt=""
                    itemProp="image"
                    layout="fill"
                />
                <time
                    itemProp="startDate"
                    dateTime={`${getTimeZone(startDate, timezone).format('YYYY-MM-DD')}`}
                />
                <time
                    itemProp="EndDate"
                    dateTime={`${getTimeZone(endDate, timezone).format('YYYY-MM-DD')}`}
                />
                {event.tickets.map((ticket) => {
                    if (!ticket.tiers?.[0]?.price) return null

                    let availabilityTest = ''
                    let timeOfValidFrom = ' " " '

                    switch (true) {
                        case !ticket.tiers.some((tier) => tier.onSale):
                            availabilityTest = 'SoldOut'
                            break
                        case ticket.tiers[0].onSaleDate !== null:
                            availabilityTest = 'PreOrder'
                            break
                        default:
                            availabilityTest = 'InStock'
                    }

                    if (ticket.tiers[0].onSaleDate) {
                        timeOfValidFrom = getTimeZone(ticket.tiers[0].onSaleDate, timezone).format(
                            'YYYY-MM-DDTHH:mm'
                        )
                    }

                    return (
                        <div
                            itemProp="offers"
                            itemScope
                            itemType="https://schema.org/Offer"
                            key={ticket.id}
                        >
                            <p itemProp="name">{ticket.name}</p>
                            <div itemProp="description">{ticket.description}</div>
                            <div
                                itemProp="priceSpecification"
                                itemScope
                                itemType="https://schema.org/PriceSpecification"
                            >
                                <span itemProp="minPrice">{ticket.tiers[0].price / 100}</span>
                                <span itemProp="priceCurrency">{currency}</span>
                            </div>
                            <div itemProp="availability">{availabilityTest}</div>
                            <div itemProp="validFrom">{timeOfValidFrom}</div>
                        </div>
                    )
                })}
            </div>
            <Head>
                <title>{event.meta.title || event.name}</title>
            </Head>
            <BodyClass
                bodyClass={`event-detail-wrapper ${footerAddPBCSS} ${isF1 ? 'f1-event-page' : ''}`}
            />
            {showEventNotIncludeTicketBanner && <EventNotIncludeTicketBanner />}
            <BreadCrumbsNavbar
                accommodationUrl={navbarUrl.accommodation}
                addOnsUrl={navbarUrl.addOn}
                groupSizeUrl={navbarUrl.groupSize}
                hasAddonsPage={hasAddonsPage}
                isOnlySellHotel={isOnlySellHotel}
                isOnlySellTickets={isOnlySellTickets}
                packagesUrl={''}
                previewUrl={navbarUrl.preview}
                roomUrl={navbarUrl.room}
                routerPathName={router.pathname}
                showNavbar={hasAddToCart}
                onClickBreadCrumbsNavbar={handleNavBarClick}
            />
            <EventTop
                hasAddToCart={hasAddToCart}
                headerImage={headerImage}
                headerVideo={headerVideo}
                isLoading={isLoading}
                isMobileWidth={isMobileWidth}
            />
            <EventIntroduction
                ageRequirement={ageRequirement}
                dateDisplay={dateDisplay}
                description={description}
                endDate={endDate}
                isLoading={isLoading}
                isOnlySellHotel={isOnlySellHotel}
                location={location}
                locationDisplay={locationDisplay}
                name={event.name}
                startDate={startDate}
                timezone={timezone}
            />
            <div>
                <div className="container hotel-list-container package-list-container gx-3">
                    <div
                        style={{
                            width: FULL_WIDTH
                        }}
                    >
                        {renderSelectTickets()}
                    </div>
                    {renderHotelOnlySoldOutNotification()}
                    {renderHotelCards()}
                    <div
                        data-testid="media-viewer-popup"
                        style={{ height: '100%' }}
                    >
                        {allImageCardGallery && (
                            <HotelCardImages
                                headerImage={hotelImageUrl}
                                showSingleImage={showSingleImage}
                                handleShowSingleImage={() => setShowSingleImage(true)}
                                hideSingleImage={() => setShowSingleImage(false)}
                                hideAllImageCardGallery={() => setAllImageCardGallery(false)}
                            />
                        )}
                    </div>
                </div>
                {renderEventTimeLine()}
                {event.featuresAndBenefits && (
                    <div className="container included-container">
                        <div className="row">
                            <div className="col-md-12 gx-4-2">
                                <h2 className="text-md-center text-start event-section-headline">
                                    What&apos;s Included?
                                </h2>
                                <div className="mx-auto included-content">
                                    <EventFeaturesAndBenefitsSection
                                        featuresAndBenefits={event.featuresAndBenefits}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {event.bottomImage.length !== 0 && <EventBottomHero event={event} />}
                <EventFAQ
                    faqs={faqs}
                    faqsCategories={faqsCategories}
                    hasFaqsCategories={faqsCategories.length > 0}
                />
            </div>

            <PreSaleModal
                code={code}
                codeError={codeError}
                handleValidCode={handleValidCode}
                hidePreSalePopUp={hidePreSalePopUp}
                onChangeCode={(e: any) => setCode(e.currentTarget.value)}
                showPreSalePopUp={showPreSalePopUp}
                submitting={submitting}
            />
            {showGuestsModal && (
                <GuestsModal
                    isF1={isF1}
                    onCancelClick={hideGuestsModal}
                    onContinueClick={handleConfirmSelectedGuestsOption}
                    onSelectedOptionChange={handleSelectedGuestsOptionChange}
                    options={groupSizeOptions}
                    selectedOptionIsPlaceholder={selectedOptionIsPlaceholder}
                    selectedValue={selectedGuestsOption}
                    submitting={guestsSubmitting}
                    visible={showGuestsModal}
                />
            )}

            <EventNotIncludeTicketModal
                addToLocalCartInfo={addToLocalCartInfo}
                isReserving={isReserving}
                setIsReserving={setIsReserving}
                setSubmitting={setSubmitting}
                setShowEventNotIncludeTicketModal={setShowEventNotIncludeTicketModal}
                show={showEventNotIncludeTicketModal}
                submitTicketId={submitTicketId}
            />

            {isDateRangePickerModalOpen && (
                <DateRangePickerModalComponent
                    addToCartDefaultValueRange={addToCartDefaultValueRange}
                    addToCartValue={addToCartValue}
                    cancelButtonTitle="Reset"
                    cartEventId={cart.items.length === 0 ? undefined : cart.eventID}
                    cartFirstCheckDateOfLocationTimeRange={cartFirstCheckDateOfLocationTimeRange}
                    cartFirstCheckDateRange={getCartFirstCheckDateRange()}
                    cartInfoHasSelectedHotelID={
                        localCartInfo.hasSelectedHotelID !== undefined
                            ? localCartInfo.hasSelectedHotelID
                            : ''
                    }
                    cartInfoPreviousHotelID={
                        localCartInfo.previousHotelID !== undefined
                            ? localCartInfo.previousHotelID
                            : ''
                    }
                    checkInDate={event.checkInDate}
                    checkOutDate={event.checkOutDate}
                    confirmButtonTitle="Apply"
                    defaultValueRange={defaultValueRange}
                    eventHotels={event.hotels}
                    eventId={event.id}
                    eventTimezone={timezone}
                    groupSize={selectedGroupSize}
                    hasAddToCart={hasAddToCart}
                    hasSelectedHotelID={selectedHotelId ?? ''}
                    inventory={inventory}
                    isDisabled={submitting}
                    isLoading={submitting}
                    isOnlySellHotel={isOnlySellHotel}
                    maxDate={maxSelectDate ?? undefined}
                    minDate={minSelectDate ?? undefined}
                    onClose={() => setIsDateRangePickerModalOpen(false)}
                    onContinue={handleClickDateRangePickerModalContinue}
                    onDatesChange={(value) => updateDates(value)}
                    onResetDates={() => resetDatesToInitialEventDates()}
                    openModal={isDateRangePickerModalOpen}
                    textContent={textContent}
                    ticketId={''}
                    title="Select the length of your stay"
                    updateEventProp={updateEventProp}
                    value={selectedDates}
                />
            )}
        </div>
    )
}

export default Event

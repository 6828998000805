import { AddToCartInfo } from 'store'
import { EventJSON } from '../../../events-service/src/models/event'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import { HotelRoomTierJSON } from '../../../events-service/src/models/hotelRoomTier'
import { HotelRoomJSON } from '../../../events-service/src/models/hotelRoom'
import { CheapestPriceJSON, hotelTableJSON, roomTableJSON } from 'util/customizeJsonType'
import { getRoomTier } from 'pages/events/[slug]'
import dayjs from 'dayjs'
import { HotelRoomTierPriceJSON } from '../../../events-service/src/models/hotelRoomTierPrice'
import { newAssignRoom } from 'util/parse'
import { getTotalResortFee } from 'util/cart'
import { useMemo } from 'react'

export type calculateReturnType = {
    totalTiersData: (excludingInventory: boolean) => hotelTableJSON[]
    calculateCheapestPrice: (
        allChoicesArray: any[],
        associateTicketAvailableRoom: HotelRoomJSON[],
        groupSize: number,
        hotelId: string,
        isOnlySellHotel: boolean,
        newTotalTables: Record<number, Record<number, [roomTableJSON[]]>>,
        overBaseOccupancyFee: number,
        unlimitedInventoryRoomTiers?: Record<string, boolean>
    ) => CheapestPriceJSON
    getCheapestPrice: (
        availableRoom: HotelRoomJSON[],
        groupSize: number,
        hotelId: string,
        isOnlySellHotel: boolean,
        overBaseOccupancyFee: number,
        totalTiersPriceData: hotelTableJSON | undefined,
        unlimitedInventoryRoomTiers: Record<string, boolean>
    ) => number
}

export const useCalculate = ({
    event,
    inventory,
    cartInfo
}: {
    event: EventJSON
    inventory: Record<string, number>
    cartInfo: AddToCartInfo
}): calculateReturnType => {
    const codeIsRight = cartInfo?.codeIsRight !== undefined ? cartInfo.codeIsRight : false
    const allNights = useMemo(() => {
        return dayjs(event.checkOutDate).diff(dayjs(event.checkInDate), 'days')
    }, [event])
    const hotelsResortInfo = useMemo(() => {
        return (
            event.hotels.map((hotel) => {
                return {
                    id: hotel.id,
                    resortFee: hotel.resortFee,
                    resortFeeType: hotel.resortFeeType
                }
            }) || {}
        )
    }, [event])
    const isRoomPriceInventoryEnough = (roomId: string) => {
        return inventory[roomId] !== 0
    }

    const hasNotAddToTiersTables = (tiersID: string[], roomId: string) => {
        return tiersID.indexOf(roomId) === -1
    }

    const getSortedTablesData = (
        tiersTables: Record<number, roomTableJSON[]>,
        roomSize: number
    ) => {
        tiersTables[roomSize].sort((a, b) => {
            switch (true) {
                case a.size !== b.size:
                    return b.size - a.size
                case a.size === b.size && a.price !== b.price:
                    return a.price - b.price
                default:
                    return b.inventory - a.inventory
            }
        })
        return tiersTables
    }

    const getNewTablesData = (
        newTables: Record<number, [roomTableJSON[]]>,
        roomSize: number,
        tiersTables: Record<string, roomTableJSON[]>
    ) => {
        // @ts-ignore
        // It is needs to initialize array,and it must be passed in as a parameter
        newTables[roomSize] = []

        let temporaryTiersTable: Record<string, roomTableJSON[]> = {}
        tiersTables[roomSize].forEach((item) => {
            const key = item.roomID
            if (temporaryTiersTable[key]) {
                temporaryTiersTable[key].push(item)
            } else {
                temporaryTiersTable[key] = [item]
            }
        })
        for (const id in temporaryTiersTable) {
            const element = temporaryTiersTable[id]
            if (element.length !== 0) {
                newTables[roomSize].push(...[element])
            }
        }
        newTables[roomSize].sort((a, b) => a[0].price - b[0].price)
        return newTables
    }

    const handleTiersTables = (
        tiersTables: Record<number, roomTableJSON[]>,
        roomSize: number,
        rooms: HotelRoomJSON,
        roomTier: HotelRoomTierJSON,
        index: number,
        tableTemporaryInventory: Record<string, number>,
        tierIndex: number
    ) => {
        let pricesData: HotelRoomTierPriceJSON[] = []
        if (rooms.isPricePerRoom) {
            for (
                let sizeOfRoom = rooms.minimumOccupancy;
                sizeOfRoom <= rooms.sleeps;
                sizeOfRoom++
            ) {
                const data = { ...roomTier.prices[0], size: sizeOfRoom }
                pricesData.push(data)
            }
        } else {
            pricesData = roomTier.prices
                .filter(
                    (price) => price.size <= rooms.sleeps && price.size >= rooms.minimumOccupancy
                )
                .sort((a, b) => a.size - b.size)
        }
        tiersTables[roomSize].push(
            ...[
                {
                    roomName: rooms.name,
                    pricingID: pricesData[index].id,
                    size: pricesData[index].size,
                    price: pricesData[index].price + pricesData[index].taxesAndFees,
                    inventory: tableTemporaryInventory[pricesData[index].id],
                    unlimitInventory: pricesData.every((price) => price.quantityAvailable === -1),
                    tiersId: roomTier.id,
                    tierIndex: tierIndex,
                    roomID: rooms.id,
                    sleep: rooms.sleeps,
                    baseOccupancy: rooms.baseOccupancy,
                    shoulderNights: rooms.shoulderNights
                }
            ]
        )
        return tiersTables
    }

    const getExcludingInventoryTablesData = (
        tiersTables: Record<number, roomTableJSON[]>,
        roomSize: number,
        rooms: HotelRoomJSON,
        roomTier: HotelRoomTierJSON,
        tempIndex: number,
        tableTemporaryInventory: Record<string, number>,
        roomsSleepIndex: number,
        pricesData: HotelRoomTierPriceJSON[],
        tiersID: string[],
        tierIndex: number
    ) => {
        switch (pricesData[roomsSleepIndex].price) {
            case 0:
                while (tempIndex >= 0) {
                    switch (true) {
                        case pricesData[tempIndex].price === 0:
                            break
                        case !hasNotAddToTiersTables(tiersID, pricesData[tempIndex].id):
                            tempIndex = 0
                            break
                        default:
                            handleTiersTables(
                                tiersTables,
                                roomSize,
                                rooms,
                                roomTier,
                                tempIndex,
                                tableTemporaryInventory,
                                tierIndex
                            )
                            tempIndex = 0
                            break
                    }
                    tempIndex -= 1
                }
                break
            default:
                handleTiersTables(
                    tiersTables,
                    roomSize,
                    rooms,
                    roomTier,
                    roomsSleepIndex,
                    tableTemporaryInventory,
                    tierIndex
                )
                break
        }
        return tiersTables
    }

    const getIncludingInventoryTablesData = (
        tiersTables: Record<number, roomTableJSON[]>,
        roomSize: number,
        rooms: HotelRoomJSON,
        roomTier: HotelRoomTierJSON,
        tempIndex: number,
        tableTemporaryInventory: Record<string, number>,
        roomsSleepIndex: number,
        pricesData: HotelRoomTierPriceJSON[],
        tiersID: string[],
        groupSize: number,
        tempGroupSize: number,
        tierIndex: number
    ) => {
        switch (true) {
            case !(
                pricesData[roomsSleepIndex].size * inventory[pricesData[roomsSleepIndex].id] <
                groupSize
            ):
                handleTiersTables(
                    tiersTables,
                    roomSize,
                    rooms,
                    roomTier,
                    roomsSleepIndex,
                    tableTemporaryInventory,
                    tierIndex
                )
                break
            default:
                while (tempIndex >= 0) {
                    switch (true) {
                        case !isRoomPriceInventoryEnough(pricesData[tempIndex].id):
                            break
                        default:
                            const hasArrangedPeople =
                                pricesData[tempIndex].size * inventory[pricesData[tempIndex].id]
                            while (tempGroupSize - hasArrangedPeople > 0 && tempIndex >= 1) {
                                const adequateRoomInventory = isRoomPriceInventoryEnough(
                                    pricesData[tempIndex].id
                                )
                                const isRoomIdInTiersTable = hasNotAddToTiersTables(
                                    tiersID,
                                    pricesData[tempIndex].id
                                )
                                if (adequateRoomInventory && isRoomIdInTiersTable) {
                                    handleTiersTables(
                                        tiersTables,
                                        roomSize,
                                        rooms,
                                        roomTier,
                                        tempIndex,
                                        tableTemporaryInventory,
                                        tierIndex
                                    )
                                }
                                tempGroupSize = tempGroupSize - hasArrangedPeople
                                tempIndex--
                            }
                            if (hasNotAddToTiersTables(tiersID, pricesData[tempIndex].id)) {
                                handleTiersTables(
                                    tiersTables,
                                    roomSize,
                                    rooms,
                                    roomTier,
                                    tempIndex,
                                    tableTemporaryInventory,
                                    tierIndex
                                )
                            }
                            tempIndex = 0
                            break
                    }
                    tempIndex -= 1
                }
                break
        }
        return tiersTables
    }

    const getSingleTiersTables = (
        tiersTables: Record<number, roomTableJSON[]>,
        rooms: HotelRoomJSON,
        tableTemporaryInventory: Record<string, number>,
        roomSize: number,
        groupSize: number,
        excludingInventory: boolean
    ) => {
        for (let tierIndex = 0; tierIndex < rooms.tiers.length; tierIndex++) {
            for (let sleep = rooms.sleeps; sleep >= rooms.minimumOccupancy; sleep--) {
                let flag = false
                const roomTier = getRoomTier(
                    codeIsRight,
                    sleep,
                    tableTemporaryInventory,
                    rooms,
                    tierIndex
                )
                if (!roomTier) {
                    continue
                }
                let pricesData: HotelRoomTierPriceJSON[] = []
                if (rooms.isPricePerRoom) {
                    for (
                        let sizeOfRoom = rooms.minimumOccupancy;
                        sizeOfRoom <= rooms.sleeps;
                        sizeOfRoom++
                    ) {
                        const data = { ...roomTier.prices[0], size: sizeOfRoom }
                        pricesData.push(data)
                    }
                } else {
                    pricesData = roomTier.prices
                        .filter(
                            (price) =>
                                price.size <= rooms.sleeps && price.size >= rooms.minimumOccupancy
                        )
                        .sort((a, b) => a.size - b.size)
                }
                for (let j = sleep; j >= 0; j--) {
                    const tiersID = tiersTables[roomSize].map((r) => r.pricingID)
                    switch (true) {
                        case pricesData[j] == undefined:
                            continue
                        case !hasNotAddToTiersTables(tiersID, pricesData[j].id):
                            break
                        default:
                            const tempIndex = j
                            const tempGroupSize = groupSize
                            switch (true) {
                                case roomSize !== pricesData[j].size:
                                    break
                                case excludingInventory:
                                    getExcludingInventoryTablesData(
                                        tiersTables,
                                        roomSize,
                                        rooms,
                                        roomTier,
                                        tempIndex,
                                        tableTemporaryInventory,
                                        j,
                                        pricesData,
                                        tiersID,
                                        tierIndex
                                    )
                                    if (
                                        (j + 1) * tableTemporaryInventory[pricesData[j].id] >
                                        groupSize
                                    ) {
                                        flag = true
                                    }
                                    break
                                default:
                                    getIncludingInventoryTablesData(
                                        tiersTables,
                                        roomSize,
                                        rooms,
                                        roomTier,
                                        tempIndex,
                                        tableTemporaryInventory,
                                        j,
                                        pricesData,
                                        tiersID,
                                        groupSize,
                                        tempGroupSize,
                                        tierIndex
                                    )
                                    if ((j + 1) * inventory[pricesData[j].id] > groupSize) {
                                        flag = true
                                    }
                                    break
                            }
                    }
                }
                if (flag) break
            }
            getSortedTablesData(tiersTables, roomSize)
            tiersTables[roomSize].sort((a, b) => a.tierIndex - b.tierIndex)
        }
        return tiersTables
    }

    const singleTiersData = (hotel: HotelJSON, groupSize: number, excludingInventory: boolean) => {
        let tiersTables: Record<number, roomTableJSON[]> = {}
        let newTables: Record<number, [roomTableJSON[]]> = {}
        const tableTemporaryInventory: Record<string, number> = { ...inventory }
        if (excludingInventory) {
            Object.entries(tableTemporaryInventory).forEach(([id, qty]) => {
                tableTemporaryInventory[id] = 8
            })
        }
        for (let roomSize = 1; roomSize <= 8; roomSize++) {
            tiersTables[roomSize] = []
            hotel.rooms.map((rooms) => {
                tiersTables = getSingleTiersTables(
                    tiersTables,
                    rooms,
                    tableTemporaryInventory,
                    roomSize,
                    groupSize,
                    excludingInventory
                )
            })

            newTables = getNewTablesData(newTables, roomSize, tiersTables)
        }
        return newTables
    }

    const totalTiersData = (excludingInventory: boolean) => {
        let summaryTable: Record<number, {}> = {}
        let hotelTotalTable: hotelTableJSON[] = []
        for (let i = 0; i < event.hotels.length; i++) {
            const hotel = event.hotels[i]
            summaryTable = {}
            for (let groupSize = 1; groupSize <= 8; groupSize++) {
                summaryTable[groupSize] = singleTiersData(hotel, groupSize, excludingInventory)
            }
            hotelTotalTable.push({
                hotelID: hotel.id,
                hotelTable: summaryTable
            })
        }
        return hotelTotalTable
    }

    const getCheapestPrice = (
        availableRoom: HotelRoomJSON[],
        groupSize: number,
        hotelId: string,
        isOnlySellHotel: boolean,
        overBaseOccupancyFee: number,
        totalTiersPriceData: hotelTableJSON | undefined,
        unlimitedInventoryRoomTiers: Record<string, boolean>
    ) => {
        let priceData = 0
        if (totalTiersPriceData) {
            const cheapestPriceData = calculateCheapestPrice(
                newAssignRoom(groupSize),
                availableRoom,
                groupSize,
                hotelId,
                isOnlySellHotel,
                totalTiersPriceData.hotelTable,
                overBaseOccupancyFee,
                unlimitedInventoryRoomTiers
            )
            priceData = cheapestPriceData.price
        }
        return priceData
    }

    const deleteInsufficientInventoryTables = (
        tables: Record<number, [roomTableJSON[]]>,
        roomSize: number
    ) => {
        while (true) {
            const isTablesExiting = tables[roomSize].length && tables[roomSize][0].length
            if (isTablesExiting && tables[roomSize][0][0].inventory <= 0) {
                tables[roomSize][0].shift()
                if (tables[roomSize][0].length == 0) {
                    tables[roomSize].shift()
                }
            } else {
                break
            }
        }
        tables[roomSize].sort((a, b) => a[0].price - b[0].price)
        return tables
    }

    const deleteEmptyTables = (tables: Record<number, [roomTableJSON[]]>, roomSize: number) => {
        if (tables[roomSize].length > 0 && tables[roomSize][0].length > 0) {
            tables[roomSize][0].shift()
            if (tables[roomSize][0].length == 0) {
                tables[roomSize].shift()
            }
        }
        tables[roomSize].sort((a, b) => a[0].price - b[0].price)
        return tables
    }

    const removeNullValues = (associateTicketRoomsTable: any, size: number, index: number) => {
        associateTicketRoomsTable = JSON.parse(JSON.stringify(associateTicketRoomsTable))
        if (associateTicketRoomsTable[size][index].length == 0) {
            associateTicketRoomsTable[size].splice(index, 1)
        }
        while (associateTicketRoomsTable[size].indexOf(null) !== -1) {
            const nullIndex = associateTicketRoomsTable[size].indexOf(null)
            associateTicketRoomsTable[size].splice(nullIndex, 1)
        }
        return associateTicketRoomsTable
    }

    const handleAssociateTicketRooms = (
        associateTicketAvailableRoom: HotelRoomJSON[],
        newTables: Record<number, [roomTableJSON[]]>
    ) => {
        let associateTicketRoomsTable: any = {}
        for (let size = 1; size <= 8; size++) {
            associateTicketRoomsTable[size] = []
            newTables[size].map((tables, index) => {
                associateTicketRoomsTable[size][index] = []
                tables.map((tier) => {
                    associateTicketAvailableRoom.map((room) => {
                        if (room.id === tier.roomID) {
                            associateTicketRoomsTable[size][index].push({ ...tier })
                        }
                    })
                })
                associateTicketRoomsTable = removeNullValues(associateTicketRoomsTable, size, index)
            })
        }
        return associateTicketRoomsTable
    }

    const getAssociateTicketRoomsTable = (
        isOnlySellHotel: boolean,
        associateTicketAvailableRoom: HotelRoomJSON[],
        newTables: Record<number, [roomTableJSON[]]>
    ) => {
        let associateRoomsTables: Record<number, [roomTableJSON[]]> = {}
        switch (true) {
            case !isOnlySellHotel:
                associateRoomsTables = JSON.parse(
                    JSON.stringify(
                        handleAssociateTicketRooms(associateTicketAvailableRoom, newTables)
                    )
                )
                break
            default:
                associateRoomsTables = JSON.parse(JSON.stringify(newTables))
                break
        }
        return associateRoomsTables
    }

    const handleUnlimitInventoryTables = (
        tables: Record<number, [roomTableJSON[]]>,
        tiersId: string
    ) => {
        for (let i = 0; i < Object.values(tables).length; i++) {
            let table = Object.values(tables)[i]
            if (table.length > 0 && table[0].length > 0) {
                table.forEach((tier) => {
                    tier.forEach((item) => {
                        if (item.tiersId === tiersId) {
                            item.inventory -= 1
                        }
                    })
                })
            }
        }
        return tables
    }

    const getOverbaseFeeTables = (
        choicesPrice: Record<number, any[]> = {},
        roomSizeIndex: number,
        baseOccupancyTotal: Record<number, number> = {},
        groupSize: number,
        overBaseFee: Record<number, number> = {},
        overBaseOccupancyFee: number
    ) => {
        choicesPrice[roomSizeIndex].map((combinationOfResult) => {
            baseOccupancyTotal[roomSizeIndex] += combinationOfResult.baseOccupancy
        })
        switch (true) {
            case groupSize - baseOccupancyTotal[roomSizeIndex] <= 0:
                break
            case baseOccupancyTotal[roomSizeIndex] === 0:
                break
            default:
                overBaseFee[roomSizeIndex] =
                    (groupSize - baseOccupancyTotal[roomSizeIndex]) *
                    overBaseOccupancyFee *
                    allNights
                break
        }
        return overBaseFee
    }

    const getTotalResortFeeTables = (
        choicesPrice: Record<number, any[]> = {},
        hotelId: string,
        roomSizeIndex: number,
        totalResortFeeTable: Record<number, number> = {}
    ) => {
        const roomSize = choicesPrice[roomSizeIndex].length
        const { resortFee, resortFeeType } = hotelsResortInfo.find(
            (hotel) => hotel.id === hotelId
        ) || { resortFee: 0, resortFeeType: '' }
        const totalResortFee = getTotalResortFee(resortFee, resortFeeType, roomSize, allNights)
        totalResortFeeTable[roomSizeIndex] = totalResortFee
        return totalResortFeeTable
    }

    const getCalculatePriceTables = (
        choices: any[],
        tables: Record<number, [roomTableJSON[]]>,
        choicesPrice: Record<number, any[]>,
        choicesArrayIndex: number,
        associateTicketRoomsTable: Record<number, [roomTableJSON[]]>,
        groupSize: number,
        priceOfError: Record<number, string>,
        unlimitedInventoryRoomTiers?: Record<string, boolean>
    ) => {
        let flag = false
        let endLoop = false
        let insufficientInventoryArray: string[] = []
        let tempGroupSize = groupSize
        let numberOfSleepingPeople = 0
        for (let roomSize = 8; roomSize >= 1; roomSize--) {
            if (choices[roomSize - 1] === 0) continue

            for (let i = 0; i < choices[roomSize - 1]; i++) {
                deleteInsufficientInventoryTables(tables, roomSize)
                if (tables[roomSize].length > 0 && tables[roomSize][0].length !== 0) {
                    let targetTiers = tables[roomSize][0][0]
                    switch (true) {
                        case !insufficientInventoryArray.includes(targetTiers.pricingID) &&
                            targetTiers.inventory > 0:
                            choicesPrice[choicesArrayIndex].push({ ...targetTiers })
                            numberOfSleepingPeople += roomSize
                            break
                        default:
                            deleteInsufficientInventoryTables(tables, roomSize)
                            if (tables[roomSize].length > 0 && tables[roomSize][0].length > 0) {
                                targetTiers = tables[roomSize][0][0]
                            }
                            if (insufficientInventoryArray.includes(targetTiers.pricingID)) {
                                deleteEmptyTables(tables, roomSize)
                            }
                            if (tables[roomSize].length > 0 && tables[roomSize][0].length > 0) {
                                targetTiers = tables[roomSize][0][0]
                                if (targetTiers.inventory > 0) {
                                    choicesPrice[choicesArrayIndex].push({ ...targetTiers })
                                    numberOfSleepingPeople += roomSize
                                }
                            }
                            break
                    }

                    switch (true) {
                        case tables[roomSize].length > 0 && tables[roomSize][0].length > 0:
                            switch (true) {
                                case unlimitedInventoryRoomTiers !== undefined &&
                                    unlimitedInventoryRoomTiers[targetTiers.tiersId]:
                                    handleUnlimitInventoryTables(tables, targetTiers.tiersId)
                                    break
                                default:
                                    targetTiers.inventory -= 1
                                    break
                            }
                            switch (true) {
                                case targetTiers.inventory > 0:
                                    break
                                case !insufficientInventoryArray.includes(targetTiers.pricingID):
                                    insufficientInventoryArray.push(...[targetTiers.pricingID])
                                default:
                                    deleteInsufficientInventoryTables(tables, roomSize)
                                    break
                            }
                            break
                        default:
                            flag = true
                            break
                    }
                } else {
                    if (associateTicketRoomsTable[roomSize].length < 1) {
                        priceOfError[choicesArrayIndex] = 'Unavailable Room'
                        break
                    }
                    switch (true) {
                        case groupSize - numberOfSleepingPeople <= 0:
                            break
                        case tables[roomSize].length >= 1:
                            break
                        default:
                            choicesPrice[choicesArrayIndex] = []
                            priceOfError[
                                choicesArrayIndex
                            ] = `Sold Out for Groups Larger Than ${numberOfSleepingPeople}`
                            flag = true
                    }
                    break
                }
            }

            switch (true) {
                case tempGroupSize - choices[roomSize - 1] * roomSize <= 0:
                    break
                case associateTicketRoomsTable[roomSize].length >= 1:
                    break
                default:
                    tempGroupSize -= choices[roomSize - 1] * roomSize
                    if (tables[roomSize].length < 1) {
                        choicesPrice[choicesArrayIndex] = []
                        priceOfError[
                            choicesArrayIndex
                        ] = `Sold Out for Groups Larger Than ${numberOfSleepingPeople}`
                        endLoop = true
                    }
                    break
            }

            switch (true) {
                case !flag:
                    break
                case associateTicketRoomsTable[roomSize].length < 1:
                    endLoop = true
                case groupSize - numberOfSleepingPeople > 0 && tables[roomSize].length < 1:
                    choicesPrice[choicesArrayIndex] = []
                    priceOfError[
                        choicesArrayIndex
                    ] = `Sold Out for Groups Larger Than ${numberOfSleepingPeople}`
                    endLoop = true
                    break
            }
            if (endLoop) break
        }
        return { choicesPrice, priceOfError }
    }

    const calculateCheapestPrice = (
        allChoicesArray: any[],
        associateTicketAvailableRoom: HotelRoomJSON[],
        groupSize: number,
        hotelId: string,
        isOnlySellHotel: boolean,
        newTotalTables: Record<number, Record<number, [roomTableJSON[]]>>,
        overBaseOccupancyFee: number,
        unlimitedInventoryRoomTiers?: Record<string, boolean>
    ) => {
        const newTables = newTotalTables[groupSize]
        let associateTicketRoomsTable: Record<number, [roomTableJSON[]]> = {}
        let baseOccupancyTotal: Record<number, number> = {}
        let choicesPrice: Record<number, any[]> = {}
        let priceOfError: Record<number, string> = {}
        let cheapestPrice: CheapestPriceJSON = { price: 0, error: '' }
        let totalPrice: Record<number, number> = {}
        let overBaseFee: Record<number, number> = {}
        let totalResortFeeTable: Record<number, number> = {}
        let unavailableRoom = false

        switch (true) {
            case associateTicketAvailableRoom.length == 0:
                unavailableRoom = true
                break
            default:
                associateTicketRoomsTable = getAssociateTicketRoomsTable(
                    isOnlySellHotel,
                    associateTicketAvailableRoom,
                    newTables
                )
                break
        }

        for (let j = 0; j < allChoicesArray.length; j++) {
            if (unavailableRoom) {
                totalPrice[j] = 1000000000
                break
            }
            const tables: Record<number, [roomTableJSON[]]> = JSON.parse(
                JSON.stringify(associateTicketRoomsTable)
            )
            const choices = allChoicesArray[j]
            choicesPrice[j] = []
            priceOfError[j] = ''
            totalPrice[j] = 0
            baseOccupancyTotal[j] = 0
            overBaseFee[j] = 0
            totalResortFeeTable[j] = 0
            const choicesPriceData = getCalculatePriceTables(
                choices,
                tables,
                choicesPrice,
                j,
                associateTicketRoomsTable,
                groupSize,
                priceOfError,
                unlimitedInventoryRoomTiers
            )

            choicesPrice = choicesPriceData.choicesPrice
            priceOfError = choicesPriceData.priceOfError
            overBaseFee = getOverbaseFeeTables(
                choicesPrice,
                j,
                baseOccupancyTotal,
                groupSize,
                overBaseFee,
                overBaseOccupancyFee
            )
            totalResortFeeTable = getTotalResortFeeTables(
                choicesPrice,
                hotelId,
                j,
                totalResortFeeTable
            )
            for (let index = 0; index < Object.keys(choicesPrice).length; index++) {
                totalPrice[index] = choicesPrice[index].reduce(
                    (sum, everyChoice) => sum + everyChoice.price,
                    0
                )
                if (totalPrice[index] === 0) {
                    continue
                }
                totalPrice[index] += overBaseFee[index] + totalResortFeeTable[index]
            }
        }

        const realTotalPrice = Object.values(totalPrice).filter((price) => price !== 0)

        const priceOfErrorArray = Object.values(priceOfError)

        switch (true) {
            case priceOfErrorArray.every((error) => error === 'Unavailable Room'):
                cheapestPrice = { price: 99999999, error: 'Sold Out' }
                break
            case priceOfErrorArray.every((error) => error !== '') &&
                priceOfErrorArray.some((item) => item.includes('Sold Out for Groups Larger Than')):
                const insufficientInventoryErrorMessage = priceOfErrorArray
                    .filter((item) => item.includes('Sold Out for Groups Larger Than'))
                    .sort((a: any, b: any) => b.localeCompare(a))
                if (insufficientInventoryErrorMessage[0] === 'Sold Out for Groups Larger Than 0') {
                    cheapestPrice = { price: 99999999, error: 'Sold Out' }
                } else {
                    cheapestPrice = { price: 99999999, error: insufficientInventoryErrorMessage[0] }
                }
                break
            case realTotalPrice.length !== 0:
                cheapestPrice = { price: realTotalPrice.sort((a, b) => a - b)[0], error: '' }
                break
            default:
                cheapestPrice = { price: 99999999, error: 'Sold Out' }
                break
        }

        return cheapestPrice
    }

    return {
        totalTiersData,
        calculateCheapestPrice,
        getCheapestPrice
    }
}

import { EventJSON } from '../../../events-service/src/models/event'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import { DateRange } from '@mui/x-date-pickers-pro'
import { getRoomTier } from 'pages/events/[slug]'
import { HotelRoomJSON } from '../../../events-service/src/models/hotelRoom'
import { HotelRoomShoulderNightJSON } from '../../../events-service/src/models/hotelRoomShoulderNight'
import { HotelRoomTierJSON } from '../../../events-service/src/models/hotelRoomTier'
import { shoulderDataJSON } from './customizeJsonType'
import { isSubset } from './parse'
import { HotelJSON } from '../../../events-service/src/models/hotel'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

export const dateRange = (
    startDate: string | Date,
    endDate: string | Date,
    timeZone?: string | undefined
) => {
    timeZone = timeZone || 'America/Los_Angeles'
    const _startDate = dayjs(startDate).tz(timeZone)
    const _endDate = dayjs(endDate).tz(timeZone)
    if (_startDate.year() !== _endDate.year()) {
        return `${_startDate.format('MMMM DD, YYYY')} - ${_endDate.format('MMMM DD, YYYY')}`
    }
    if (_startDate.month() !== _endDate.month()) {
        return `${_startDate.format('MMMM DD')} - ${_endDate.format('MMMM DD, YYYY')}`
    }
    if (_startDate.date() === _endDate.date()) {
        return `${_startDate.format('MMMM DD, YYYY')}`
    }
    return `${_startDate.format('MMMM DD')} - ${_endDate.format('DD, YYYY')}`
}

export const convertToShowHour = (time: number) => {
    let hour = Math.floor(time / 60)
    let minute = time % 60
    let hourShow = ''
    let minuteShow = ''
    switch (hour) {
        case 0:
            hourShow = ''
            break
        case 1:
            hourShow = ' 1 hour'
            break
        default:
            hourShow = ' ' + hour.toString() + ' hours'
            break
    }
    switch (minute) {
        case 0:
            minuteShow = ''
            break
        case 1:
            minuteShow = ' 1 minute'
            break
        default:
            minuteShow = ' ' + minute.toString() + ' minutes'
            break
    }
    return hourShow + minuteShow
}

export const now = (timeZone?: string | undefined) => {
    timeZone = timeZone || 'America/Los_Angeles'
    return dayjs().tz(timeZone)
}

export const displayDate = (date: string | Date | number) => {
    const opts: Intl.DateTimeFormatOptions = {
        timeZone: 'UTC',
        month: '2-digit',
        day: '2-digit',
        year: '2-digit'
    }
    const fmt = new Intl.DateTimeFormat('en-US', opts)
    return fmt.format(date instanceof Date ? date : new Date(date))
}

export const dateCompareMax = (
    Date1: string | Date,
    Date2: string | Date,
    timeZone?: string | undefined
) => {
    timeZone = timeZone || 'America/Los_Angeles'
    const _Date1 = dayjs(Date1).tz(timeZone).startOf('day')
    const _Date2 = dayjs(Date2).tz(timeZone).startOf('day')
    return _Date1.isAfter(_Date2)
}

export const dateCompareMin = (
    Date1: string | Date,
    Date2: string | Date,
    timeZone?: string | undefined
) => {
    timeZone = timeZone || 'America/Los_Angeles'
    const _Date1 = dayjs(Date1).tz(timeZone).startOf('day')
    const _Date2 = dayjs(Date2).tz(timeZone).startOf('day')
    return _Date1.isBefore(_Date2)
}

export const getEarliestDate = (dates: dayjs.Dayjs[]) => {
    // We return infinity to make sure tour is at end of sorting
    // and returns positive number when comparing
    if (dates.length === 0) {
        return Infinity
    }
    return dates.reduce((earliest, current) => {
        if (current.valueOf() < earliest) {
            return current.valueOf()
        }
        return earliest
    }, Infinity)
}

export const getShoulderNightsByEvent = (event: EventJSON): any[] => {
    let shoulderNightsArray: any[] = []
    event.hotels.map((hotel) =>
        hotel.rooms.map((room) => {
            if (room.shoulderNights.length) {
                shoulderNightsArray.push(room.shoulderNights)
            }
            return false
        })
    )
    const sortedNightsArray: any[] = shoulderNightsArray?.[0]?.sort(
        (a: { bookDate: number }, b: { bookDate: number }) => a.bookDate - b.bookDate
    )
    const startAndEndDates: any[] = [
        sortedNightsArray?.[0],
        sortedNightsArray?.[sortedNightsArray?.length - 1]
    ]
    return startAndEndDates
}

const sortShoulderArray = (shoulderNightData: shoulderDataJSON[], check: 'in' | 'out') => {
    if (check === 'in') {
        return shoulderNightData.sort((a, b) => b.checkInArray.length - a.checkInArray.length)
    }
    return shoulderNightData.sort((a, b) => b.checkOutArray.length - a.checkOutArray.length)
}

const inventoryHandle = (
    tier: HotelRoomTierJSON,
    inventory: Record<string, number>,
    pricingID: string
) => {
    const pricing = tier.prices.find((p) => {
        return p.id === pricingID
    })
    if (pricing?.quantityAvailable === -1) {
        tier.prices.map((p) => {
            if (p.id !== pricingID && p.quantityAvailable === -1 && inventory[p.id] > 0) {
                inventory[p.id] -= 1
            }
        })
    }
    return inventory
}

const calculateMaxCheckDistance = (
    shoulderNightData: shoulderDataJSON[],
    groupSize: number,
    inventory: Record<string, number>,
    check: 'in' | 'out'
) => {
    let peopleNumber = groupSize
    let temInventory = { ...inventory }
    let minimumOccupancyTotal = 0
    let shoulderPointer = 0
    let updateShoulder: {
        room: HotelRoomJSON
        shoulderNights: HotelRoomShoulderNightJSON[]
        shoulderNightsInventory: number[]
    }[] = []
    if (check === 'in') {
        shoulderNightData.map((s) => {
            updateShoulder.push({
                room: s.room,
                shoulderNights: s.checkInArray,
                shoulderNightsInventory: s.checkInArrayInventory
            })
        })
    } else {
        shoulderNightData.map((s) => {
            updateShoulder.push({
                room: s.room,
                shoulderNights: s.checkOutArray,
                shoulderNightsInventory: s.checkOutArrayInventory
            })
        })
    }
    while (peopleNumber > 0 && shoulderPointer < shoulderNightData.length) {
        let selectedShoulder = updateShoulder[shoulderPointer]

        let found = false
        const roomTier = getRoomTier(true, groupSize, inventory, selectedShoulder.room)
        if (!roomTier || selectedShoulder.shoulderNightsInventory.every((s) => s <= 0)) {
            shoulderPointer += 1
            continue
        }
        const roomPricing = roomTier.prices.sort((a, b) => a.size - b.size)
        for (let j = 0; j < roomPricing.length; j++) {
            const currentPricing = roomPricing[j]
            if (temInventory[currentPricing.id] === 0) {
                continue
            }
            if (
                peopleNumber < currentPricing.size &&
                minimumOccupancyTotal + currentPricing.size > groupSize
            ) {
                continue
            }
            minimumOccupancyTotal += currentPricing.size
            temInventory[currentPricing.id] -= 1
            const newShoulderNightsInventory = selectedShoulder.shoulderNightsInventory.map((s) => {
                return (s -= 1)
            })
            selectedShoulder.shoulderNightsInventory = newShoulderNightsInventory
            temInventory = inventoryHandle(roomTier, temInventory, currentPricing.id)
            peopleNumber -= selectedShoulder.room.sleeps
            found = true
            break
        }
        if (found) {
            const needExecuteNextLoop =
                peopleNumber > 0 && shoulderPointer < shoulderNightData.length
            const shoulderNightInventoryEnough = selectedShoulder.shoulderNightsInventory.every(
                (s) => s > 0
            )
            if (!shoulderNightInventoryEnough && needExecuteNextLoop) {
                const inventoryNotEnoughIndex = selectedShoulder.shoulderNightsInventory.indexOf(0)
                selectedShoulder.shoulderNights = selectedShoulder.shoulderNights.slice(
                    0,
                    inventoryNotEnoughIndex
                )
                selectedShoulder.shoulderNightsInventory =
                    selectedShoulder.shoulderNightsInventory.slice(0, inventoryNotEnoughIndex)
                updateShoulder = updateShoulder.sort(
                    (a, b) => b.shoulderNights.length - a.shoulderNights.length
                )
                shoulderPointer = 0
            }
        } else {
            shoulderPointer += 1
        }
    }
    if (shoulderPointer === shoulderNightData.length && peopleNumber > 0) {
        return 0
    }
    return updateShoulder[shoulderPointer].shoulderNights.length
}

const getShoulderAndInventoryByRoom = (
    room: HotelRoomJSON,
    inventory: Record<string, number>,
    eventCheckIn: Date | string,
    eventCheckOut: Date | string,
    timeZone: string,
    check: 'in' | 'out'
) => {
    let inventoryArray: number[] = []
    let shoulderArray: HotelRoomShoulderNightJSON[] = []
    const inventoryHandle = (inventoryArray: number[]) => {
        return inventoryArray.map((inventory, index) => {
            if (index > 0 && inventory > inventoryArray[index - 1]) {
                return inventoryArray[index - 1]
            }
            return inventory
        })
    }
    if (check === 'in') {
        shoulderArray = room.shoulderNights
            .filter((s) => {
                return dateCompareMin(s.bookDate, eventCheckIn, timeZone) && inventory[s.hotelRoomID] > 0
            })
            .sort((a, b) =>
                getTimeZone(b.bookDate, timeZone).diff(getTimeZone(a.bookDate, timeZone), 'day')
            )
            .filter((night, index) => {
                const eventCheckInFormat = getTimeZone(eventCheckIn, timeZone)
                    .subtract(index + 1, 'day')
                    .format('YYYY-MM-DD')
                const nightFormat = getTimeZone(night.bookDate, timeZone).format('YYYY-MM-DD')
                if (eventCheckInFormat === nightFormat) {
                    inventoryArray.push(inventory[night.id])
                }
                return eventCheckInFormat === nightFormat
            })
        inventoryArray = inventoryHandle(inventoryArray)
        return {
            checkInArray: shoulderArray,
            checkInArrayInventory: inventoryArray,
            checkOutArray: [],
            checkOutArrayInventory: []
        }
    }
    shoulderArray = room.shoulderNights
        .filter((s) => dateCompareMax(s.bookDate, eventCheckOut, timeZone) && inventory[s.hotelRoomID] > 0)
        .sort((a, b) =>
            getTimeZone(a.bookDate, timeZone).diff(getTimeZone(b.bookDate, timeZone), 'day')
        )
        .filter((night, index) => {
            const eventCheckOutFormat = getTimeZone(eventCheckOut, timeZone)
                .add(index + 1, 'day')
                .format('YYYY-MM-DD')
            const nightFormat = getTimeZone(night.bookDate, timeZone).format('YYYY-MM-DD')
            if (eventCheckOutFormat === nightFormat) {
                inventoryArray.push(inventory[night.id])
            }
            return eventCheckOutFormat === nightFormat
        })
    inventoryArray = inventoryHandle(inventoryArray)
    return {
        checkInArray: [],
        checkInArrayInventory: [],
        checkOutArray: shoulderArray,
        checkOutArrayInventory: inventoryArray
    }
}

const getShoulderNightMaxRange = (
    shoulderNightData: shoulderDataJSON[],
    groupSize: number,
    inventory: Record<string, number>
) => {
    let checkInDistance = 0
    let checkOutDistance = 0
    let sortCheckInArray = sortShoulderArray([...shoulderNightData], 'in')
    let sortCheckOutArray = sortShoulderArray([...shoulderNightData], 'out')
    for (let j = 0; j < shoulderNightData.length; j++) {
        const sipSortCheckInArray = sortCheckInArray.slice(j)
        if (sipSortCheckInArray[0].checkInArray.length > checkInDistance) {
            let _checkInDistance = calculateMaxCheckDistance(
                sipSortCheckInArray,
                groupSize,
                inventory,
                'in'
            )
            if (_checkInDistance && _checkInDistance > checkInDistance) {
                checkInDistance = _checkInDistance
            }
        }
        const sipSortCheckOutArray = sortCheckOutArray.slice(j)
        if (sipSortCheckOutArray[0].checkOutArray.length > checkOutDistance) {
            let _checkOutDistance = calculateMaxCheckDistance(
                sipSortCheckOutArray,
                groupSize,
                inventory,
                'out'
            )
            if (_checkOutDistance && _checkOutDistance > checkOutDistance) {
                checkOutDistance = _checkOutDistance
            }
        }
    }
    return [checkInDistance, checkOutDistance]
}

export const getShoulderNightsByHotelId = (
    event: EventJSON,
    hotel: HotelJSON | undefined,
    inventory: Record<string, number>,
    ticketId: string,
    groupSize: number,
    selectedDateRange?: string[]
): DateRange<Dayjs> | undefined => {
    let checkInDistance = 0
    let checkOutDistance = 0
    let shoulderNightData: shoulderDataJSON[] = []
    hotel?.rooms.map((room) => {
        if (room.tickets.length > 0 && ticketId && !room.tickets.some((t) => t.id === ticketId)) {
            return
        }
        const roomTier = getRoomTier(true, groupSize, inventory, room)
        if (!roomTier && room.shoulderNights.length > 0) return
        if (selectedDateRange) {
            let hotelRoomShoulderNightDate: any[] = []
            room.shoulderNights.map((shoulder) => {
                let roomShoulderNightsDate = getTimeZone(shoulder.bookDate, event.timezone).format(
                    'MMM DD, YYYY'
                )
                hotelRoomShoulderNightDate.push(roomShoulderNightsDate)
            })
            if (!isSubset(selectedDateRange, hotelRoomShoulderNightDate)) return
        }
        const { checkInArray, checkInArrayInventory } = getShoulderAndInventoryByRoom(
            room,
            inventory,
            event.checkInDate,
            event.checkOutDate,
            event.timezone,
            'in'
        )
        const { checkOutArray, checkOutArrayInventory } = getShoulderAndInventoryByRoom(
            room,
            inventory,
            event.checkInDate,
            event.checkOutDate,
            event.timezone,
            'out'
        )
        if (checkInArray.length > 0 || checkOutArray.length > 0) {
            shoulderNightData.push({
                room: room,
                checkInArray: checkInArray,
                checkInArrayInventory: checkInArrayInventory,
                checkOutArray: checkOutArray,
                checkOutArrayInventory: checkOutArrayInventory
            })
        }
    })
    if (shoulderNightData.length === 0) {
        return undefined
    }
    ;[checkInDistance, checkOutDistance] = getShoulderNightMaxRange(
        shoulderNightData,
        groupSize,
        inventory
    )
    if (checkInDistance === 0 && checkOutDistance === 0) {
        return undefined
    }
    const earliestDate = getLocalTimeZone(event.checkInDate, event.timezone).subtract(
        checkInDistance,
        'day'
    )
    const latestDate = getLocalTimeZone(event.checkOutDate, event.timezone).add(
        checkOutDistance,
        'day'
    )
    return [earliestDate, latestDate] as unknown as DateRange<Dayjs>
}

export const getShoulderInventoryErrorDate = (
    hotelId: string,
    event: EventJSON,
    ticketId: string,
    groupSize: number,
    inventory: Record<string, number>,
    startDayDistance: number,
    endDateDistance: number
) => {
    let shoulderInventoryNotEnough = true
    let inventoryErrorDate = []
    let checkInErrorDate: string[] = []
    let checkOutErrorDate: string[] = []
    let shoulderNightRange: DateRange<Dayjs> | undefined = undefined
    const getCheckInErrorDateArray = (startDayDistance: number, minDateDistance: number) => {
        let checkInErrorDate = []
        for (let i = 0; i < startDayDistance; i++) {
            checkInErrorDate.push(
                getTimeZone(event.checkInDate, event.timezone)
                    .subtract(minDateDistance + i + 1, 'day')
                    .format('MMM DD, YYYY')
            )
        }
        checkInErrorDate.sort((a, b) => {
            return -1
        })
        return checkInErrorDate
    }

    const getCheckOutErrorDateArray = (endDateDistance: number, maxDateDistance: number) => {
        let checkOutErrorDate = []
        for (let j = 0; j < endDateDistance; j++) {
            checkOutErrorDate.push(
                getTimeZone(event.checkOutDate, event.timezone)
                    .add(maxDateDistance + j + 1, 'day')
                    .format('MMM DD, YYYY')
            )
        }
        return checkOutErrorDate
    }

    const selectedCheckInDate = getCheckInErrorDateArray(startDayDistance, 0)
    const selectedCheckOutDate = getCheckOutErrorDateArray(endDateDistance, 0)
    const hotel = event.hotels.find((hotel) => hotel.id === hotelId)
    const selectedDateRange = selectedCheckInDate.concat(selectedCheckOutDate)
    shoulderNightRange = getShoulderNightsByHotelId(
        event,
        hotel,
        inventory,
        ticketId,
        groupSize,
        selectedDateRange
    )
    if (!shoulderNightRange) {
        checkInErrorDate = selectedCheckInDate
        checkOutErrorDate = selectedCheckOutDate
        inventoryErrorDate = [...checkInErrorDate, ...checkOutErrorDate]
    } else {
        const minDate = dayjs(shoulderNightRange[0]).startOf('day')
        const maxDate = dayjs(shoulderNightRange[1]).startOf('day')
        const minDateDistance = getLocalTimeZone(event.checkInDate, event.timezone)
            .startOf('day')
            .diff(minDate, 'day')
        const maxDateDistance = maxDate.diff(
            getLocalTimeZone(event.checkOutDate, event.timezone).startOf('day'),
            'day'
        )
        if (minDateDistance >= startDayDistance && maxDateDistance >= endDateDistance) {
            shoulderInventoryNotEnough = false
            return {
                shoulderInventoryNotEnough: shoulderInventoryNotEnough,
                inventoryErrorDate: []
            }
        }
        if (minDateDistance < startDayDistance) {
            const startDateDiff = startDayDistance - minDateDistance
            checkInErrorDate = getCheckInErrorDateArray(startDateDiff, minDateDistance)
        }
        if (maxDateDistance < endDateDistance) {
            const endDateDiff = endDateDistance - maxDateDistance
            checkOutErrorDate = getCheckOutErrorDateArray(endDateDiff, maxDateDistance)
        }
    }
    inventoryErrorDate = [...checkInErrorDate, ...checkOutErrorDate]
    return {
        shoulderInventoryNotEnough: shoulderInventoryNotEnough,
        inventoryErrorDate: inventoryErrorDate
    }
}

export const updateShoulderNightRanges = (
    checkInDate: string | Date,
    checkOutDate: string | Date,
    eventTimezone: string,
    eventHotels: HotelJSON[] | undefined,
    groupSize: number,
    hotelId: string,
    inventory: Record<string, number>,
    newCheckIn: Dayjs | null,
    newCheckOut: Dayjs | null,
    ticketId: string
) => {
    const checkInDiff = getLocalTimeZone(checkInDate, eventTimezone).diff(newCheckIn, 'day')
    const checkOutDiff = newCheckOut?.diff(getLocalTimeZone(checkOutDate, eventTimezone), 'day')
    const selectEventCheckIn = checkInDiff === 0
    const selectEventCheckOut = checkOutDiff === 0
    const hasSelectCheckInOnly = !selectEventCheckIn && selectEventCheckOut
    const hasSelectCheckOutOnly = selectEventCheckIn && !selectEventCheckOut
    let shoulderNightData: shoulderDataJSON[] = []
    let shoulderNightArray: {
        checkInDistance: number | undefined
        checkOutDistance: number | undefined
    } = { checkInDistance: undefined, checkOutDistance: undefined }

    let checkInDistance = 0
    let checkOutDistance = 0
    const hotel = eventHotels?.find((hotel: { id: string }) => hotel.id === hotelId)
    const inventoryHandle = (
        hasSelectedCheckLength: number,
        hasSelectedCheckArrayInventory: number[],
        checkArrayInventory: number[]
    ) => {
        const selectedCheckInventory: number =
            hasSelectedCheckArrayInventory[hasSelectedCheckLength - 1]
        return checkArrayInventory.map((inventory) => {
            if (inventory > selectedCheckInventory) {
                inventory = selectedCheckInventory
            }
            return inventory
        })
    }

    if (hasSelectCheckInOnly) {
        hotel?.rooms.map((room: HotelRoomJSON) => {
            const ticketNotAssociateRoom =
                room.tickets.length > 0 && ticketId && !room.tickets.some((t) => t.id === ticketId)
            if (ticketNotAssociateRoom) return
            const roomTier = getRoomTier(true, groupSize, inventory, room)
            if (roomTier && room.shoulderNights.length > 0) {
                const hasSelectedCheckInArray = room.shoulderNights.filter(
                    (shoulderNight) =>
                        !getLocalTimeZone(shoulderNight?.bookDate, eventTimezone).isBefore(
                            newCheckIn
                        ) &&
                        dateCompareMin(shoulderNight?.bookDate, checkInDate, eventTimezone) &&
                        inventory[shoulderNight?.id] > 0
                )
                if (hasSelectedCheckInArray.length === checkInDiff) {
                    const { checkInArrayInventory } = getShoulderAndInventoryByRoom(
                        room,
                        inventory,
                        checkInDate,
                        checkOutDate,
                        eventTimezone,
                        'in'
                    )
                    const { checkOutArray, checkOutArrayInventory } = getShoulderAndInventoryByRoom(
                        room,
                        inventory,
                        checkInDate,
                        checkOutDate,
                        eventTimezone,
                        'out'
                    )
                    const newCheckOutArrayInventory = inventoryHandle(
                        hasSelectedCheckInArray.length,
                        checkInArrayInventory,
                        checkOutArrayInventory
                    )
                    if (checkOutArray.length > 0) {
                        shoulderNightData.push({
                            room: room,
                            checkInArray: [],
                            checkInArrayInventory: [],
                            checkOutArray: checkOutArray,
                            checkOutArrayInventory: newCheckOutArrayInventory
                        })
                    }
                }
            }
        })
    } else if (hasSelectCheckOutOnly) {
        hotel?.rooms.map((room: HotelRoomJSON) => {
            const ticketNotAssociateRoom =
                room.tickets.length > 0 && ticketId && !room.tickets.some((t) => t.id === ticketId)
            if (ticketNotAssociateRoom) return
            const roomTier = getRoomTier(true, groupSize, inventory, room)
            if (roomTier && room.shoulderNights.length > 0) {
                const hasSelectedCheckOutArray = room.shoulderNights.filter(
                    (shoulderNight) =>
                        !newCheckOut?.isBefore(
                            getLocalTimeZone(shoulderNight.bookDate, eventTimezone)
                        ) &&
                        dateCompareMax(shoulderNight.bookDate, checkInDate, eventTimezone) &&
                        inventory[shoulderNight.id] > 0
                )
                if (hasSelectedCheckOutArray.length === checkOutDiff) {
                    const { checkInArray, checkInArrayInventory } = getShoulderAndInventoryByRoom(
                        room,
                        inventory,
                        checkInDate,
                        checkOutDate,
                        eventTimezone,
                        'in'
                    )
                    const { checkOutArrayInventory } = getShoulderAndInventoryByRoom(
                        room,
                        inventory,
                        checkInDate,
                        checkOutDate,
                        eventTimezone,
                        'out'
                    )
                    const newCheckInArrayInventory = inventoryHandle(
                        hasSelectedCheckOutArray.length,
                        checkOutArrayInventory,
                        checkInArrayInventory
                    )
                    if (checkInArray.length > 0) {
                        shoulderNightData.push({
                            room: room,
                            checkInArray: checkInArray,
                            checkInArrayInventory: newCheckInArrayInventory,
                            checkOutArray: [],
                            checkOutArrayInventory: []
                        })
                    }
                }
            }
        })
    } else if (!selectEventCheckIn && !selectEventCheckOut) {
        hotel?.rooms.map((room: HotelRoomJSON) => {
            const ticketNotAssociateRoom =
                room.tickets.length > 0 &&
                ticketId &&
                !room.tickets.some((ticket: { id: string }) => ticket.id === ticketId)
            if (ticketNotAssociateRoom) return
            const roomTier = getRoomTier(true, groupSize, inventory, room)
            if (roomTier && room.shoulderNights.length > 0) {
                const hasSelectedCheckInArray = room.shoulderNights.filter(
                    (s) =>
                        !getLocalTimeZone(s.bookDate, eventTimezone).isBefore(newCheckIn) &&
                        dateCompareMin(s.bookDate, checkInDate, eventTimezone) &&
                        inventory[s.id] > 0
                )
                const hasSelectedCheckOutArray = room.shoulderNights.filter(
                    (s) =>
                        !newCheckOut?.isBefore(getLocalTimeZone(s.bookDate, eventTimezone)) &&
                        dateCompareMax(s.bookDate, checkInDate, eventTimezone) &&
                        inventory[s.id] > 0
                )
                if (
                    hasSelectedCheckInArray.length === checkInDiff &&
                    hasSelectedCheckOutArray.length === checkOutDiff
                ) {
                    const { checkInArray, checkInArrayInventory } = getShoulderAndInventoryByRoom(
                        room,
                        inventory,
                        checkInDate,
                        checkOutDate,
                        eventTimezone,
                        'in'
                    )
                    const { checkOutArray, checkOutArrayInventory } = getShoulderAndInventoryByRoom(
                        room,
                        inventory,
                        checkInDate,
                        checkOutDate,
                        eventTimezone,
                        'out'
                    )
                    const newCheckOutArrayInventory = inventoryHandle(
                        hasSelectedCheckInArray.length,
                        checkInArrayInventory,
                        checkOutArrayInventory
                    )
                    const newCheckInArrayInventory = inventoryHandle(
                        hasSelectedCheckOutArray.length,
                        checkOutArrayInventory,
                        checkInArrayInventory
                    )
                    if (checkInArray.length > 0 || checkOutArray.length > 0) {
                        shoulderNightData.push({
                            room: room,
                            checkInArray: checkInArray,
                            checkInArrayInventory: newCheckInArrayInventory,
                            checkOutArray: checkOutArray,
                            checkOutArrayInventory: newCheckOutArrayInventory
                        })
                    }
                }
            }
        })
    }

    ;[checkInDistance, checkOutDistance] = getShoulderNightMaxRange(
        shoulderNightData,
        groupSize,
        inventory
    )
    if (hasSelectCheckInOnly) {
        shoulderNightArray = { checkInDistance: undefined, checkOutDistance: checkOutDistance }
    } else if (hasSelectCheckOutOnly) {
        shoulderNightArray = { checkInDistance: checkInDistance, checkOutDistance: undefined }
    } else if (!selectEventCheckIn && !selectEventCheckOut) {
        shoulderNightArray = {
            checkInDistance: checkInDistance,
            checkOutDistance: checkOutDistance
        }
    }

    return shoulderNightArray
}

export const getTimeZone = (date: string | Date | undefined, timeZone?: string | undefined) => {
    timeZone = timeZone || 'America/Los_Angeles'
    return dayjs(date).tz(timeZone)
}

export const getLocalTimeZone = (date: string | Date, timeZone?: string | undefined) => {
    timeZone = timeZone || 'America/Los_Angeles'
    return dayjs(dayjs(date).tz(timeZone).format('YYYY-MM-DD'))
}

export const getWordInitials = (text: string) => {
    const initials = text
        .split(' ')
        .map((word) => word.charAt(0))
        .join('')
    return initials
}

export const timeZoneString = (timeZone: string) => {
    let result = ''
    const date = new Date()
    const fullNameOfTimeZone = getTimeZone(date, timeZone).format('zzz')
    const timeZoneOffset = getTimeZone(date, timeZone).format('z')
    const partialWesternTimeZone = [
        'Pacific/Marquesas',
        'America/Lima',
        'America/Sao_Paulo',
        'America/Godthab',
        'Atlantic/Cape_Verde'
    ]
    const partialEasternTimeZone = [
        'Asia/Tehran',
        'Asia/Dubai',
        'Asia/Kabul',
        'Asia/Kathmandu',
        'Asia/Dacca',
        'Asia/Rangoon',
        'Australia/Eucla'
    ]
    const partOfEasternTimeZone = [
        'Australia/LHI',
        'Asia/Sakhalin',
        'Pacific/Chatham',
        'Pacific/Tongatapu',
        'Pacific/Kiritimati'
    ]
    switch (true) {
        case partialWesternTimeZone.includes(timeZone):
            result = timeZoneOffset.replace('GMT-', '-0').replace(':', '')
            break
        case partialEasternTimeZone.includes(timeZone):
            result = timeZoneOffset.replace('GMT+', '+0').replace(':', '')
            break
        case partOfEasternTimeZone.includes(timeZone):
            result = timeZoneOffset.replace('GMT', '').replace(':', '')
            break
        case fullNameOfTimeZone === 'Western Indonesia Time':
            result = 'WIB'
            break
        case fullNameOfTimeZone === 'Pakistan Standard Time':
            result = 'PKT'
            break
        case fullNameOfTimeZone === 'Moscow Standard Time':
            result = 'MSK'
            break
        case fullNameOfTimeZone === 'West Africa Standard Time':
            result = 'WAT'
            break
        case fullNameOfTimeZone === 'Alaska Daylight Time':
            result = 'AKDT'
            break
        default:
            result = getWordInitials(fullNameOfTimeZone)
            break
    }
    return result
}

export const isFutureRoom = (room: HotelRoomJSON, timeZone?: string | undefined) => {
    timeZone = timeZone || 'America/Los_Angeles'
    return (
        room.tiers[0]?.onSale === true &&
        room.tiers[0]?.onSaleDate !== undefined &&
        getTimeZone(room.tiers[0]?.onSaleDate, timeZone) > now(timeZone)
    )
}

export const allRoomIsFuture = (rooms: HotelRoomJSON[]) => {
    return rooms.every((room) => isFutureRoom(room))
}

import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js'
import { PaymentIntentResult } from '@stripe/stripe-js'
import { isEqual } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Address, getPaymentData } from '../store/actions/fetchCart'
import {
    acceptSplitPayInvite,
    AcceptSplitPayInviteParamsJSON,
    getSplitPayOrder,
    payInstallment,
    PayInstallmentJSON,
    TransactionDetailsParams
} from '../store/actions/fetchOrders'
import { CardDetailJSON, ShowStateType } from '../types/checkout'
import { CardJSON } from '../types/payments'
import { scrollToElement } from '../util/parse'
import {
    validateAddressForm,
    validateAddressInput,
    validateEmail,
    validateConfirmEmail,
    validateTravelGuardForm
} from '../util/validate'
import getConfig from 'next/config'
import { useBraintree } from 'hooks/useBraintree'
import { handlePaymentErrorMsgByBraintree } from 'enums/braintreeErrors'
const { publicRuntimeConfig } = getConfig()
import { useTravelGuard } from './useTravelGuard'
import { TravelGuardSelection } from 'enums'

export interface paySuccessCallbackJSON {
    form: {
        email: string
        subscribe: boolean
        shippingAddress: Address
        autoPush: boolean
        billingAddress: Address
        rememberMyInfo: boolean
    }
    paymentInfo: {
        brand: string
        braintreeCustomerId: string
        braintreePaymentMethodId: string
        braintreeTransactionId: string
        expiration: string
        last4: string
    }
}

const startCountries = ['US']

export const blankAddress: () => Address = () => ({
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    phone: '',
    phoneAuthorization: 'false'
})

const getBillingAddress = (authState: any): Address => {
    if (authState.user?.billingInfo?.billingAddress) {
        return {
            ...authState.user.billingInfo.billingAddress,
            country: authState.user.billingInfo.billingAddress.country || startCountries[0]
        }
    } else {
        const address = blankAddress()
        address.country = startCountries[0]
        return address
    }
}

const getShippingAddress = (authState: any): Address => {
    if (authState.user?.billingInfo?.shippingAddress) {
        return {
            ...authState.user.billingInfo.shippingAddress,
            country: authState.user.billingInfo.shippingAddress.country || startCountries[0]
        }
    } else {
        const address = blankAddress()
        address.country = startCountries[0]
        return address
    }
}

export function useCheckOut({
    amount,
    authState,
    canPurchaseInsurance = false,
    isBraintree,
    isSplitPayModal,
    layawayFee,
    orderId
}: {
    amount: number
    authState: any
    canPurchaseInsurance?: boolean
    isBraintree: boolean
    isSplitPayModal: boolean
    layawayFee: number
    orderId: string
}) {
    const isLogIn = !!authState.jwt
    const userInfo = authState.user

    // stripe
    const stripe = useStripe()
    const elements = useElements()
    // braintree
    const {
        braintreeFormError,
        braintreeFormErrors,
        getBraintreeClientToken,
        handleBraintreeHostedFieldsTokenize,
        handleBraintreePaymentRequest,
        handleCloseSnackbar,
        handleSetBraintreeDetails,
        handleValidateHostedFields,
        handleValidateBraintreeForm,
        hostedFieldsError,
        hostedFieldsInstance,
        isLoadingBraintreeForm,
        openSnackbar,
        setBraintreeFormError,
        setHostedFieldsInstance,
        setIsLoadingBraintreeForm,
        setVaultPaymentMethodID,
        vaultPaymentMethodID
    } = useBraintree()

    const {
        checkTravelGuardRequestParameters,
        debouncedSendTravelGuardQuoteRequest,
        getInsuranceDetails,
        hasFilledBasicInfo,
        isLoadingRequest,
        resetTravelGuardForm,
        setTravelGuardInfoErrors,
        travelGuardFormOnChange,
        travelGuardInfo,
        travelGuardInfoErrors,
        travelGuardPrice
    } = useTravelGuard({
        cartIdOrOrderId: orderId,
        isTravelGuardEnabled: canPurchaseInsurance,
        ordersUrl: publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!
    })

    // form data
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [shippingAddress, setShippingAddress] = useState<Address>(() =>
        getShippingAddress(authState)
    )
    const [cardDetail, setCardDetail] = useState<CardDetailJSON>({
        paymentMethodId: '',
        brand: { value: '', filled: false },
        last4: { value: '', filled: false },
        cvv: { value: '', filled: false },
        expiration: { value: '', filled: false }
    })
    const [billingAddress, setBillingAddress] = useState<Address>(() =>
        getBillingAddress(authState)
    )
    const [chooseSameAsShippingInfo, setChooseSameAsShippingInfo] = useState<boolean>(true)
    const [rememberMyInfo, setRememberMyInfo] = useState<boolean>(false)
    const [subscribe, setSubscribe] = useState<boolean>(false)
    const [autoPush, setAutoPush] = useState<boolean>(false)
    const [paymentOptionActiveState, setPaymentOptionActiveState] = useState({
        full: true,
        installment: false,
        splitPayment: false
    })
    const preBillingAddress = useRef<Address>()
    const preShippingAddress = useRef<Address>()

    // form show state
    const [shippingAddressShowState, setShippingAddressShowState] = useState<ShowStateType>({
        editText: false,
        form: false,
        saveButton: false,
        abbreviation: false
    })
    const [paymentInfoShowState, setPaymentInfoShowState] = useState<ShowStateType>({
        editText: false,
        form: false,
        saveButton: false,
        abbreviation: false
    })
    const [billingAddressShowState, setBillingAddressShowState] = useState<
        ShowStateType & { sameAs: boolean }
    >({
        editText: false,
        sameAs: false,
        form: false,
        saveButton: false,
        abbreviation: false
    })
    const [isPrivacyPolicyCheck, setIsPrivacyPolicyCheck] = useState<boolean>(false)

    // error
    const [emailError, setEmailError] = useState('')
    const [confirmEmailError, setConfirmEmailError] = useState('')
    const [shippingAddressError, setShippingAddressError] =
        useState<Record<keyof Address, string>>()
    const [cardInfoError, setCardInfoError] = useState<Omit<CardJSON, 'brand'>>({
        last4: '',
        cvv: '',
        expiration: ''
    })
    const [billingAddressError, setBillingAddressError] = useState<Record<keyof Address, string>>()
    const [paymentError, setPaymentError] = useState<string>()
    const prevPaymentError = useRef('')
    const [privacyPolicyCheckError, setPrivacyPolicyCheckError] = useState<string>('')

    // state
    const [isSavingCard, setIsSavingCard] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [stopCountDown, setStopCountDown] = useState<boolean>(false)
    const prevPaymentMethodID = useRef<string>()
    const [showEmailHint, setShowEmailHint] = useState<boolean>(false)

    let billingInfo = billingAddress
    let shippingInfo = shippingAddress
    if (chooseSameAsShippingInfo) {
        billingInfo = shippingInfo
    }

    useEffect(() => {
        if (hostedFieldsInstance) {
            const handleCardFill = (event: any) => {
                const creditCardIsEmpty = event.fields.number.isEmpty
                const expirationIsEmpty = event.fields.expirationDate.isEmpty
                const cvvIsEmpty = event.fields.cvv.isEmpty
                const brand = {
                    value: creditCardIsEmpty
                        ? cardDetail.brand.value
                        : event.cards[0]?.niceType || '',
                    filled: creditCardIsEmpty
                }
                const expiration = {
                    value: cardDetail.expiration.value,
                    filled: expirationIsEmpty
                }
                const cvv = {
                    value: cardDetail.cvv.value,
                    filled: cvvIsEmpty
                }
                setCardDetail({
                    ...cardDetail,
                    brand,
                    expiration,
                    cvv
                })
            }
            hostedFieldsInstance.on('empty', (event) => {
                handleCardFill(event)
            })
            hostedFieldsInstance.on('notEmpty', (event) => {
                handleCardFill(event)
            })
        }
    }, [hostedFieldsInstance])

    useEffect(() => {
        const ba = getBillingAddress(authState)
        const sa = getShippingAddress(authState)
        let asShippingAddress: boolean = true
        let newCardDetail: CardDetailJSON = {
            paymentMethodId: '',
            brand: { value: '', filled: false },
            last4: { value: '', filled: false },
            cvv: { value: '', filled: false },
            expiration: { value: '', filled: false }
        }
        let newShippingAddressShowState: ShowStateType = {
            editText: false,
            form: true,
            saveButton: false,
            abbreviation: false
        }
        let newPaymentInfoShowState: ShowStateType = {
            editText: false,
            form: true,
            saveButton: false,
            abbreviation: false
        }
        let newBillingAddressShowState: ShowStateType & { sameAs: boolean } = {
            editText: false,
            sameAs: true,
            form: false,
            saveButton: false,
            abbreviation: false
        }

        if (!isLogIn) {
            if (stripe && elements && !isBraintree) {
                const cardNumber = elements.getElement(CardNumberElement)
                const cardExpiration = elements.getElement(CardExpiryElement)
                const cardCvv = elements.getElement(CardCvcElement)
                cardNumber?.clear()
                cardExpiration?.clear()
                cardCvv?.clear()
            }
        } else {
            if (isBraintree) {
                const braintreePaymentInfo = authState.user?.braintreePaymentInfo
                if (braintreePaymentInfo && braintreePaymentInfo.braintreePaymentMethodId) {
                    newPaymentInfoShowState = {
                        editText: true,
                        form: false,
                        saveButton: false,
                        abbreviation: true
                    }
                    const expiration = braintreePaymentInfo.expiration?.split('/') || ''
                    newCardDetail = {
                        paymentMethodId: braintreePaymentInfo.braintreePaymentMethodId || '',
                        brand: {
                            value: braintreePaymentInfo.brand || '',
                            filled: !!braintreePaymentInfo.brand
                        },
                        last4: {
                            value: braintreePaymentInfo.last4 || '',
                            filled: !!braintreePaymentInfo.last4
                        },
                        cvv: {
                            value: braintreePaymentInfo.braintreePaymentMethodId ? 'xxx' : '',
                            filled: !!braintreePaymentInfo.braintreePaymentMethodId
                        },
                        expiration: {
                            value:
                                expiration.length > 1
                                    ? expiration[0].padStart(2, '0') +
                                      ' / ' +
                                      expiration[1].slice(-2)
                                    : '',
                            filled: !!braintreePaymentInfo?.braintreePaymentMethodId
                        }
                    }
                }
            }

            const shippingAddressIsNotEmpty = Object.entries(sa).some(
                ([k, v]) => v && v !== 'false' && v !== 'US' && k !== 'country'
            )
            const billingAddressIsNotEmpty = Object.entries(ba).some(
                ([k, v]) => v && v !== 'false' && v !== 'US' && k !== 'country'
            )
            if (shippingAddressIsNotEmpty) {
                newShippingAddressShowState = {
                    editText: true,
                    form: false,
                    saveButton: false,
                    abbreviation: true
                }
            }
            switch (true) {
                case shippingAddressIsNotEmpty && !billingAddressIsNotEmpty:
                    asShippingAddress = false
                    newBillingAddressShowState = {
                        editText: false,
                        sameAs: true,
                        form: true,
                        saveButton: false,
                        abbreviation: false
                    }
                    break
                case !shippingAddressIsNotEmpty && billingAddressIsNotEmpty:
                    asShippingAddress = false
                    newBillingAddressShowState = {
                        editText: true,
                        sameAs: false,
                        form: false,
                        saveButton: false,
                        abbreviation: true
                    }
                    break
                case shippingAddressIsNotEmpty && billingAddressIsNotEmpty:
                    let k: keyof Address
                    for (k in ba) {
                        if (sa[k] !== ba[k]) {
                            asShippingAddress = false
                            newBillingAddressShowState = {
                                editText: true,
                                sameAs: false,
                                form: false,
                                saveButton: false,
                                abbreviation: true
                            }
                            break
                        }
                    }
                    break
                default:
                    break
            }
        }

        if (!newPaymentInfoShowState.form || !isBraintree) {
            setIsLoadingBraintreeForm(false)
        }
        setBillingAddress(ba)
        setShippingAddress({ ...sa, phoneAuthorization: autoPush.toString() })
        setCardDetail(newCardDetail)
        setChooseSameAsShippingInfo(asShippingAddress)
        setShippingAddressShowState(newShippingAddressShowState)
        setPaymentInfoShowState(newPaymentInfoShowState)
        setBillingAddressShowState(newBillingAddressShowState)

        setEmailError('')
        setShippingAddressError(undefined)
        setCardInfoError({
            last4: '',
            cvv: '',
            expiration: ''
        })
        setBillingAddressError(undefined)
        setPaymentError('')

        preShippingAddress.current = shippingAddress
        preBillingAddress.current = billingAddress
    }, [isLogIn])

    useEffect(() => {
        if (travelGuardInfo.selected === '') return
        const braintreePaymentInfo = authState.user?.braintreePaymentInfo
        if (braintreePaymentInfo && braintreePaymentInfo.braintreePaymentMethodId) {
            setPaymentInfoShowState({
                editText: false,
                form: true,
                saveButton: false,
                abbreviation: false
            })
        }
    }, [travelGuardInfo.selected])

    // event
    const handleEmailChange = (e: any) => {
        const value = e.target.value.trim()
        setEmail(value)
        const msg = validateEmail(value)
        setEmailError(msg)
    }

    const handleConfirmEmailChange = (e: any) => {
        const value = e.target.value.trim()
        setConfirmEmail(value)
        const msg = validateConfirmEmail(value, email)
        setConfirmEmailError(msg)
    }

    const handleEmailBlur = (e: any) => {
        const msg = validateEmail(e.target.value)
        setEmailError(msg)
    }

    const handleConfirmEmailBlur = (e: any) => {
        const msg = validateConfirmEmail(e.target.value, email)
        setConfirmEmailError(msg)
    }

    const handlePaymentMethodError = (error: { code: string; message: string }) => {
        if (
            error.code === 'expired_card' ||
            error.code === 'incomplete_expiry' ||
            error.code === 'invalid_expiry_year_past' ||
            error.code === 'invalid_expiry_month_past'
        ) {
            setCardInfoError({ ...cardInfoError, expiration: error.message })
        } else if (error.code === 'incorrect_cvc' || error.code === 'incomplete_cvc') {
            setCardInfoError({ ...cardInfoError, cvv: error.message })
        } else {
            setCardInfoError({ ...cardInfoError, last4: error.message })
        }
    }

    const handlePrivacyPolicyCheckOnChange = () => {
        setIsPrivacyPolicyCheck(!isPrivacyPolicyCheck)
        if (privacyPolicyCheckError && !isPrivacyPolicyCheck) {
            setPrivacyPolicyCheckError('')
        }
    }

    const saveShippingAddress = () => {
        const shippingErrorMsg = validateAddressForm(shippingAddress)
        const shippingFormIsNotValid = Object.entries(shippingErrorMsg).find(([_, v]) => v !== '')
        setShippingAddressError({ ...shippingErrorMsg })
        const isSaveSuccessful = !shippingFormIsNotValid
        if (isSaveSuccessful) {
            setShippingAddressShowState({
                editText: true,
                form: false,
                saveButton: false,
                abbreviation: true
            })
        }
        preShippingAddress.current = shippingAddress
        return isSaveSuccessful
    }
    const handleBraintreePaymentRequestError = () => {
        setStopCountDown(false)
        setSubmitting(false)
        scrollToElement('payment-info')
    }

    const saveCardInfo = async () => {
        if (!hostedFieldsInstance || !authState.user?.braintreePaymentInfo) {
            return
        }
        const cardFill = {
            brand: cardDetail.brand.filled,
            cvv: cardDetail.cvv.filled,
            expiration: cardDetail.expiration.filled
        }

        let createdSuccessfully = false
        const cardBrand = cardDetail.brand.value || ''
        const cardIsUnion = cardBrand === 'UnionPay'
        const fillCardIsNotExist = Object.entries(cardFill).some(([_, v]) => !v)

        if (fillCardIsNotExist) {
            try {
                const { cardDetails, paymentRequestToken } = await handleBraintreePaymentRequest(
                    amount,
                    billingInfo,
                    email,
                    handleBraintreePaymentRequestError
                )
                if (cardDetails === undefined || paymentRequestToken === '') {
                    return
                }
                const expiration =
                    cardDetails.expMonth && cardDetails.expYear
                        ? `${cardDetails.expMonth}/${cardDetails.expYear.slice(-2)}`
                        : ''
                const brand = cardIsUnion ? cardBrand : cardDetails.brand || ''
                const newCardDetail = {
                    paymentMethodId: paymentRequestToken,
                    brand: { value: brand, filled: true },
                    last4: { value: cardDetails.last4 || '', filled: true },
                    cvv: { value: cardDetails.last4 ? 'xxx' : '', filled: true },
                    expiration: { value: expiration, filled: true }
                }
                createdSuccessfully = true
                if (cardDetail.paymentMethodId !== paymentRequestToken) {
                    setVaultPaymentMethodID('')
                }
                setCardDetail(newCardDetail)
            } catch {}
        } else {
            createdSuccessfully = true
        }
        setPaymentInfoShowState({
            editText: createdSuccessfully ? true : false,
            form: createdSuccessfully ? false : true,
            saveButton: createdSuccessfully ? false : true,
            abbreviation: createdSuccessfully ? true : false
        })
        setIsSavingCard(false)
        return createdSuccessfully
    }

    const saveBillingAddress = () => {
        const billingErrorMsg = validateAddressForm(billingAddress)
        const billingFormIsNotValid = Object.entries(billingErrorMsg).some(([_, v]) => v !== '')
        setBillingAddressError(billingErrorMsg)
        const isSaveSuccessful = !billingFormIsNotValid
        if (isSaveSuccessful) {
            setBillingAddressShowState({
                editText: true,
                sameAs: false,
                form: false,
                saveButton: false,
                abbreviation: true
            })
        }
        preBillingAddress.current = billingAddress
        return isSaveSuccessful
    }

    const handleShippingAddressEdit = async () => {
        if (paymentInfoShowState.form && paymentInfoShowState.saveButton) {
            const createdSuccessfully = await saveCardInfo()
            if (!createdSuccessfully) {
                scrollToElement('payment-info')
                return
            }
        }

        if (billingAddressShowState.form && billingAddressShowState.saveButton) {
            const isSaveSuccessful = saveBillingAddress()
            if (!isSaveSuccessful) {
                scrollToElement('billing-address')
                return
            }
        }

        setShippingAddressShowState({
            editText: false,
            form: true,
            saveButton: true,
            abbreviation: false
        })
    }

    const handlePaymentInfoEdit = () => {
        if (shippingAddressShowState.form && shippingAddressShowState.saveButton) {
            const isSaveSuccessful = saveShippingAddress()
            if (!isSaveSuccessful) {
                scrollToElement('shipping-address')
                return
            }
        }

        if (billingAddressShowState.form && billingAddressShowState.saveButton) {
            const isSaveSuccessful = saveBillingAddress()
            if (!isSaveSuccessful) {
                scrollToElement('billing-address')
                return
            }
        }
        setHostedFieldsInstance(undefined)
        setIsLoadingBraintreeForm(true)
        setPaymentInfoShowState({
            editText: false,
            form: true,
            saveButton: true,
            abbreviation: false
        })
    }

    const handlePaymentInfoChange = (k: keyof Omit<CardJSON, 'brand'>, e: any) => {
        setCardInfoError({
            ...cardInfoError,
            [k]: e.error?.message || ''
        })
        setCardDetail({
            ...cardDetail,
            [k]: {
                value: cardDetail[k].value,
                filled: e.empty
            }
        })
    }

    const handleBillingAddressEdit = async () => {
        if (shippingAddressShowState.form && shippingAddressShowState.saveButton) {
            const isSaveSuccessful = saveShippingAddress()
            if (!isSaveSuccessful) {
                scrollToElement('shipping-address')
                return
            }
        }

        if (paymentInfoShowState.form && paymentInfoShowState.saveButton) {
            const createdSuccessfully = await saveCardInfo()
            if (!createdSuccessfully) {
                scrollToElement('payment-info')
                return
            }
        }
        setChooseSameAsShippingInfo(false)
        setBillingAddressShowState({
            editText: false,
            sameAs: true,
            form: true,
            saveButton: true,
            abbreviation: false
        })
    }

    const handleChooseSameAsShippingInfoChange = async (e: any) => {
        setChooseSameAsShippingInfo(e.target.checked)
        let newBillingAddressShowState = { ...billingAddressShowState }
        if (e.target.checked) {
            newBillingAddressShowState = {
                editText: false,
                sameAs: true,
                form: false,
                saveButton: false,
                abbreviation: false
            }
        } else {
            const ba = getBillingAddress(authState)
            const billingAddressIsNotEmpty = Object.entries(ba).some(
                ([k, v]) => v && v !== 'US' && k !== 'country'
            )
            let showSaveButton = false

            if (billingAddressIsNotEmpty) {
                showSaveButton = true
            }

            if (paymentInfoShowState.form && paymentInfoShowState.saveButton) {
                const createdSuccessfully = await saveCardInfo()
                if (!createdSuccessfully) {
                    scrollToElement('payment-info')
                    return
                }
            }
            if (shippingAddressShowState.form && shippingAddressShowState.saveButton) {
                const isSaveSuccessful = saveShippingAddress()
                if (!isSaveSuccessful) {
                    scrollToElement('shipping-address')
                    return
                }
            }

            newBillingAddressShowState = {
                editText: false,
                sameAs: true,
                form: true,
                saveButton: showSaveButton,
                abbreviation: false
            }
        }
        setBillingAddressShowState(newBillingAddressShowState)
    }

    const handleShippingAddressChange = (address: Address) => {
        setShippingAddress({ ...address, phoneAuthorization: autoPush.toString() })
    }

    const handleShippingAddressBlur = (k: keyof Address) => {
        const error = validateAddressInput(shippingAddress, k)
        setShippingAddressError({ ...shippingAddressError, ...error })
    }

    const handleBillingAddressChange = (address: Address) => {
        setBillingAddress(address)
    }

    const handleBillingAddressBlur = (k: keyof Address) => {
        const error = validateAddressInput(billingAddress, k)
        setBillingAddressError({ ...billingAddressError, ...error })
    }

    const handleRememberMyInfoChange = (e: any) => {
        setRememberMyInfo(e.target.checked)
    }

    const handleSubscribeChange = (e: any) => {
        setSubscribe(e.target.checked)
    }

    const handleAutoPushChange = (e: any) => {
        setAutoPush(e.target.checked)
        setShippingAddress({ ...shippingAddress, phoneAuthorization: e.target.checked.toString() })
    }

    const updatePaymentOptionActiveState = ({
        full,
        installment,
        splitPayment
    }: {
        full: boolean
        installment: boolean
        splitPayment: boolean
    }) => {
        setPaymentOptionActiveState({ full, installment, splitPayment })
    }

    const validateTravelGuard = (isVerified: boolean, scrollTo: string) => {
        if (
            isSplitPayModal ||
            !canPurchaseInsurance ||
            shippingAddress.country !== startCountries[0]
        ) {
            return { isVerified, scrollTo }
        }
        const travelGuardErrorMsg = validateTravelGuardForm(travelGuardInfo!, false)
        const isTravelGuardFormValid = Object.entries(travelGuardErrorMsg).every(
            ([_, value]) => value === ''
        )
        if (!isTravelGuardFormValid) {
            setTravelGuardInfoErrors!(travelGuardErrorMsg)
            scrollTo = scrollTo || 'insurance-form'
            isVerified = false
        }
        return { isVerified, scrollTo }
    }

    const validateCheckOutForm = async (verifyMemberEmail?: boolean, memberEmail?: string) => {
        let isVerified = true
        let scrollTo = ''
        let paymentErrorMsg = ''
        let card = {
            paymentMethodId: cardDetail.paymentMethodId,
            brand: cardDetail.brand.value,
            last4: cardDetail.last4.value,
            cvv: cardDetail.cvv.value,
            expiration: cardDetail.expiration.value
        }

        if (!isPrivacyPolicyCheck && !isSplitPayModal) {
            setPrivacyPolicyCheckError('This field is required.')
            isVerified = false
        }

        const emailErrorMsg = validateEmail(email) // validate the input email format
        const confirmEmailErrorMsg = validateEmail(email)
        const emailIsNotValid = isLogIn ? false : emailErrorMsg !== ''
        const confirmEmailIsNotValid = isLogIn ? false : confirmEmailErrorMsg !== ''

        if (emailIsNotValid || confirmEmailIsNotValid) {
            setEmailError(emailErrorMsg)
            setConfirmEmailError(confirmEmailErrorMsg)
            scrollTo = scrollTo || 'email'
            isVerified = false
        }

        // Verify email on the invite submit page.
        if (verifyMemberEmail) {
            if ((userInfo?.email || email).toLowerCase() !== memberEmail!.toLowerCase()) {
                if (isLogIn) {
                    showHintOfEmail()
                } else {
                    setEmailError('please enter the correct email address.')
                }
                scrollTo = scrollTo || 'email'
                isVerified = false
            }
        }

        if (shippingAddressShowState.saveButton) {
            const shippingFormIsNotValid = Object.entries(shippingAddressError || {}).some(
                ([_, v]) => v !== ''
            )
            if (shippingFormIsNotValid) {
                setShippingAddressShowState({
                    editText: false,
                    form: true,
                    saveButton: true,
                    abbreviation: false
                })
                scrollTo = scrollTo || 'emailError'
                isVerified = false
            } else if (isEqual(shippingAddress, preShippingAddress.current)) {
                setShippingAddressShowState({
                    editText: true,
                    form: false,
                    saveButton: false,
                    abbreviation: true
                })
            } else {
                paymentErrorMsg = paymentErrorMsg || 'Please confirm your new shipping information.'
                scrollTo = scrollTo || 'shipping-address'
                isVerified = false
            }
        } else {
            const shippingErrorMsg = validateAddressForm(shippingAddress)
            const shippingFormIsNotValid = Object.entries(shippingErrorMsg).some(
                ([_, v]) => v !== ''
            )
            setShippingAddressError(shippingErrorMsg)
            if (shippingFormIsNotValid) {
                const userHaveShippingAddress = userInfo?.billingInfo?.shippingAddress
                    ? true
                    : false
                if (userHaveShippingAddress) {
                    setShippingAddressShowState({
                        editText: false,
                        form: true,
                        saveButton: true,
                        abbreviation: false
                    })
                }
                scrollTo = scrollTo || 'shipping-address'
                isVerified = false
            }
        }

        if (isBraintree) {
            if (paymentInfoShowState.saveButton) {
                const cardFill = {
                    last4: cardDetail.last4.filled,
                    cvv: cardDetail.cvv.filled,
                    expiration: cardDetail.expiration.filled
                }
                const fillCardIsNotExist = Object.entries(cardFill).some(([_, v]) => !v)
                if (fillCardIsNotExist) {
                    paymentErrorMsg = paymentErrorMsg || 'Please confirm your new payment method.'
                    scrollTo = scrollTo || 'payment-info'
                    isVerified = false
                } else {
                    setPaymentInfoShowState({
                        editText: true,
                        form: false,
                        saveButton: false,
                        abbreviation: true
                    })
                }
            }
        } else {
            if (!stripe || !elements) {
                paymentErrorMsg = paymentErrorMsg || 'Something went wrong. Please try again later.'
                scrollTo = scrollTo || 'payment-info'
                isVerified = false
            } else {
                const fillcard = {
                    last4: cardDetail.last4.filled,
                    cvv: cardDetail.cvv.filled,
                    expiration: cardDetail.expiration.filled
                }
                const fillCardIsNotExist = Object.entries(fillcard).some(([_, v]) => !v)
                if (fillCardIsNotExist) {
                    const result = await createPaymentMethod(userInfo?.email || email, billingInfo)
                    if (result.error) {
                        handlePaymentMethodError({
                            code: result.error?.code || '',
                            message: result.error?.message || ''
                        })
                        scrollTo = scrollTo || 'payment-info'
                        isVerified = false
                    } else {
                        const expMonth = result?.paymentMethod?.card?.exp_month
                            ?.toString()
                            .padStart(2, '0') // exp_month, eg: 4
                        const expYear = result?.paymentMethod?.card?.exp_year?.toString().slice(-2) // exp_year, eg: 2022
                        card = {
                            paymentMethodId: result.paymentMethod.id,
                            brand: result.paymentMethod.card?.brand || '',
                            last4: result.paymentMethod.card?.last4 || '',
                            cvv: 'xxx',
                            expiration: expMonth + ' / ' + expYear // eg: 04 / 22,
                        }
                    }
                }
            }
        }

        // validate TravelGuardForm
        const validateTravelGuardResult = validateTravelGuard(isVerified, scrollTo)
        isVerified = validateTravelGuardResult.isVerified
        scrollTo = validateTravelGuardResult.scrollTo

        if (billingAddressShowState.saveButton) {
            const billingFormIsNotValid = Object.entries(billingAddressError || {}).some(
                ([_, v]) => v !== ''
            )
            if (billingFormIsNotValid) {
                setBillingAddressShowState({
                    editText: false,
                    sameAs: false,
                    form: true,
                    saveButton: true,
                    abbreviation: false
                })
                scrollTo = scrollTo || 'billing-address'
                isVerified = false
            } else if (isEqual(billingAddress, preBillingAddress.current)) {
                setBillingAddressShowState({
                    editText: true,
                    sameAs: false,
                    form: false,
                    saveButton: false,
                    abbreviation: true
                })
            } else {
                paymentErrorMsg = paymentErrorMsg || 'Please confirm your new billing information.'
                scrollTo = scrollTo || 'billing-address'
                isVerified = false
            }
        } else {
            if (!chooseSameAsShippingInfo) {
                const billingErrorMsg = validateAddressForm(billingAddress)
                const billingFormIsNotValid = Object.entries(billingErrorMsg).some(
                    ([_, v]) => v !== ''
                )
                setBillingAddressError({ ...billingErrorMsg })
                if (billingFormIsNotValid) {
                    const userHaveBillingAddress = userInfo?.billingInfo?.billingAddress
                        ? true
                        : false
                    if (userHaveBillingAddress) {
                        setBillingAddressShowState({
                            editText: false,
                            sameAs: false,
                            form: true,
                            saveButton: true,
                            abbreviation: false
                        })
                    }
                    scrollTo = scrollTo || 'billing-address'
                    isVerified = false
                }
            }
        }
        scrollTo && scrollToElement(scrollTo)

        return [isVerified, paymentErrorMsg, card] as const
    }

    const getBraintreeTransactionDetails = async () => {
        let braintreeTransactionDetails = {
            braintreeCustomerId: '',
            braintreePaymentMethodId: '',
            braintreeTransactionId: '',
            shouldVaultPayment: true,
            usage: 'SINGLE_USE' as 'MULTI_USE' | 'SINGLE_USE'
        }
        if (authState.user.braintreePaymentInfo && travelGuardInfo.selected === '') {
            switch (true) {
                case cardDetail.paymentMethodId ===
                    authState.user.braintreePaymentInfo.braintreePaymentMethodId:
                    braintreeTransactionDetails = {
                        braintreeCustomerId:
                            authState.user.braintreePaymentInfo.braintreeCustomerId,
                        braintreePaymentMethodId: cardDetail.paymentMethodId,
                        braintreeTransactionId:
                            authState.user.braintreePaymentInfo.braintreeTransactionId,
                        shouldVaultPayment: false,
                        usage: 'MULTI_USE'
                    }
                    break
                case vaultPaymentMethodID === '':
                    braintreeTransactionDetails = {
                        ...braintreeTransactionDetails,
                        braintreePaymentMethodId: cardDetail.paymentMethodId
                    }
                    break
                default:
                    braintreeTransactionDetails = {
                        ...braintreeTransactionDetails,
                        braintreePaymentMethodId: vaultPaymentMethodID,
                        shouldVaultPayment: false,
                        usage: 'MULTI_USE'
                    }
                    break
            }
        } else {
            try {
                const { cardDetails, paymentRequestToken } = await handleBraintreePaymentRequest(
                    amount,
                    billingInfo,
                    email,
                    handleBraintreePaymentRequestError
                )
                if (cardDetails === undefined || paymentRequestToken === '') {
                    return braintreeTransactionDetails
                }
                braintreeTransactionDetails = {
                    ...braintreeTransactionDetails,
                    braintreePaymentMethodId: paymentRequestToken
                }
                if (
                    cardDetail.paymentMethodId === paymentRequestToken &&
                    vaultPaymentMethodID !== ''
                ) {
                    braintreeTransactionDetails = {
                        ...braintreeTransactionDetails,
                        braintreePaymentMethodId: cardDetail.paymentMethodId,
                        shouldVaultPayment: false,
                        usage: 'MULTI_USE'
                    }
                }
                const expiration =
                    cardDetails.expMonth && cardDetails.expYear
                        ? `${cardDetails.expMonth}/${cardDetails.expYear.slice(-2)}`
                        : ''
                const newCardDetail = {
                    paymentMethodId: paymentRequestToken,
                    brand: { value: cardDetails.brand || '', filled: true },
                    last4: { value: cardDetails?.last4 || '', filled: true },
                    cvv: { value: cardDetails?.last4 ? 'xxx' : '', filled: true },
                    expiration: { value: expiration, filled: true }
                }
                setCardDetail(newCardDetail)
            } catch {}
        }
        return braintreeTransactionDetails
    }

    const handleGetInsuranceDetails = async () => {
        if (
            isSplitPayModal ||
            !canPurchaseInsurance ||
            shippingAddress.country !== startCountries[0]
        ) {
            return null
        }
        const coveredAmount = formData.paymentOptionActiveState.installment
            ? amount + layawayFee
            : amount

        let cvvPaymentMethodId = null
        if (travelGuardInfo.selected === TravelGuardSelection.YES) {
            cvvPaymentMethodId = await handleBraintreeHostedFieldsTokenize()
            if (!cvvPaymentMethodId) {
                handleBraintreePaymentRequestError()
                return null
            }
        }
        return getInsuranceDetails(cvvPaymentMethodId, shippingAddress.country, coveredAmount)
    }

    const handleSplitPayInviteSubmit = async (
        orderID: string,
        myselfMemberID: string,
        friendMemberID: string,
        friendMemberEmail: string,
        layaway: boolean,
        eventName: string,
        verifyFriendMemberEmail: boolean,
        successCallback?: (data: paySuccessCallbackJSON) => void,
        failureCallback?: () => void
    ) => {
        if (isLoadingBraintreeForm || submitting) return
        startSubmitting()

        const [verified, paymentErrorMsg, card] = await validateCheckOutForm(
            verifyFriendMemberEmail,
            friendMemberEmail
        )
        if (!verified) {
            setTimeout(() => {
                stopSubmitting(paymentErrorMsg, card.paymentMethodId)
            }, 300)
            return
        }

        const stripePaymentMethodId = card.paymentMethodId

        const orderResponse = await getSplitPayOrder(
            publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
            orderID,
            friendMemberID,
            userInfo?.billingInfo?.stripeCustomerID,
            stripePaymentMethodId
        )

        if (
            !orderResponse.paymentIntent &&
            orderResponse.transactionDetails?.paymentProcessorName == 'stripe'
        ) {
            let msg = getErrorMsg(orderResponse)
            if (
                msg ===
                'The provided PaymentMethod was previously used with a PaymentIntent without Customer attachment, shared with a connected account without Customer attachment, or was detached from a Customer. It may not be used again. To use a PaymentMethod multiple times, you must attach it to a Customer first.'
            ) {
                msg = 'Your card was declined.'
            }
            if (msg) {
                stopSubmitting(msg, stripePaymentMethodId)
            }
            return
        }
        const stripeCustomerId = orderResponse.paymentIntent
            ? orderResponse.paymentIntent.stripeCustomerID
            : ''
        const stripePaymentIntentId = orderResponse.paymentIntent
            ? orderResponse.paymentIntent.stripePaymentIntentID
            : ''
        let stripeClientSecret = orderResponse.paymentIntent
            ? orderResponse.paymentIntent.stripeClientSecret
            : ''

        const payInviteByStripe = async (stripePaymentIntentId: string) => {
            const transactionDetails: TransactionDetailsParams = {
                stripePaymentIntentId: stripePaymentIntentId,
                stripePaymentMethodId: stripePaymentMethodId,
                stripeCustomerId: stripeCustomerId,

                // Braintree
                braintreeTransactionId: '',
                braintreePaymentMethodId: '',
                braintreeCustomerId: '',
                shouldVaultPayment: true,
                usage: 'SINGLE_USE'
            }
            const params: AcceptSplitPayInviteParamsJSON = {
                amount,
                layaway,
                billingInfo: billingInfo,
                insuranceDetails: null, // Will not purchase insurance by Stripe
                shippingInfo: shippingInfo,
                transactionDetails
            }
            if (userInfo?.id) {
                params.userId = userInfo.id
            }
            if (myselfMemberID !== friendMemberID) {
                // pay for friend
                params.paidForBy = myselfMemberID
            }
            params.description = eventName

            let response: Record<string, any> = {}
            try {
                response = await acceptSplitPayInvite(
                    publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
                    orderID,
                    friendMemberID,
                    params
                )
            } catch (error) {}

            if (response.status === 'ok') {
                successCallback?.({
                    form: {
                        subscribe,
                        rememberMyInfo,
                        autoPush,
                        email: (userInfo?.email || email).toLowerCase(),
                        shippingAddress: params.shippingInfo,
                        billingAddress: params.billingInfo
                    },
                    paymentInfo: {
                        brand: card.brand,
                        braintreeCustomerId: '',
                        braintreePaymentMethodId: '',
                        braintreeTransactionId: '',
                        expiration: card.expiration,
                        last4: card.last4
                    }
                })
                return
            }
            let msg = getErrorMsg(response)
            if (msg.includes('requires_action')) {
                try {
                    stripeClientSecret = JSON.parse(response.message).client_secret
                    const result: PaymentIntentResult = await stripe!.handleCardAction(
                        stripeClientSecret
                    )
                    console.log({ result })
                    if (result.error) {
                        msg = result.error.message || 'Payment failed.'
                    } else {
                        // The card action has been handled
                        // The PaymentIntent can be confirmed again on the server
                        console.log('pay again.')
                        // if (result.paymentIntent.status === 'requires_confirmation') {
                        //   // payInvite({ ...params, paymentIntentID: result.paymentIntent!.id || '' });
                        //   stripe?.confirmCardPayment(
                        //     params.stripeClientSecret,
                        //     { payment_method: params.paymentMethodID }
                        //   )
                        // }
                        const newStripePaymentIntentId = result.paymentIntent!.id || ''
                        payInviteByStripe(newStripePaymentIntentId)
                        return
                    }
                } catch (error) {
                    msg = 'Payment failed.'
                }
            }
            stopSubmitting(
                msg || 'Payment failed.',
                params.transactionDetails.stripePaymentMethodId
            )
            failureCallback?.()
        }

        const payInviteByBrainTree = async () => {
            let braintreeTransactionDetails = await getBraintreeTransactionDetails()
            if (braintreeTransactionDetails.braintreePaymentMethodId === '') {
                return
            }
            const transactionDetails: TransactionDetailsParams = {
                stripePaymentIntentId: '',
                stripePaymentMethodId: '',
                stripeCustomerId: '',

                // Braintree
                ...braintreeTransactionDetails
            }
            // handle travel guard
            const insuranceDetails = await handleGetInsuranceDetails()

            const params: AcceptSplitPayInviteParamsJSON = {
                amount,
                layaway,
                billingInfo: billingInfo,
                insuranceDetails: insuranceDetails,
                shippingInfo: shippingInfo,
                transactionDetails
            }
            if (userInfo?.id) {
                params.userId = userInfo.id
            }
            if (myselfMemberID !== friendMemberID) {
                // pay for friend
                params.paidForBy = myselfMemberID
            }
            params.description = eventName
            try {
                const response = await acceptSplitPayInvite(
                    publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
                    orderID,
                    friendMemberID,
                    params
                )
                if (response.paymentMethodID && response.authorizationProcessorResponse) {
                    setVaultPaymentMethodID(response.paymentMethodID)
                    setPaymentError(handlePaymentErrorMsgByBraintree(response.message))
                    setSubmitting(false)
                    return
                }
                if (response.status === 'ok') {
                    const orderResponse = await getSplitPayOrder(
                        publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
                        orderID,
                        friendMemberID,
                        userInfo?.billingInfo?.stripeCustomerID,
                        cardDetail.paymentMethodId
                    )
                    const member = orderResponse.order?.members.find((member) => {
                        return friendMemberID === member.ID
                    })
                    const paymentInfo = {
                        brand: member!.TransactionDetails.cardDetails.brand || '',
                        braintreeCustomerId: member!.TransactionDetails.braintreeCustomerId,
                        braintreePaymentMethodId:
                            member!.TransactionDetails.braintreePaymentMethodId,
                        braintreeTransactionId: member!.TransactionDetails.braintreeTransactionId,
                        expiration:
                            member!.TransactionDetails.cardDetails.expMonth +
                            '/' +
                            member!.TransactionDetails.cardDetails.expYear,
                        last4: member!.TransactionDetails.cardDetails.last4
                    }
                    successCallback?.({
                        form: {
                            subscribe,
                            rememberMyInfo,
                            autoPush,
                            email: userInfo?.email || email,
                            shippingAddress: params.shippingInfo,
                            billingAddress: params.billingInfo
                        },
                        paymentInfo
                    })
                    return
                }
            } catch (error: any) {
                setPaymentError(handlePaymentErrorMsgByBraintree(error.message))
                setSubmitting(false)
            }
        }
        if (isBraintree) {
            payInviteByBrainTree()
        } else {
            payInviteByStripe(stripePaymentIntentId)
        }
    }

    const handlePayInstallmentsSubmit = async (
        orderID: string,
        myselfMemberID: string,
        friendMemberID: string,
        friendMemberEmail: string,
        amount: number,
        checkDesiredStatuses: () => Promise<string>,
        successCallback?: (data: paySuccessCallbackJSON) => void,
        failureCallback?: () => void
    ) => {
        if (isLoadingBraintreeForm || submitting) return
        startSubmitting()

        const amountError: boolean = amount < 0
        if (amountError) {
            const amountErrorMsg = 'Charge Amount cannot be negative.'
            setTimeout(() => {
                stopSubmitting(amountErrorMsg, cardDetail.paymentMethodId)
            }, 300)
            return
        }

        const [verified, paymentErrorMsg, card] = await validateCheckOutForm()
        if (!verified) {
            setTimeout(() => {
                stopSubmitting(paymentErrorMsg, card.paymentMethodId)
            }, 300)
            return
        }

        let errorMsg = ''
        try {
            errorMsg = await checkDesiredStatuses()
        } catch (error) {
            errorMsg = 'Server error.'
        }
        if (errorMsg) {
            setTimeout(() => {
                stopSubmitting(errorMsg, card.paymentMethodId)
            }, 300)
            return
        }

        const stripePaymentMethodId = card.paymentMethodId

        const orderResponse = await getSplitPayOrder(
            publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
            orderID,
            friendMemberID,
            userInfo?.billingInfo?.stripeCustomerID,
            stripePaymentMethodId
        )
        if (
            !orderResponse.paymentIntent &&
            orderResponse.transactionDetails?.paymentProcessorName == 'stripe'
        ) {
            let msg = getErrorMsg(orderResponse)
            if (
                msg ===
                'The provided PaymentMethod was previously used with a PaymentIntent without Customer attachment, shared with a connected account without Customer attachment, or was detached from a Customer. It may not be used again. To use a PaymentMethod multiple times, you must attach it to a Customer first.'
            ) {
                msg = 'Your card was declined.'
            }
            if (msg) {
                stopSubmitting(msg, stripePaymentMethodId)
            }
            return
        }
        const stripeCustomerId = orderResponse.paymentIntent
            ? orderResponse.paymentIntent.stripeCustomerID
            : ''
        const stripePaymentIntentId = orderResponse.paymentIntent
            ? orderResponse.paymentIntent.stripePaymentIntentID
            : ''
        let stripeClientSecret = orderResponse.paymentIntent
            ? orderResponse.paymentIntent.stripeClientSecret
            : ''

        const payAnInstallmentOrPayRemainingInstallmentByStripe = async () => {
            const transactionDetails: TransactionDetailsParams = {
                stripePaymentIntentId,
                stripePaymentMethodId,
                stripeCustomerId,

                // Braintree
                braintreeTransactionId: '',
                braintreePaymentMethodId: '',
                braintreeCustomerId: '',
                shouldVaultPayment: true,
                usage: 'SINGLE_USE'
            }
            const params: PayInstallmentJSON = {
                amount,
                shippingInfo,
                billingInfo,
                useFuture: true,
                transactionDetails,
                payForMemberEmail: friendMemberEmail
            }
            if (myselfMemberID !== friendMemberID) {
                // pay for friend
                params.payFor = friendMemberID
            }
            let response: Record<string, any> = {}
            try {
                response = await payInstallment(
                    publicRuntimeConfig.NEXT_PUBLIC_PAYMENTS_URL!,
                    `/v2/payment-plan/${orderID}/${myselfMemberID}/pay-now`,
                    params
                )
            } catch (error) {}

            if (response.status === 'ok') {
                successCallback?.({
                    form: {
                        subscribe,
                        rememberMyInfo,
                        autoPush,
                        email: userInfo?.email || '',
                        shippingAddress: params.shippingInfo,
                        billingAddress: params.billingInfo
                    },
                    paymentInfo: {
                        brand: card.brand,
                        braintreeCustomerId: '',
                        braintreePaymentMethodId: '',
                        braintreeTransactionId: '',
                        expiration: card.expiration,
                        last4: card.last4
                    }
                })
                return
            }

            let msg = getErrorMsg(response)
            if (msg.includes('requires_action')) {
                try {
                    stripeClientSecret = JSON.parse(response.message).client_secret
                    const result: PaymentIntentResult = await stripe!.handleCardAction(
                        stripeClientSecret
                    )
                    console.log({ result })
                    if (result.error) {
                        msg = result.error.message || 'Payment failed.'
                    } else {
                        // The card action has been handled
                        // The PaymentIntent can be confirmed again on the server
                        console.log('pay again.')
                        // if (result.paymentIntent.status === 'requires_confirmation') {
                        //   // payInvite({ ...params, paymentIntentID: result.paymentIntent!.id || '' });
                        //   stripe?.confirmCardPayment(
                        //     params.stripeClientSecret,
                        //     { payment_method: params.paymentMethodID }
                        //   )
                        // }
                        payAnInstallmentOrPayRemainingInstallmentByStripe()
                        return
                    }
                } catch (error) {
                    msg = 'Payment failed.'
                }
            }
            stopSubmitting(msg || 'Payment failed.', stripePaymentMethodId)
            failureCallback?.()
        }

        const payAnInstallmentOrPayRemainingInstallmentByBraintree = async () => {
            let braintreeTransactionDetails = await getBraintreeTransactionDetails()
            if (braintreeTransactionDetails.braintreePaymentMethodId === '') {
                return
            }
            const transactionDetails: TransactionDetailsParams = {
                stripePaymentIntentId: '',
                stripePaymentMethodId: '',
                stripeCustomerId: '',

                // Braintree
                ...braintreeTransactionDetails
            }
            const params: PayInstallmentJSON = {
                amount,
                shippingInfo,
                billingInfo,
                useFuture: true,
                transactionDetails,
                payForMemberEmail: friendMemberEmail
            }
            if (myselfMemberID !== friendMemberID) {
                // pay for friend
                params.payFor = friendMemberID
            }
            try {
                const response = await payInstallment(
                    publicRuntimeConfig.NEXT_PUBLIC_PAYMENTS_URL!,
                    `/v2/payment-plan/${orderID}/${myselfMemberID}/pay-now`,
                    params
                )
                if (response.paymentMethodID) {
                    setVaultPaymentMethodID(response.paymentMethodID)
                    setPaymentError(handlePaymentErrorMsgByBraintree(response.message))
                    setSubmitting(false)
                    return
                }
                if (response.status === 'ok') {
                    const paymentArray = await getPaymentData(
                        publicRuntimeConfig.NEXT_PUBLIC_PAYMENTS_URL!,
                        orderID,
                        friendMemberID
                    )
                    const charges = paymentArray
                        .filter((i) => i.Type === 'charge')
                        .sort((a, b) => a.Created.getTime() - b.Created.getTime())
                    const latestCharge = charges[charges.length - 1]
                    const paymentInfo = {
                        brand: latestCharge.Brand,
                        braintreeCustomerId: '',
                        braintreePaymentMethodId: '',
                        braintreeTransactionId: '',
                        expiration: '',
                        last4: latestCharge.last4
                    }

                    successCallback?.({
                        form: {
                            subscribe,
                            rememberMyInfo,
                            autoPush,
                            email: userInfo?.email || '',
                            shippingAddress: params.shippingInfo,
                            billingAddress: params.billingInfo
                        },
                        paymentInfo
                    })
                    return
                }
            } catch (error: any) {
                setPaymentError(handlePaymentErrorMsgByBraintree(error.message))
                setSubmitting(false)
            }
        }
        if (isBraintree) {
            payAnInstallmentOrPayRemainingInstallmentByBraintree()
        } else {
            payAnInstallmentOrPayRemainingInstallmentByStripe()
        }
    }

    const showHintOfEmail = () => {
        setShowEmailHint(true)
    }

    const closeHintOfEmail = () => {
        setShowEmailHint(false)
    }

    // pay action
    const createPaymentMethod = async (email: string, billingInfo: Address) => {
        const result = await stripe!.createPaymentMethod({
            type: 'card',
            card: elements!.getElement(CardNumberElement)!,
            billing_details: {
                // Include any additional collected billing details.
                name: [billingInfo.firstName, billingInfo.lastName].join(' '),
                address: {
                    line1: billingInfo.address,
                    line2: billingInfo.address2,
                    city: billingInfo.city,
                    state: billingInfo.state,
                    postal_code: billingInfo.zip,
                    country: billingInfo.country
                },
                email: email.toLowerCase(),
                phone: billingInfo.phone
            }
        })
        return result
    }

    const startSubmitting = () => {
        setStopCountDown(true)
        setPaymentError('')
        setSubmitting(true)
    }

    const stopSubmitting = (errorMsg: string, pmID: string) => {
        logPrevPaymentError(errorMsg)
        logPrevPaymentMethodID(pmID)
        setStopCountDown(false)
        window.Rollbar.error(errorMsg)
        setPaymentError(errorMsg)
        setSubmitting(false)
    }

    const getErrorMsg = (errorResponse: Record<string, any>) => {
        let msg = ''
        let isSet = false
        try {
            if (errorResponse.message.indexOf('requires_action') !== -1) {
                msg = errorResponse.message
                isSet = true
            }
            if (errorResponse.message.indexOf('{') !== -1) {
                const errorJSON = JSON.parse(
                    errorResponse.message.slice(errorResponse.message.indexOf('{'))
                )
                if (errorJSON && errorJSON.message) {
                    msg = errorJSON.message
                    if (
                        msg ==
                        'The provided PaymentMethod was previously used with a PaymentIntent without Customer attachment, shared with a connected account without Customer attachment, or was detached from a Customer. It may not be used again. To use a PaymentMethod multiple times, you must attach it to a Customer first.'
                    ) {
                        msg = 'Your card was declined.'
                    }
                    isSet = true
                }
            } else {
                if (errorResponse && errorResponse.message) {
                    msg = errorResponse.message
                    if (
                        msg ==
                        'The provided PaymentMethod was previously used with a PaymentIntent without Customer attachment, shared with a connected account without Customer attachment, or was detached from a Customer. It may not be used again. To use a PaymentMethod multiple times, you must attach it to a Customer first.'
                    ) {
                        msg = 'Your card was declined.'
                    }
                    isSet = true
                }
            }
        } catch (e) {
            console.log(e)
        }
        if (!isSet) {
            msg = 'Internal server error.'
        }
        return msg
    }

    const logPrevPaymentError = (msg: string) => {
        prevPaymentError.current = msg
    }

    const logPrevPaymentMethodID = (pmID: string) => {
        prevPaymentMethodID.current = pmID
    }

    const formData = {
        confirmEmail,
        email,
        shippingAddress,
        billingAddress,
        cardDetail,
        preShippingAddress: preShippingAddress.current,
        preBillingAddress: preBillingAddress.current,
        paymentOptionActiveState,
        chooseSameAsShippingInfo,
        rememberMyInfo,
        subscribe,
        autoPush
    }
    const formError = {
        confirmEmailError,
        emailError,
        shippingAddressError,
        cardInfoError,
        billingAddressError
    }
    const formEvent = {
        handleEmailChange,
        handleConfirmEmailChange,
        handleEmailBlur,
        handleConfirmEmailBlur,
        saveShippingAddress,
        saveCardInfo,
        saveBillingAddress,
        handleShippingAddressEdit,
        handlePaymentInfoEdit,
        handlePaymentInfoChange,
        handlePrivacyPolicyCheckOnChange,
        handleBillingAddressEdit,
        handleChooseSameAsShippingInfoChange,
        handlePaymentMethodError,
        handleShippingAddressChange,
        handleShippingAddressBlur,
        handleBillingAddressChange,
        handleBillingAddressBlur,
        handleRememberMyInfoChange,
        handleSubscribeChange,
        handleAutoPushChange,
        updatePaymentOptionActiveState,
        validateCheckOutForm,
        showHintOfEmail,
        closeHintOfEmail
    }
    const formShowState = {
        billingAddressShowState,
        isPrivacyPolicyCheck,
        paymentInfoShowState,
        shippingAddressShowState,
        showEmailHint
    }
    const formState = {
        isSavingCard
    }
    const stripeData = {
        stripe,
        elements
    }
    const braintreeData = {
        braintreeFormError,
        braintreeFormErrors,
        getBraintreeClientToken,
        handleBraintreePaymentRequest,
        handleCloseSnackbar,
        handleSetBraintreeDetails,
        handleValidateBraintreeForm,
        handleValidateHostedFields,
        hostedFieldsError,
        hostedFieldsInstance,
        isLoadingBraintreeForm,
        openSnackbar,
        setBraintreeFormError,
        setHostedFieldsInstance,
        setIsLoadingBraintreeForm
    }
    const payEvent = {
        handleSplitPayInviteSubmit,
        startSubmitting,
        getErrorMsg,
        logPrevPaymentError,
        handlePayInstallmentsSubmit
    }
    const payState = {
        submitting,
        stopCountDown
    }
    const payData = {
        prevPaymentMethodID: prevPaymentMethodID.current
    }
    const payError = {
        paymentError,
        prevPaymentError: prevPaymentError.current,
        privacyPolicyCheckError
    }
    const checkoutAction = {
        setEmail,
        setShippingAddress,
        setCardDetail,
        setBillingAddress,
        setShippingAddressShowState,
        setPaymentInfoShowState,
        setBillingAddressShowState,
        setChooseSameAsShippingInfo,
        setConfirmEmailError,
        setEmailError,
        setShippingAddressError,
        setCardInfoError,
        setBillingAddressError,
        setIsSavingCard,
        setPaymentOptionActiveState,
        setSubmitting,
        setStopCountDown,
        setPaymentError
    }
    const travelGuardData = {
        checkTravelGuardRequestParameters,
        debouncedSendTravelGuardQuoteRequest,
        hasFilledBasicInfo,
        isLoadingRequest,
        resetTravelGuardForm,
        travelGuardFormOnChange,
        travelGuardInfo,
        travelGuardInfoErrors,
        travelGuardPrice
    }
    return {
        braintreeData,
        formData,
        formError,
        formEvent,
        formShowState,
        formState,
        stripeData,
        payEvent,
        payState,
        payData,
        payError,
        checkoutAction,
        travelGuardData
    }
}

import { useEffect } from 'react'
import { faLock, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { TicketJSON } from '../../../../events-service/src/models/ticket'
import { TicketTipJSON } from '../../../../events-service/src/models/ticketTip'
import { formatMoney, handleAmountsPoint } from '../../util/parse'
import { Modal } from 'react-bootstrap'
import { getTimeZone, timeZoneString } from '../../util/dates'
import { TipsSection } from '../TipsSection'
import Image from '../image'
import {
    COLOR_GRAY_500,
    FONT_SIZE_12,
    FONT_WEIGHT_400,
    FULL_WIDTH,
    SIZE_4,
    SIZE_8,
    SIZE_16,
    SIZE_18,
    SIZE_20,
    SIZE_24,
    SIZE_46,
    SIZE_54,
    SIZE_100
} from 'styles/theme'
import { TruncationComponent } from '../../util/TruncationComponent'
import { Skeleton } from '@mui/material'
import { useStyles } from './styles/ticketTypeSelection.styles'

export type TicketTypeSelectionProps = {
    allAvailableRoomSoldOut: boolean
    codeIsRight: boolean
    currency: string
    hotelNumInfos: any
    inPreSaleInterval: boolean
    isEmptyAvailableRoom: boolean
    isLoading: boolean
    isOnlySellTickets: boolean
    isOnsaleTicket: boolean
    isPreSale: boolean
    needShowHotelPrice: boolean
    onPackageSelectionClick: (ticketId: string) => void
    onUnlockButtonClick: () => void
    peopleNumber: number
    submitting: boolean
    totalPrice: number
    ticket: TicketJSON
    ticketIndex: number
    ticketNameResize: () => void
    ticketNumInfos: any
    ticketPrice: number
    ticketStatus?: string
    ticketTip: TicketTipJSON | undefined
    timezone: string
}

export const TicketTypeSelection = ({
    allAvailableRoomSoldOut,
    codeIsRight,
    currency,
    hotelNumInfos,
    inPreSaleInterval,
    isEmptyAvailableRoom,
    isLoading,
    isOnlySellTickets,
    isOnsaleTicket,
    isPreSale,
    needShowHotelPrice,
    onPackageSelectionClick,
    onUnlockButtonClick,
    peopleNumber,
    submitting,
    totalPrice,
    ticket,
    ticketIndex,
    ticketNameResize,
    ticketNumInfos,
    ticketPrice,
    ticketStatus,
    ticketTip,
    timezone
}: TicketTypeSelectionProps) => {
    const isFuture = ticketStatus?.indexOf('@') !== -1
    const isSoldOut = ticketStatus?.indexOf('Sold Out') !== -1
    const isPrice = ticketPrice !== 0 && !isEmptyAvailableRoom && isOnsaleTicket
    const [needViewMore, setNeedViewMore] = useState<boolean>(false)
    const [showThumbnail, setShowThumbnail] = useState<boolean>(false)
    const [viewMore, setViewMore] = useState<boolean>(false)
    const buttonRef = useRef(null)
    const cardRef = useRef(null)
    const imgRef = useRef(null)
    const titleRef = useRef(null)
    const { classes } = useStyles()
    const ticketNameRef = useRef(null)
    const isHotelFuture =
        !isOnlySellTickets && hotelNumInfos?.['AllHotel'] === hotelNumInfos?.['WillBeSold']
    let isFutureStatus = isFuture
    switch (true) {
        case inPreSaleInterval && isFuture && codeIsRight:
            isFutureStatus = false
            break
    }
    const shouldShowPrice =
        isPrice &&
        (!isPreSale || codeIsRight) &&
        !isFutureStatus &&
        !isHotelFuture &&
        needShowHotelPrice &&
        totalPrice <= 10000000
    // totalPrice <= 10000000: If the total amount exceeds 50000, do not display the price
    /* ----- event ----- */

    /* ----- render ----- */
    useEffect(() => {
        if (isLoading) return
        if (ticketNameRef) {
            ticketNameResize()
        }
    }, [isLoading, ticketNameRef])
    useEffect(() => {
        if (isLoading) return
        if (ticketTip) {
            const tipElement = document.getElementById(ticketTip.id)
            if (tipElement) {
                if (tipElement.offsetHeight >= 250) {
                    //tip max-height = 250
                    setNeedViewMore(true)
                }
            }
        }
    }, [isLoading, ticketTip])

    const handleViewMore = (b: boolean) => {
        setViewMore(b)
    }

    const presaleBtnClassName = isFuture || isHotelFuture ? 'presaleTicket-btn' : ''
    const buttonStatus = () => {
        if ((ticketNumInfos?.['OnSale'] <= 0 && !codeIsRight) || allAvailableRoomSoldOut) {
            if (ticketNumInfos['WillBeSold'] > 0) {
                return (
                    <button
                        className={
                            'btn btn-block btn-purple btn-gray btn-disabled w-100 ' +
                            presaleBtnClassName
                        }
                    >
                        {ticketStatus}
                    </button>
                )
            }
            return (
                <button className="btn btn-block btn-purple btn-gray btn-disabled w-100 py-12">
                    Sold Out
                </button>
            )
        } else if (hotelNumInfos?.['OnSale'] <= 0 && !isOnlySellTickets && !codeIsRight) {
            if (hotelNumInfos['WillBeSold'] > 0) {
                return (
                    <button
                        className={
                            'btn btn-block btn-purple btn-gray btn-disabled w-100 ' +
                            presaleBtnClassName
                        }
                    >
                        {'On Sale ' +
                            getTimeZone(hotelNumInfos['LatestDateToBeSold'], timezone).format(
                                'MMM D, YYYY @ hh:mm A'
                            ) +
                            ' ' +
                            timeZoneString(timezone)}
                    </button>
                )
            }
            return (
                <button className="btn btn-block btn-purple btn-gray btn-disabled w-100 py-12">
                    Sold Out
                </button>
            )
        } else {
            if (isFutureStatus || isSoldOut) {
                return (
                    <button
                        className={
                            'btn btn-block btn-purple btn-gray btn-disabled w-100 ' +
                            presaleBtnClassName
                        }
                    >
                        {ticketStatus}
                    </button>
                )
            }
            return (
                <button
                    aria-label="Select Package Button"
                    className={`btn btn-block btn-purple w-100 py-12 ${
                        isMobileOnly ? 'fs-6 lh-base' : ''
                    }`}
                    onClick={() => onPackageSelectionClick(ticket.id)}
                >
                    {submitting ? (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className="fa-spin"
                        />
                    ) : (
                        'Select This Package'
                    )}
                </button>
            )
        }
    }

    const renderImage = () => {
        const imageUrl = ticket.ticketImage?.[0]?.url || ''
        if (imageUrl) {
            return (
                <div
                    className={classes.imageWrapper}
                    ref={imgRef}
                >
                    <div className={classes.imageContainer}>
                        {isLoading ? (
                            <Skeleton
                                animation="wave"
                                key={`ticketImageSkeleton_${ticketIndex}`}
                                sx={{
                                    height: FULL_WIDTH,
                                    maskImage: 'none'
                                }}
                                variant="rectangular"
                            />
                        ) : (
                            <Image
                                alt=""
                                className={classes.image}
                                layout="fill"
                                src={imageUrl}
                            />
                        )}
                    </div>

                    {!isLoading && ticket.ticketThumbnail?.[0]?.url && (
                        <div className={classes.thumbnail}>
                            <Image
                                alt=""
                                layout="fill"
                                onClick={() => setShowThumbnail(true)}
                                src={ticket.ticketThumbnail?.[0]?.url}
                            />
                        </div>
                    )}
                </div>
            )
        }
    }
    const renderDescription = () => {
        if (isLoading) {
            return (
                <Skeleton
                    animation="wave"
                    key={`ticketDescriptionSkeleton_${ticketIndex}`}
                    sx={{
                        height: '126px',
                        maskImage: 'none'
                    }}
                    variant="rectangular"
                />
            )
        }
        return (
            <div
                className={classes.nameContainer}
                ref={titleRef}
            >
                <h2
                    className={classes.name}
                    id={`ticket_${ticketIndex}`}
                    ref={ticketNameRef}
                >
                    {ticket.name}
                </h2>
                {ticket.description ? (
                    <TruncationComponent
                        className={classes.description}
                        html={ticket.description}
                        isTicketDescription={true}
                        maxNumberOfLines={3}
                    />
                ) : (
                    <p
                        style={{
                            height: SIZE_54,
                            marginBottom: 0,
                            marginTop: SIZE_8
                        }}
                    ></p>
                )}
            </div>
        )
    }

    const renderPrice = () => {
        if (isLoading)
            return (
                <Skeleton
                    animation="wave"
                    key={`ticketPriceSkeleton_${ticketIndex}`}
                    sx={{
                        height: '148px',
                        maskImage: 'none'
                    }}
                    variant="rectangular"
                />
            )
        return (
            <div
                className={classes.priceWrapper}
                ref={buttonRef}
            >
                {shouldShowPrice ? (
                    <div>
                        <div className={classes.priceContainer}>
                            <span className={classes.priceText}>
                                From{' '}
                                {handleAmountsPoint(
                                    formatMoney(totalPrice / 100 / peopleNumber, currency)
                                )}
                            </span>{' '}
                            / Person
                        </div>
                        <p
                            style={{
                                color: COLOR_GRAY_500,
                                fontSize: FONT_SIZE_12,
                                fontWeight: FONT_WEIGHT_400,
                                lineHeight: SIZE_18,
                                marginBottom: 0,
                                marginTop: SIZE_4
                            }}
                        >
                            {handleAmountsPoint(formatMoney(totalPrice / 100, currency))} Total
                            (Taxes and fees included)
                        </p>
                        <p
                            style={{
                                color: COLOR_GRAY_500,
                                fontSize: FONT_SIZE_12,
                                fontWeight: FONT_WEIGHT_400,
                                lineHeight: SIZE_18,
                                marginBottom: isMobileOnly ? SIZE_16 : SIZE_20
                            }}
                        >
                            *Price shown based on 2 people
                        </p>
                    </div>
                ) : (
                    <div>
                        <p style={{ paddingBottom: SIZE_46, marginBottom: SIZE_24 }}></p>
                    </div>
                )}

                {isPreSale ? (
                    <button
                        className="btn btn-block btn-purple w-100"
                        onClick={onUnlockButtonClick}
                    >
                        <span className="icon-unlock">
                            <FontAwesomeIcon icon={faLock} />
                        </span>{' '}
                        Unlock
                    </button>
                ) : (
                    <>{buttonStatus()}</>
                )}
            </div>
        )
    }
    const renderTip = () => {
        if (!ticketTip) return null
        if (isLoading)
            return (
                <Skeleton
                    animation="wave"
                    key={`ticketTipSkeleton_${ticketIndex}`}
                    sx={{
                        height: SIZE_100,
                        maskImage: 'none'
                    }}
                    variant="rectangular"
                />
            )
        return (
            <TipsSection
                handleViewMore={handleViewMore}
                needViewMore={needViewMore}
                ticketName={ticket.name}
                ticketTip={ticketTip}
                viewMore={viewMore}
            />
        )
    }
    return (
        <div className={classes.wrapper}>
            <div
                className={classes.container}
                ref={cardRef}
                style={{
                    minHeight: isMobile ? '35vh' : undefined
                }}
            >
                {renderImage()}
                {renderDescription()}
                {renderPrice()}
                {renderTip()}
            </div>
            <Modal
                className="modal thumbnail-Modal thumbnail-bg"
                show={showThumbnail}
                contentClassName="border-0"
                dialogClassName="modal-fullscreen modal-dialog-scrollable thumbnail-bg mx-sm-auto"
            >
                <Modal.Header className="py-4 border-0 d-block position-relative">
                    <Modal.Title
                        as={() => {
                            return (
                                <div className="container gx-md-3 gx-0 text-end">
                                    <span
                                        className="btn-close close-image-gallery-btn fs-5 opacity-100 position-absolute"
                                        style={{ zIndex: 2010, color: '#667085', padding: '5px' }}
                                        onClick={() => {
                                            setShowThumbnail(false)
                                        }}
                                    ></span>
                                </div>
                            )
                        }}
                    ></Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 modal-body">
                    <div className="container-map-popup gx-md-3 gx-0 p-0 addons-pop">
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                paddingTop: '100%',
                                overflow: 'hidden'
                            }}
                        >
                            <Image
                                className="img-responsive"
                                src={ticket.ticketThumbnail[0]?.url}
                                layout="fill"
                                objectFit="contain"
                                alt=""
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

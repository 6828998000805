import { DocumentNode, gql } from '@apollo/client'
export const GET_EVENTS_TOURS_QUERY: DocumentNode = gql`
    query InitialQuery(
        $limit: Int!
        $offset: Int!
        $orderBy: String
        $onlyShowContactUpEvent: Boolean
    ) {
        listEvents(
            limit: $limit
            offset: $offset
            orderBy: $orderBy
            onlyShowContactUpEvent: $onlyShowContactUpEvent
            filters: { published: true }
        ) {
            data {
                id
                name
            }
        }
        listTours(
            limit: $limit
            offset: $offset
            # orderBy: $orderBy
            filters: { published: true }
        ) {
            tourData: data {
                id
                name
                isContactUsTour
                events {
                    ... on Event {
                        id
                        name
                    }
                }
            }
        }
    }
`
